import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Doughnut, Line } from 'react-chartjs-2';
import './chart.css'

//Componente che renderizza 2 grafici rispettivamente di tipo linea e a ciambella. Il grafico a linea mostra le informazioni inerenti l'arco settimanale 
//dell'aggiunta o rimozione di richieste di ritiro ingombranti e segnalazioni. Il grafico a ciambella invece mostra i dati totali, anch'essi dinamici, di tutti i tipi
//di richieste associati al comune. 

function MyChart() {

    const location = useLocation();
    const comune = location.state ? location.state.comune : 'capurso';
    const token = JSON.parse(localStorage.getItem('token'));

    //chiamate API
    const LineGraphicApi = '/api/v1/tabella/datagrafici';
    const DoughnutGraphicApi = '/api/v1/tabella/dataciambella';

    //Variabili da passare all'asse x del grafico per mostrare le date
    var today = new Date();
    var yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    var twoDaysAgo = new Date();
    twoDaysAgo.setDate(today.getDate() - 2);
    var threeDaysAgo = new Date();
    threeDaysAgo.setDate(today.getDate() - 3);
    var fourDaysAgo = new Date();
    fourDaysAgo.setDate(today.getDate() - 4);
    var fiveDaysAgo = new Date();
    fiveDaysAgo.setDate(today.getDate() - 5);
    var sixDaysAgo = new Date();
    sixDaysAgo.setDate(today.getDate() - 6);

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    today = dd + '/' + mm;

    var ydd = String(yesterday.getDate()).padStart(2, '0');
    var ymm = String(yesterday.getMonth() + 1).padStart(2, '0');
    yesterday = ydd + '/' + ymm;

    var twodd = String(twoDaysAgo.getDate()).padStart(2, '0');
    var twomm = String(twoDaysAgo.getMonth() + 1).padStart(2, '0');
    twoDaysAgo = twodd + '/' + twomm;

    var threedd = String(threeDaysAgo.getDate()).padStart(2, '0');
    var threemm = String(threeDaysAgo.getMonth() + 1).padStart(2, '0');
    threeDaysAgo = threedd + '/' + threemm;

    var fourdd = String(fourDaysAgo.getDate()).padStart(2, '0');
    var fourmm = String(fourDaysAgo.getMonth() + 1).padStart(2, '0');
    fourDaysAgo = fourdd + '/' + fourmm;

    var fivedd = String(fiveDaysAgo.getDate()).padStart(2, '0');
    var fivemm = String(fiveDaysAgo.getMonth() + 1).padStart(2, '0');
    fiveDaysAgo = fivedd + '/' + fivemm;

    var sixdd = String(sixDaysAgo.getDate()).padStart(2, '0');
    var sixmm = String(sixDaysAgo.getMonth() + 1).padStart(2, '0');
    sixDaysAgo = sixdd + '/' + sixmm;

    //stati per immagazzinare i dati per il grafico linea
    const [dataIngombrantiLine, setdataIngombrantiLine] = useState([]);
    const [dataSegnalazioniLine, setdataSegnalazioniLine] = useState([]);
    
    //useEffect per prelevare i dati per il grafico linea
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(LineGraphicApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                
                const datiIngombranti = Object.values(result.tab).filter(item => item.servizio === 'Ingombranti').map(val => {
                    return val
                })
                setdataIngombrantiLine(() => {
                    return [...datiIngombranti]
                })

                const datiSegnalazioni = Object.values(result.tab).filter(item => item.servizio === 'Segnalazioni').map(val2 => {
                    return val2
                })
                setdataSegnalazioniLine(() => {
                    return [...datiSegnalazioni]
                })
            })
            .catch(error => console.log('error', error));
    },[token.accessToken, comune])

    //contenitori per l'asse x da mostrare degli ingombranti nel grafico a linea
    var sixDays = [];
    var fiveDays = [];
    var fourDays = [];
    var threeDays = [];
    var twoDays = [];
    var oneDay = [];
    var day = [];

    //contenitori per l'asse x da mostrare delle segnalazioni nel grafico a linea
    var sixDaysSegn = [];
    var fiveDaysSegn = [];
    var fourDaysSegn = [];
    var threeDaysSegn = [];
    var twoDaysSegn = [];
    var oneDaySegn = [];
    var daySegn = [];


    const mappedIng = Object.values(dataIngombrantiLine).map(values => {
        let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
        return data
    })

    if(mappedIng.includes(sixDaysAgo)){
        mappedIng.forEach(item => {
            if(item === sixDaysAgo){
                sixDays.push(item)
            }
        })
    }

    if(mappedIng.includes(fiveDaysAgo)){
        mappedIng.forEach(item => {
            if(item === fiveDaysAgo){
                fiveDays.push(item)
            }
        })
    }

    if(mappedIng.includes(fourDaysAgo)){
        mappedIng.forEach(item => {
            if(item === fourDaysAgo){
                fourDays.push(item)
            }
        })
    }

    if(mappedIng.includes(threeDaysAgo)){
        mappedIng.forEach(item => {
            if(item === threeDaysAgo){
                threeDays.push(item)
            }
        })
    }

    if(mappedIng.includes(twoDaysAgo)){
        mappedIng.forEach(item => {
            if(item === twoDaysAgo){
                twoDays.push(item)
            }
        })
    }

    if(mappedIng.includes(yesterday)){
        mappedIng.forEach(item => {
            if(item === yesterday){
                oneDay.push(item)
            }
        })
    }

    if(mappedIng.includes(today)){
        mappedIng.forEach(item => {
            if(item === today){
                day.push(item)
            }
        })
    }

    const mappedSegn = Object.values(dataSegnalazioniLine).map(values => {
        let dataSegn = values.data.split("-")[2] + '/' + values.data.split("-")[1]
        return dataSegn
    })

    if(mappedSegn.includes(sixDaysAgo)){
        mappedSegn.forEach(item => {
            if(item === sixDaysAgo){
                sixDaysSegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(fiveDaysAgo)){
        mappedSegn.forEach(item => {
            if(item === fiveDaysAgo){
                fiveDaysSegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(fourDaysAgo)){
        mappedSegn.forEach(item => {
            if(item === fourDaysAgo){
                fourDaysSegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(threeDaysAgo)){
        mappedSegn.forEach(item => {
            if(item === threeDaysAgo){
                threeDaysSegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(twoDaysAgo)){
        mappedSegn.forEach(item => {
            if(item === twoDaysAgo){
                twoDaysSegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(yesterday)){
        mappedSegn.forEach(item => {
            if(item === yesterday){
                oneDaySegn.push(item)
            }
        })
    }

    if(mappedSegn.includes(today)){
        mappedSegn.forEach(item => {
            if(item === today){
                daySegn.push(item)
            }
        })
    }

    //hooks per grafico a ciambella
    const [dataIngombrantiCiambella, setDataIngombrantiCiambella] = useState([]);
    const [dataSegnalazioniCiambella, setDataSegnalazioniCiambella] = useState([]);
    const [dataRichiesteCiambella, setDataRichiesteCiambella] = useState([]);
    const [dataMissOrganico, setDataMissOrganico] = useState([]);
    const [dataMissCarta, setDataMissCarta] = useState([]);
    const [dataMissVetro, setDataMissVetro] = useState([]);
    const [dataMissPlastica, setDataMissPlastica] = useState([]);
    const [dataMissIndifferenziato, setDataMissIndifferenziato] = useState([]);

    //useEffect per prelevare i dati inerenti il grafico a torta
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(DoughnutGraphicApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                    
                    const ingombrantiCiambella = Object.values(result.tab).filter(item => item.servizio === 'Ingombranti').map(val => {
                        return val
                    })
                    setDataIngombrantiCiambella(() => {
                        return [...ingombrantiCiambella]
                    })
    
                    const segnalazioniCiambella = Object.values(result.tab).filter(item => item.servizio === 'Segnalazioni').map(val2 => {
                        return val2
                    })
                    setDataSegnalazioniCiambella(() => {
                        return [...segnalazioniCiambella]
                    })

                    const richiesteCiambella = Object.values(result.tab).filter(item => item.servizio === 'Richieste').map(val3 => {
                        return val3
                    })
                    setDataRichiesteCiambella(() => {
                        return [...richiesteCiambella]
                    })

                    const missOrganico = Object.values(result.tab).filter(item => item.servizio === 'Mancato Ritiro Organico').map(val4 => {
                        return val4
                    })
                    setDataMissOrganico(() => {
                        return [...missOrganico]
                    })

                    const missCarta = Object.values(result.tab).filter(item => item.servizio === 'Mancato Ritiro Carta').map(val5=> {
                        return val5
                    })
                    setDataMissCarta(() => {
                        return [...missCarta]
                    }) 

                    const missVetro = Object.values(result.tab).filter(item => item.servizio === 'Mancato Ritiro Vetro').map(val6 => {
                        return val6
                    })
                    setDataMissVetro(() => {
                        return [...missVetro]
                    })

                    const missPlastica = Object.values(result.tab).filter(item => item.servizio === 'Mancato Ritiro Plastica-metallo').map(val7 => {
                        return val7
                    })
                    setDataMissPlastica(() => {
                        return[...missPlastica]
                    })

                    const missIndiff = Object.values(result.tab).filter(item => item.servizio === 'Mancato Ritiro Indifferenziato').map(val8 => {
                        return val8
                    })
                    setDataMissIndifferenziato(() => {
                        return[...missIndiff]
                    })
                })
            .catch(error => console.log('error', error));
        }, [token.accessToken, comune])

        const mappedIngomCiambella = Object.values(dataIngombrantiCiambella).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedSegnCiambella = Object.values(dataSegnalazioniCiambella).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedRichCiambella = Object.values(dataRichiesteCiambella).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedMissOrgCiambella = Object.values(dataMissOrganico).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedMissCartaCiambella = Object.values(dataMissCarta).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedMissVetro = Object.values(dataMissVetro).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedMissPlastica = Object.values(dataMissPlastica).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

        const mappedMissIndiff = Object.values(dataMissIndifferenziato).map(values => {
            let data = values.data.split("-")[2] + '/' + values.data.split("-")[1]
            return data
        })

    return (
        <div className="chartContainer">
            <div className="linechart">
            <Line
                data={{
                    labels: [sixDaysAgo, fiveDaysAgo, fourDaysAgo, threeDaysAgo, twoDaysAgo, yesterday, today], //asse x giorni settimana
                    datasets: [{    //dati da mostrare
                        label: 'Ingombranti',
                        data: [sixDays.length, fiveDays.length, fourDays.length, threeDays.length, twoDays.length, oneDay.length, day.length],
                        backgroundColor: ['red'] //colore di sfondo dei vari punti
                    },
                    {
                        label: 'Segnalazioni',
                        data: [sixDaysSegn.length, fiveDaysSegn.length, fourDaysSegn.length, threeDaysSegn.length, twoDaysSegn.length, oneDaySegn.length, daySegn.length],
                        backgroundColor: 'orange',
                    }
                ]
                }}
                options={{
                    scales: {
                        y: {
                            ticks: {
                                stepSize: 1,
                                beginAtZero: true
                            }
                        }
                    }
                }}
                />
            </div>
                <div className="ciambella">
                    <Doughnut 
                        data={{
                            labels: ['Ingombranti', 
                            'Segnalazioni', 
                            'Richieste', 
                            'Mancato Ritiro Organico', 
                            'Mancato Ritiro Carta', 
                            'Mancato Ritiro Vetro', 
                            'Mancato Ritiro Plastica-Metallo', 
                            'Mancato Ritiro Indifferenziato'],
                            datasets: [{
                                label: [
                                    'Ingombranti', 
                                    'Segnalazioni',
                                    'Richieste',
                                    'Mancato Ritiro Organico', 
                                    'Mancato Ritiro Carta', 
                                    'Mancato Ritiro Vetro', 
                                    'Mancato Ritiro Plastica-Metallo', 
                                    'Mancato Ritiro Indifferenziato'],
                                data: [
                                    mappedIngomCiambella.length, 
                                    mappedSegnCiambella.length, 
                                    mappedRichCiambella.length,
                                    mappedMissOrgCiambella.length,
                                    mappedMissCartaCiambella.length,
                                    mappedMissVetro.length,
                                    mappedMissPlastica.length,
                                    mappedMissIndiff.length],
                                backgroundColor: ['red', 'orange', 'purple', 'brown', 'green', 'blue', 'grey', 'black']
                            }
                        ]
                        }}/>
                </div>
        </div>
    )
}

export default MyChart;