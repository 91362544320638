/* eslint-disable default-case */
import { useState, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import { TokenContext } from '../context/token/token';
import './login.css';
import img from './img1.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faUser, faEye, faLink } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import {
    Configuration, LogLevel,
    PublicClientApplication,
    AuthenticationResult,
    AccountInfo,
    EndSessionRequest,
    RedirectRequest,
    PopupRequest,
  } from "@azure/msal-browser";

const AzureActiveDirectoryAppClientId = 'e3344753-1d71-432a-a1ef-5ecd5b6ecf9b';
const AzureActiveDirectoryAppClientIdBeta = '6e0fa38f-b809-4b8a-a017-e1b40f8296d0';

//change object to production when going on production
const production = {
  appId: AzureActiveDirectoryAppClientId,
  tenant: '51d4e170-e505-4a0d-8885-aaf538e05e75',
  redirect: 'https://scr.avrgroup.it/login'
}

const development = {
  appId: AzureActiveDirectoryAppClientIdBeta,
  tenant: '5bc4a8d1-5c1e-4864-8d6c-f2382c1ff954',
  redirect: 'http://localhost:3000/login'
}

export const MSAL_CONFIG = {
  auth: {
    clientId: production.appId,
    authority: "https://login.microsoftonline.com/" + production.tenant,
    redirectUri: production.redirect,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            //console.error(message);
            return;
          case LogLevel.Info:
            //console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
            //console.warn(message);
            return;
        }
      },
    },
  },
};

  let myMSALObj = new PublicClientApplication(MSAL_CONFIG);
  let account;
  let loginRedirectRequest;
  let loginRequest;

  let isAuthenticationConfigured = false;

//Componente che renderizza la pagina di Login, accettando al momento 3 utenti con le rispettive password:
// [USER-1 - Password1!, USER-2 - Password2!, USER-3 - Password3!]

function Login(){

    //variabili dal context
    const { user1, user2, user3 } = useContext(TokenContext);
    
    //chiamata API
    const loginApi = '/api/v1/login/';
    
    const history = useHistory();

    //hooks 
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    /* const [type, setType] = useState('password'); //hook per mostrare la password
    const [show, setShow] = useState('block');
    const [hide, setHide] = useState('none'); */
    const [modalNoCredentialisOpen, setmodalNoCredentialisOpen] = useState(false);
    /* const [modalWrongCredentialisOpen, setmodalWrongCredentialisOpen] = useState(false); */

    //array contenenti gli utenti con le rispettive password abilitati al login (dal seeding)
    const users = [user1[0].username, user2[0].username, user3[0].username];
    const passwords = [user1[0].password, user2[0].password, user3[0].password];

    const openModalNoCredentials = () => {
        if(!username || !password){
            setmodalNoCredentialisOpen(true);
        } else return;
    }

    const closeModal = () => {
        setmodalNoCredentialisOpen(false);
        return;
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            background: 'rgba(243, 243, 243, 1)',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    //funzione di evento al bottone che fa partire la post verso il db
    const handleLogin = (e) => {

        e.preventDefault();

        myMSALObj
        .loginPopup({
            scopes: [],
            prompt: "select_account",
          })
        .then((resp) => {
          /* console.log('MSAL', resp); */
          if(resp.idToken){

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

            var urlencoded = new URLSearchParams();
            urlencoded.append("user", resp.account.username.split("@")[0]);
            urlencoded.append("idToken", resp.idToken);

            var requestOptions = {
                headers: myHeaders,
                method: 'POST',
                body: urlencoded
            };

            fetch(loginApi, requestOptions)
                .then(response => response.json())
                .then(data => {
                    //console.log('Logged in with MSAL!', data);
                    localStorage.setItem('token', JSON.stringify(data)) //metto il contesto all'interno del local storage
                    window.open('/telefonate');
                    history.push('/home');
                    
                })
                .catch(error => {
                    console.log('error', error);
                    openModalNoCredentials();
                })
          }
        })
        .catch((err) => {
          console.error(err);
          openModalNoCredentials();
        });
    }

    return(
        <div className="wrapperLogin">
            <form
                className="loginForm" 
                onSubmit={handleLogin}>
                <button className="loginBtn" type="submit">Login</button>

                <Modal 
                    isOpen={modalNoCredentialisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModal} className="closingbuttonmodal">x</button>
                    <p className="modalText">C'è stato un errore nell'accesso tramite SSO. Contatta un amministratore.</p>
                </Modal>

                <div className="link">
                    <p>Created by <strong>Avr Tech S.r.l</strong></p>
                    <p><a href="https://avrgroup.it"><FontAwesomeIcon icon={faLink} /> Avr S.p.A.</a></p>
                    <p><a href="https://www.amaroma.it/"><FontAwesomeIcon icon={faLink} /> AmaRoma</a></p>
                </div>
            </form>
                <div className="img">
                    <img src={img} alt="login_img" 
                    style={{height: '100vh'}} />
                </div>
        </div>
    );
}

export default Login;