import React, { useContext, useEffect, useState } from 'react';
/* import { useLocation } from 'react-router'; */
import { ConfigContext } from '../context/config';
import { Link } from 'react-router-dom';
import './tabella.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrashAlt, faInfoCircle, faEdit, faColumns } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import Filtraggio from './modals/ricercamodal.js'
import Paginazione from './paginazione';
import Mappa from '../mappa/mappa';


//Componente che mostra in una tabella i dati inerenti le ultime aggiunte nel comune in termini di richieste con associato alcuni dati dell'utenza.
//Renderizza un bottone per eseguire una ricerca, un menù a tendina che permette di filtrare i dati per mostrare solo le richieste
//di ingombranti e segnalazioni e un bottone per mostrare determinati campi della tabella.
//Inoltre è possibile effettuare 3 operazioni, cancellazione, consultazione e modifica di uno specifico record.

function Tabella( props ){

    const file = useContext(ConfigContext);
    /* const location = useLocation(); */
    const comune = props.comune/* location.state ? location.state.comune : 'capurso'; */
    const token = JSON.parse(localStorage.getItem('token'));
    const tabellaHome = file.config.comuni[comune].tabellaHome;
    const tabellaIngombranti = file.config.comuni[comune].tabellaRichIngombranti;
    const tabellaSegnalazioni = file.config.comuni[comune].tabellaSegnalazioni;
    const coordinateCentro = file.config.comuni[comune].coordinateCentro;
    /* console.log('coordinateCentro', coordinateCentro) */

    //hooks
    const [campiTabella, setCampiTabella] = useState({});
    const [modalisOpen, setmodalisOpen] = useState(false); //hook per il modale
    const [changeType, setChangeType] = useState('');
    const [modalChooseColumn, setModalChooseColumn] = useState(false);
    const [showTipo, setShowTipo] = useState('none');
    const [showRichiesta, setShowRichiesta] = useState('none');
    const [showServizio, setShowServizio] = useState('none');
/*     const [showInfo, setShowInfo] = useState('none');
    const [showNote, setShowNote] = useState('none'); */
    const [tipo, setTipo] = useState('tipo');
    const [servizio, setServizio] = useState('servizio');
    const [richiesta, setRichiesta] = useState('richiesta');
/*     const [info, setInfo] = useState('info');
    const [note, setNote] = useState('note'); */
    const [tabHome, setTabHome] = useState([]);
    const [tabellaId, setTabellaId] = useState(0);

    //chiamate API
    const tabellaHomeApi = '/api/v1/tabella/home';
    const ingombrantiAPI = "/api/v1/tabella/ingombranti";
    const segnalazioniAPI = "/api/v1/tabella/segnalazioni";
    const nascondiTabellaId = "/api/v1/tabella/inserimento";
    const searchMapAPI = 'https://nominatim.openstreetmap.org/search?q='
    const gestisciRichiesta = "/api/v1/tabella/richiestaGestita";
    const mostraRichiestaGestita = "/api/v1/tabella/richiestaGestita";

    //hooks per modali e dati vari
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [asktoRemove, setasktoRemove] = useState(false);
    const [modalinfo, setModalinfo] = useState(false);
    const [modaltext, setModalText] = useState('');
    const [modalFullInfos, setModalFullInfos] = useState(false);
    const [modalData, setModalData] = useState('');
    const [modalOra, setModalOra] = useState('');
    const [modalNome, setModalNome] = useState('');
    const [modalCognome, setModalCognome] = useState('');
    const [modalIndirizzo, setModalIndirizzo] = useState('');
    const [modalCivico, setModalCivico] = useState('');
    const [modalTelefono, setModalTelefono] = useState('');
    const [modalCellulare, setModalCellulare] = useState('');
    const [modalTipo, setModalTipo] = useState('');
    const [modalServizio, setModalServizio] = useState('');
    const [modalRichiesta, setModalRichiesta] = useState('');
    const [modalNote, setModalNote] = useState('');
    const [modalInfo, setModalInfo] = useState('');
    const [modalCorrectModify, setModalCorrectModify] = useState(false);
    const [modalGestisciRichiesta, setModalGestisciRichiesta] = useState(false)
    const [dataLimit, setDataLimit] = useState(10);
    const [tab, setTab] = useState(); //hook per la renderizzazione della ricerca
    const [lat, setLat] = useState(null); //stato per disegnare la mappa (latitudine)
    const [long, setLong] = useState(null); //stato per disegnare la mappa (longitudine)

    const openModalCorrectModify = () => {
        setModalCorrectModify(true);
    }

    const closeModalCorrectModify = () => {
        setModalCorrectModify(false);
        window.location.reload(false);
    }

    const customFullInfoModal = {
        content: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            top: '15%',
            left: '15%',
            height: '70vh',
            padding: '1%',
            width: '70vw',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const OpenFullInfoModal = (data, ora, nome, cognome, indirizzo, civico, telefono, cellulare, tipo, servizio, richiesta, note, info) => {
        setModalData(data);
        setModalOra(ora);
        setModalNome(nome); 
        setModalCognome(cognome);
        setModalIndirizzo(indirizzo);
        setModalCivico(civico);
        setModalTelefono(telefono);
        setModalCellulare(cellulare);
        setModalTipo(tipo)
        setModalServizio(servizio);
        setModalRichiesta(richiesta);
        setModalNote(note);
        setModalInfo(info);
        setModalFullInfos(true);

        fetch(searchMapAPI + `${indirizzo},${civico},${comune}&format=json`)
            .then(response => response.json())
            .then(result => {
                //console.log('result', result)
                if(result.length > 0){
                    setLat(result[0].lat);
                    setLong(result[0].lon);
                } else {
                    setLat(null)
                    setLong(null)
                }
            })
            //console.log('lat post fetch', lat, 'long post fetch', long)
    }

    const closeFullInfoModal = () => {
        setModalFullInfos(false);
        setModalData('');
        setModalOra('');
        setModalNome(''); 
        setModalCognome('');
        setModalIndirizzo('');
        setModalCivico('');
        setModalTelefono('');
        setModalServizio('');
        setModalRichiesta('');
        setModalNote('');
        setModalInfo('');
        setLat(null);
        setLong(null);
    }

    const openModalinfo = (testo) => {
        setModalText(testo)
        setModalinfo(true);
    }

    const closeModalinfo = () => {
        setModalinfo(false);
        setModalText('');
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        setasktoRemove(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
        setasktoRemove(false);
    }

    const openasktoRemoveModal = (id) => {
        setTabellaId(id)
        setasktoRemove(true);
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const customStylesRicercaModal = {
        content: {
            top: '20%',
            left: '25%',
            height: '50vh',
            padding: '1%',
            width: '50vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const customStylesRichiestaGestitaModal = {
        content: {
            top: '20%',
            left: '35%',
            height: '30vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    }

    //funzione per il fake delete
    const TabHomeDeletion = (e) => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", token.username)
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiTabellaId, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrect(e);
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    //UseEffect che gestisce i side effects dei vari risultati
    //di default mostra il risultato della fetch generica della tabella Home
    //altrimenti mostra i risultati delle tabelle Ingombranti, Segnalazioni o gestiti a seconda del click
    useEffect(() => {

        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        if(changeType === 'Segnalazioni'){
            
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
            fetch(segnalazioniAPI, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.response.risultati.length > 0){
                        const dati = Object.values(result.response.risultati)/* .filter(item => item.deleted === false) */.map(val => {
                            return val;
                        })
                        setTabHome(() => {
                            return [...dati]
                        })
                    } else return
            })
            .catch(error => console.log('error', error));

        } else if(changeType === 'Ingombranti') {

            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
            fetch(ingombrantiAPI, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.response.risultati.length > 0){
                        const dati = Object.values(result.response.risultati)/* .filter(item => item.deleted === false) */.map(val => {
                            return val;
                        })
                        setTabHome(() => {
                            return [...dati]
                        })
                    } else return
            })
            .catch(error => console.log('error', error));

            } else if(changeType === 'Gestito'){

                myHeaders.append("x-access-token", token.accessToken);
                myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
                fetch(mostraRichiestaGestita, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if(result.response.risultati.length > 0){
                            const dati = Object.values(result.response.risultati).map(val => {
                                return val;
                            })
                            setTabHome(() => {
                                return [...dati]
                            })
                        } else return
                    })
                    .catch(error => console.log('error', error));
            }else {

                myHeaders.append("x-access-token", token.accessToken);
                myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
                fetch(tabellaHomeApi, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        const dati = Object.values(result.response.risultati)/* .filter(item => item.deleted === false) */.map(val => {
                            return val;
                        })
                        setTabHome(() => {
                            return [...dati]
                        })
                    })
                    .catch(error => console.log('error', error));
                }
    }, [changeType, comune, token.accessToken]);

    //Array contenente gli stati della checkbox
    var arr = [tipo, servizio, richiesta];
    
    const openchooseColumnModal = () => {
        setModalChooseColumn(true)
    }

    const closeChooseColumnModal = () => {
        setModalChooseColumn(false)
    }
    
    const openModal = () => {
        setmodalisOpen(true);
    }

    const closeModal = () => {
        setmodalisOpen(false);
    }

    const chooseColumnStyle = {
        content: {
            top: '30%',
            left: '30%',
            height: '40%',
            padding: '1%',
            width: '40%',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(4px)'
        }
    };

    //UseEffect per cambiare la tabella a seconda della selezione
    useEffect(() => {
        if(changeType === 'Ingombranti'){
            setCampiTabella(tabellaIngombranti);
        } else if(changeType === 'Segnalazioni'){
            setCampiTabella(tabellaSegnalazioni);
        } else {
            setCampiTabella(tabellaHome);
        }
    }, [changeType, tabellaIngombranti, tabellaSegnalazioni, tabellaHome])


    const hideShowColumnTipo = () => {
        if(showTipo === 'none'){
            setShowTipo('table-cell');
            setTipo('')
        } else {
            setShowTipo('none');
            setTipo('tipo')
        }
    }

    const hideShowColumnServizio = () => {
        if(showServizio === 'none'){
            setShowServizio('table-cell');
            setServizio('');
        } else {
            setShowServizio('none');
            setServizio('servizio');
        }
    }

    const hideShowColumnRichiesta = () => {
        if(showRichiesta === 'none'){
            setShowRichiesta('table-cell');
            setRichiesta('');
        } else {
            setShowRichiesta('none');
            setRichiesta('richiesta')
        }
    }

    /* const hideShowColumnNote = () => {
        if(showNote === 'none'){
            setShowNote('table-cell');
            setNote('');
        } else {
            setShowNote('none');
            setNote('note')
        }
    }

    const hideShowColumnInfo = () => {
        if(showInfo === 'none'){
            setShowInfo('table-cell');
            setInfo('');
        } else {
            setShowInfo('none');
            setInfo('info')
        }
    } */

    //DATI PER PAGINAZIONE
    const [pages, setPages] = useState(0);

    //setto il numero di pagine a seconda della response
    useEffect(() => {
        setPages(tab ? Math.ceil(tab.length / dataLimit) : Math.ceil(tabHome.length / dataLimit))
    }, [tabHome, tab, dataLimit])

    //hook per la pagina corrente che parte da 1
    const [currentPage, setCurrentPage] = useState(1);

    //funzione per scorrere la pagina di uno
    function goToNextPage(){
        if(currentPage === pages || currentPage > pages){
            return;
        } else {
        setCurrentPage((page) => page + 1);
        }
    }

    //funzione per la pagina di una posizione indietro
    function goToPreviousPage() {
        if(currentPage === 1){
            return;
        } else {
            setCurrentPage((page) => page - 1);
        }
    }

    //funzione per scorrere la pagina quando viene cliccato un numero
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        console.log('pageNumber', pageNumber)
        if(pageNumber === 0 || pageNumber > pages){
            return;
        } else {
            setCurrentPage(pageNumber);
        }
    }

    //variabili per l'inizio e la fine dei dati
    const endIndex = currentPage * dataLimit;
    const startIndex = endIndex - dataLimit;

    //variabile per i dati paginati
    const getPaginatedData = tab ? tab.slice(startIndex, endIndex) : tabHome.slice(startIndex, endIndex);

    let pageLimit = tab ? Math.ceil(tab.length / dataLimit) : Math.ceil(tabHome.length / dataLimit);

    //funzione per mostrare i numeri giusti delle pagine
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        if(Math.ceil(pages / dataLimit) <= pageLimit){
            pageLimit = Math.ceil(pages/dataLimit) * pageLimit;
            if(dataLimit >= 10 && dataLimit < 50){
                pageLimit = 3;
            } else if(dataLimit >= 50 && dataLimit < 100){
                pageLimit = 5;
            } 
            else {
                pageLimit = 1;
            }
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        } else {
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        }
    };

    const openGestisciRichiestaModal = (e) => {
        e.preventDefault();
        if(!e.target.id){
            return
        }
        setTabellaId(e.target.id)
        setModalGestisciRichiesta(true);
    }

    const closeModalGestisciRichiesta = () => {
        setModalGestisciRichiesta(false)
    }

    //funzione per settare la richiesta gestita
    const richiestaGestita = (e) => {
        e.preventDefault();

        closeModalGestisciRichiesta();

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            /* myHeaders.append("username", token.username); */
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(gestisciRichiesta, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrectModify(e);
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        /* .then(result => console.log(result)) */
        .catch(error => console.log('error', error));
    }

    return(
        <div className="wrapper">
            <div className="helpers">
                <div className="checkboxes">
                    <button id="iconSearch" onClick={openModal}>
                        <p>Cerca</p>
                        <FontAwesomeIcon style={{ marginLeft: '.3em', alignSelf: 'center'}}
                        icon={faSearch}
                        size="1x"/>
                    </button>
                    <Modal 
                        isOpen={modalisOpen}
                        ariaHideApp={false}
                        style={customStylesRicercaModal}>
                        <button onClick={closeModal} className="closingfilterbutton">x</button>
                            <Filtraggio 
                                comune={comune}
                                setTab={setTab}
                                closeModal={closeModal}/> {/* Componente per la ricerca */}
                    </Modal>
                    {/* RENDERIZZARE UN BOTTONE CHE AL CLICK MI APRE UN MODALE CON UNA LISTA DI SELEZIONI */}
                    <div className="chooseColumn">
                        <button onClick={openchooseColumnModal}>
                            <p>Scegli colonna <FontAwesomeIcon style={{ marginLeft: '.3em', alignSelf: 'center'}}
                            icon={faColumns}
                            size="1x"/></p>
                            
                        </button>

                        <Modal 
                            isOpen={modalChooseColumn}
                            ariaHideApp={false}
                            style={chooseColumnStyle}>
                                <button style={{alignSelf: 'flex-end'}}className="closingbuttonmodal" onClick={closeChooseColumnModal}>x</button>
                                <div className="checkboxSelection">
                                    <label htmlFor="data-ora">Data - Ora</label>
                                    <input type="checkbox" name="data-ora" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="nome">Nome</label>
                                    <input type="checkbox" name="nome" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="cognome">Cognome</label>
                                    <input type="checkbox" name="cognome" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="indirizzo">Indirizzo</label>
                                    <input type="checkbox" name="indirizzo" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="telefono">Telefono</label>
                                    <input type="checkbox" name="telefono" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="cellulare">Cellulare</label>
                                    <input type="checkbox" name="cellulare" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="tipo">Tipo</label>
                                    <input type="checkbox" name="tipo" value={showTipo} 
                                        onClick={hideShowColumnTipo} defaultChecked={showTipo === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="servizio">Servizio</label>
                                    <input type="checkbox" name="servizio" value={showServizio} 
                                        onClick={hideShowColumnServizio} defaultChecked={showServizio === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="richiesta">Richiesta</label>
                                    <input type="checkbox" name="richiesta" value={showRichiesta} 
                                        onClick={hideShowColumnRichiesta} defaultChecked={showRichiesta === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="note">Note</label>
                                    <input type="checkbox" name="note" checked readOnly/* value={showNote}
                                        onClick={hideShowColumnNote} defaultChecked={showNote === 'table-cell' ? true : false} */>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="info">Info</label>
                                    <input type="checkbox" name="info" checked readOnly/* value={showInfo}
                                        onClick={hideShowColumnInfo} defaultChecked={showInfo === 'table-cell' ? true : false} */>
                                    </input>
                                </div>
                        </Modal>

                    </div>
                    <select name="selrapida" value={changeType} onChange={e => setChangeType(e.target.value)}>
                        <option value="">Tabella Generica</option>
                        <option value="Ingombranti">Ingombranti</option>
                        <option value="Segnalazioni">Segnalazioni</option>
                        <option value="Gestito">Gestito</option>
                    </select>
                </div>
                <div className="selectPaginazione">
                    <p>Elementi da visualizzare</p>
                    <select name="paginazione" value={dataLimit} onChange={e => setDataLimit(e.target.value)}>
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        {/* <option value="100">100</option>
                        <option value={tab ? tab.length : tabHome.length}>Tutti</option> */}
                    </select>
                </div>
            </div>
                <div className="tabellaHome">
                    <table>
                        <thead className="tabellaKeys">
                            <tr>
                            {
                                Object.keys(campiTabella).map((tabkeys, i) => {
                                if(!arr.includes(tabkeys)){
                                    return <th key={i}>{tabkeys}</th>
                                }
                            })
                            }
                            </tr>
                        </thead>

                        {/* QUA CI VA LA RENDERIZZAZIONE DI TABELLE.JS MA NON COME COMPONENTE */}
                        <tbody>
                        {
                            Object.values(getPaginatedData).map(tab => {
                                /* console.log(tab) */
                                return ( <tr className="rowHome"
                                    key={tab.id}
                                    id={tab.id}
                                    servizio={tab.servizio}>
                                <td>{tab.data} - {tab.ora}</td>
                                <td>{tab.utenze.nome}</td>
                                <td>{tab.utenze.cognome}</td>
                                <td 
                                    style={{padding: '0 6em'}}
                                    /* onClick={() => openModalinfo(tab.utenze.indirizzo)} */>
                                    {tab.utenze.indirizzo/* .length > 10 ? tab.utenze.indirizzo.slice(0, 10) + '...' : tab.utenze.indirizzo */}
                                </td>
                                <td style={{minWidth: '100%'}}>{tab.utenze.civico}</td>
                                <td>{tab.utenze.telefono1}</td>
                                <td>{tab.utenze.telefono2}</td>
                                <td
                                    style={{cursor: 'pointer', display: showTipo}}
                                    onClick={() => openModalinfo(tab.tipo)}>
                                    {tab.tipo.length > 10 ? tab.tipo.slice(0, 10) + '...' : tab.tipo}</td>
                                <td
                                    style={{cursor: 'pointer', display: showServizio}}
                                    onClick={() => openModalinfo(tab.servizio)}>
                                    {tab.servizio.length > 10 ? tab.servizio.slice(0, 10) + '...' : tab.servizio}</td>
                                <td 
                                    style={{ cursor: 'pointer', display: showRichiesta}}
                                    onClick={() => openModalinfo(tab.richiesta)}>
                                    {tab.richiesta.length > 10 ? tab.richiesta.slice(0, 10) + '...' : tab.richiesta}</td>
                                <td style={{/*  cursor: 'pointer',  */padding: '0 2em'/* , display: showNote */}}
                                    /* onClick={() => openModalinfo(tab.note)} */>
                                    {tab.note/* .length > 10 ? tab.note.slice(0, 10) + '...' : tab.note */}
                                </td>
                                <td style={{/*  cursor: 'pointer',  */padding: '0 2em'/* , display: showInfo */}}
                                    /* onClick={() => openModalinfo(tab.utenze.info)} */>
                                    {tab.utenze.info /* ? tab.utenze.info.length > 10 ? tab.utenze.info.slice(0, 10) + '...' : tab.utenze.info : null  */}
                                </td>
                                <td>{tab.gestito === false ? 'No' : 'Si'}</td>
                                {/* CELLA OPERAZIONI */}
                                <td className="operationContainer" style={{justifyContent: 'space-evenly'}}>
                                    {/* ICONA PER MODIFICARE LA RICHIESTA AL CLICK*/}
                                    <div style={{alignSelf: 'center'}}>
                                        <button style={{ cursor: 'pointer' }}>
                                            <Link to={{
                                                pathname: "/servizio/" + comune + "/ritiri_e_segnalazioni/modifica_richiesta",
                                                state:{"comune": comune},
                                                tab: [tab]
                                            }}>
                                            <FontAwesomeIcon 
                                                icon={faEdit}
                                                id={tab.id}
                                                servizio={tab.servizio}
                                                size="1x"
                                                style={{cursor: 'pointer', color: '#33448f', alignSelf: 'center', justifySelf: 'center'}}/>
                                            </Link>
                                        </button>
                                    </div>
                                    <div style={{alignSelf: 'center'}}>
                                    {/* ICONA PER CANCELLARE LA RICHIESTA */}
                                        <button style={{ cursor: 'pointer' }} onClick={() => openasktoRemoveModal(tab.id)}>
                                            <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                id={tab.id}
                                                servizio={tab.servizio}
                                                size="1x"
                                                style={{cursor: 'pointer', padding: '1%', alignSelf: 'center', color: '#33448f'}}/>
                                        </button>
                                    </div>
                                    <div style={{alignSelf: 'center'}}>
                                    {/* ICONA PER MOSTRARE TUTTE LE INFO */}
                                        <button style={{ cursor: 'pointer' }}
                                            onClick={() => OpenFullInfoModal(tab.data, tab.ora, tab.utenze.nome, tab.utenze.cognome, tab.utenze.indirizzo, tab.utenze.civico, tab.utenze.telefono1,
                                                tab.utenze.telefono2, tab.tipo, tab.servizio, tab.richiesta, tab.note, tab.utenze.info)}
                                            disabled={!tab.gestito ? false : true}>
                                            <FontAwesomeIcon 
                                                icon={faInfoCircle}
                                                id={tab.id}
                                                servizio={tab.servizio}
                                                size="1x"
                                                style={{cursor: 'pointer', alignSelf: 'center', color: '#33448f'}}/>
                                        </button>
                                    </div>
                                    <div style={{alignSelf: 'center'}}>
                                    {/* ICONA PER SETTARE LA RICHIESTA GESTITA */}
                                        <button style={{ cursor: 'pointer' }}
                                            onClick={(e) => openGestisciRichiestaModal(e)}
                                            disabled={!tab.gestito ? false : true}>
                                            <FontAwesomeIcon 
                                                icon={faCheck} 
                                                id={tab.id}
                                                servizio={tab.servizio}
                                                size="1x"
                                                style={{cursor: 'pointer', padding: '1%', alignSelf: 'center', color: '#33448f'}}/>
                                        </button>
                                    </div>
                                </td>

                            <Modal 
                                isOpen={modalGestisciRichiesta}
                                ariaHideApp={false}
                                style={customStylesRichiestaGestitaModal}>
                                <p>Attenzione! Stai per settare questa richiesta come gestita</p>
                                <p>Sei sicuro?</p>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                    <FontAwesomeIcon 
                                    icon={faCheck} 
                                    id={tab.id}
                                    servizio={tab.servizio}
                                    onClick={richiestaGestita} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                    <FontAwesomeIcon 
                                    icon={faTimes} 
                                    onClick={closeModalGestisciRichiesta} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                </div>
                            </Modal>

                            <Modal
                                isOpen={asktoRemove}
                                ariaHideApp={false}
                                style={customStyles}>
                                <p>Attenzione! Stai per rimuovere questo record</p>
                                <p>Sei sicuro?</p>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                    <FontAwesomeIcon 
                                    icon={faCheck} 
                                    id={tab.id}
                                    servizio={tab.servizio}
                                    onClick={TabHomeDeletion} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                    <FontAwesomeIcon 
                                    icon={faTimes} 
                                    onClick={closeasktoRemoveModal} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                </div>
                            </Modal>

                            <Modal 
                                isOpen={modalCorrectModify}
                                ariaHideApp={false}
                                style={customStyles}>
                                    <button onClick={closeModalCorrectModify} className="closingbuttonmodal">x</button>
                                    <p style={{marginTop: '5em'}}>Operazione completata con successo</p>
                            </Modal>

                            <Modal 
                                isOpen={modalinfo}
                                ariaHideApp={false}
                                style={customStyles}>
                                <button onClick={closeModalinfo} className="closingbuttonmodal">x</button>
                                <p style={{marginTop: '5em'}}>{modaltext}</p>
                            </Modal>

                            <Modal
                                isOpen={modalCorrectisOpen}
                                ariaHideApp={false}
                                style={customStyles}>
                                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                                <p style={{marginTop: '5em'}}>Record eliminato correttamente</p>
                            </Modal>

                            <Modal
                                isOpen={modalErrorisOpen}
                                ariaHideApp={false}
                                style={customStyles}>
                                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                                <p style={{marginTop: '5em'}}>Ops! Qualcosa è andato storto!</p>
                            </Modal>

                        </tr>
                    );
                })
                }
                        </tbody>
                    </table>
                    
                </div>
                {/* FUORI DALLA TABLE CI VA IL COMPONENTE PAGINAZIONE */}
                <Paginazione 
                    getPaginationGroup={getPaginationGroup}
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    pages={pages}
                    currentPage={currentPage}
                    changePage={changePage}
                />
                {/* Modale in cui va inserita la mappa che punta all'indirizzo */}
                <Modal 
                    isOpen={modalFullInfos}
                    ariaHideApp={false}
                    style={customFullInfoModal}>
                    <Mappa lat={lat} long={long} indirizzo={modalIndirizzo} civico={modalCivico} comune={comune} coordinateCentro={coordinateCentro} />
                    <div className="infoContainer">
                        <div className="info1Container">
                            <div className="modaldivs"><h4>Data inserimento: </h4><p className="modalparag">{modalData}</p></div>
                            <div className="modaldivs"><h4>Ora inserimento: </h4><p className="modalparag">{modalOra}</p></div>
                            <div className="modaldivs"><h4>Nome: </h4><p className="modalparag">{modalNome}</p></div>
                            <div className="modaldivs"><h4>Cognome: </h4><p className="modalparag">{modalCognome}</p></div>
                            <div className="modaldivs"><h4>Indirizzo: </h4><p className="modalparag">{modalIndirizzo}</p></div>
                            <div className="modaldivs"><h4>Civico: </h4><p className="modalparag">{modalCivico}</p></div>
                            <div className="modaldivs"><h4>Telefono: </h4><p className="modalparag">{modalTelefono}</p></div>
                        </div>
                            <div className="info2Container">
                            <div className="modaldivs"><h4>Cellulare: </h4><p className="modalparag">{modalCellulare}</p></div>
                            <div className="modaldivs"><h4>Tipo: </h4><p className="modalparag">{modalTipo}</p></div>
                            <div className="modaldivs"><h4>Servizio: </h4><p className="modalparag">{modalServizio}</p></div>
                            <div className="modaldivs"><h4>Richiesta: </h4><p className="modalparag">{modalRichiesta}</p></div>
                            <div className="modaldivs"><h4>Note: </h4><p className="modalparag">{modalNote}</p></div>
                            <div className="modaldivs"><h4>Info: </h4><p className="modalparag">{modalInfo}</p></div>
                        </div>
                    </div>
                    <button onClick={closeFullInfoModal} className="modalfullinfosclosebutton">x</button>
                </Modal>
        </div>
    );
}

export default Tabella;