import React, { createContext, useEffect, useState } from 'react';


export const ConfigContext = createContext();


export function ConfigProvider(props){

    const configApi = '/api/v1/config/'

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(configApi)
        .then(res => {
            if(res.ok){
                return res.json()
            }
            throw res;
        })
        .then(dati => {
            setData(dati)
        })
        .catch(error => {
            console.error('Errore nel fetching dei dati:', error)
            setError(error);
        })
        .finally(() => {
            setLoading(false)
        })
    }, []);

    if(loading) return "Caricamento..."
    if(error) return "Errore nel caricamento dei dati..."

    return(
    <ConfigContext.Provider setdata={ setData } value={ data }>
        {props.children}
    </ConfigContext.Provider>
    );

}