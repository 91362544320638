import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, Link } from 'react-router-dom';
import { ConfigContext } from '../context/config';
import './elencoutenze.css'
import Sidebar from '../comuni/sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faCheck, faTimes, faTrashAlt, faEdit} from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-modal';
import FiltraggioPaP from '../comuni/modals/modalricercapap';
import Paginazione from '../comuni/paginazione';

//Componente che renderizza una tabella contenente i dati relativi alle singole utenze, con dei bottoni per la ricerca e per la selezione dei campi da visualizzare.

function ElencoUtenze(){

    const file = useContext(ConfigContext);
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const tabellaElenco = file.config.comuni[comune].tabellaUtenze;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamate API
    const nascondiUtenzeTabellaId = "/api/v1/tabella/utenze"
    const tabUtenzeApi = "/api/v1/tabella/utenze";

    const [tabUtenze, setTabUtenze] = useState([]);
    const [modalisOpen, setmodalisOpen] = useState(false); // hook per l'apertura del modal
    const [modalChooseColumn, setModalChooseColumn] = useState(false);
    const [showOrganico, setShowOrganico] = useState('none');
    const [showPlastica, setShowPlastica] = useState('none');
    const [showVetro, setShowVetro] = useState('none');
    const [showCarta, setShowCarta] = useState('none');
    const [showIndiff, setShowIndiff] = useState('none');
    const [carta, setCarta] = useState('carta');
    const [plastica, setPlastica] = useState('plastica');
    const [vetro, setVetro] = useState('vetro');
    const [organico, setOrganico] = useState('organico');
    const [indiff, setIndiff] = useState('indiff.');

    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [asktoRemove, setasktoRemove] = useState(false);
    const [tabellaId, setTabellaId] = useState(0);
    const [modalInfo, setModalInfo] = useState(false);
    const [modaltesto, setModalTesto] = useState('');
    const [dataLimit] = useState(20);

    const openModalInfo = (indirizzo) => {
        setModalTesto(indirizzo)
        setModalInfo(true)
    }

    const closeModalInfo = () => {
        setModalTesto('')
        setModalInfo(false)
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        setasktoRemove(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
        setasktoRemove(false);
    }

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        //console.log(e.target)
        if(!e.target.id){
            return;
        } else {
            //console.log('quando premo sulla X', e.target.id)
            setTabellaId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const customStylesRicercaUtenze = {
        content: {
            top: '20%',
            left: '25%',
            height: '50vh',
            padding: '1%',
            width: '50vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const chooseColumnStyle = {
        content: {
            top: '35%',
            left: '35%',
            height: '30%',
            padding: '1%',
            width: '30%',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(4px)'
        }
    };

    //funzione per il fake delete
    const TabUtenzeDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        console.log('record da passare al backend', tabellaId)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiUtenzeTabellaId, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrect(e);
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const [pages, setPages] = useState();

    useEffect(() => {
        setPages(Math.ceil(tabUtenze.length / dataLimit))
    }, [tabUtenze, dataLimit])

    var pageLimit = Math.ceil(tabUtenze.length / dataLimit)

    const [currentPage, setCurrentPage] = useState(1);

    //funzione per scorrere la pagina di uno
    function goToNextPage(){
        if(currentPage === pages  || currentPage === pages + 1){
            return;
        } else {
        setCurrentPage((page) => page + 1);
        }
    }

    //funzione per la pagina di una posizione indietro
    function goToPreviousPage() {
        if(currentPage === 1){
            return;
        } else {
            setCurrentPage((page) => page - 1);
        }
    }

    //funzione per scorrere la pagina quando viene cliccato un numero
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        if(pageNumber === 0 || pageNumber === pages + 1){
            return;
        } else {
            setCurrentPage(pageNumber);
        }
    }

    //variabili per l'inizio e la fine dei dati
    const endIndex = currentPage * dataLimit;
    const startIndex = endIndex - dataLimit;

    //variabile per i dati paginati
    const getPaginatedData = tabUtenze.slice(startIndex, endIndex);

    //funzione per mostrare i numeri giusti delle pagine
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        if(Math.ceil(pages/* getPaginatedData.length */ / dataLimit) <= pageLimit){
            pageLimit = Math.ceil(pages/dataLimit) * pageLimit/* Math.ceil(pages *//* getPaginatedData.length */ /* / dataLimit) */;
            /* if(dataLimit >= 3){
                pageLimit = 3;
            } */
            if(dataLimit >= 10 && dataLimit < 50){
                pageLimit = 3;
            } else if(dataLimit >= 50 && dataLimit < 100){
                pageLimit = 5;
            } 
            else {
                pageLimit = 1;
            }
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        } else {
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        }
        
    };

    const openModal = (e) => {
        e.preventDefault();
        setmodalisOpen(true);
    }

    const closeModal = (e) => {
        e.preventDefault();
        setmodalisOpen(false);
    }

    //GET per prendere i dati della tabella delle utenze
    useEffect(() =>{
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

        fetch(tabUtenzeApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                //console.log(result)
    
                const dati = Object.values(result.response.risultati)
                            .filter(item => 
                                item.deleted === false &&
                                item.carta !== " " &&
                                item.vetro !== " " &&
                                item.indifferenziato !== " " &&
                                item.plastica !== " ")
                                .map(val => {
                    return val;
                })
                setTabUtenze(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
    }, [token.accessToken, comune])

    const openchooseColumnModal = () => {
        setModalChooseColumn(true)
    }

    const closeChooseColumnModal = () => {
        setModalChooseColumn(false)
    }

    const hideShowColumnOrganico = () => {
        if(showOrganico === 'none'){
            setShowOrganico('table-cell');
            setOrganico('')
        } else {
            setShowOrganico('none');
            setOrganico('organico')
        }
    }

    const hideShowColumnPlastica = () => {
        if(showPlastica === 'none'){
            setShowPlastica('table-cell');
            setPlastica('');
        } else {
            setShowPlastica('none');
            setPlastica('plastica');
        }
    }

    const hideShowColumnVetro = () => {
        if(showVetro === 'none'){
            setShowVetro('table-cell');
            setVetro('');
        } else {
            setShowVetro('none');
            setVetro('vetro')
        }
    }

    const hideShowColumnCarta = () => {
        if(showCarta === 'none'){
            setShowCarta('table-cell');
            setCarta('');
        } else {
            setShowCarta('none');
            setCarta('carta')
        }
    }

    const hideShowColumnIndiff = () => {
        if(showIndiff === 'none'){
            setShowIndiff('table-cell');
            setIndiff('');
        } else {
            setShowIndiff('none');
            setIndiff('indiff.')
        }
    }

    var arr = [organico, plastica, vetro, carta, indiff]

    return(
        <div className="wrapperUtenze">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="elencoutenzewrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                    <div className="tabellaelencoWrapper">
                        <div className="helpingButtons">
                            <button id="iconSearch" onClick={openModal}>
                                <p>CERCA</p>
                                <FontAwesomeIcon 
                                    style={{ marginLeft: '.3em', alignSelf: 'center'}} 
                                    icon={faSearch} size="1x"
                                />
                            </button>
                            <Modal isOpen={modalisOpen}
                                ariaHideApp={false}
                                style={customStylesRicercaUtenze}>
                                <button onClick={closeModal} className="closingfiltermodal">x</button>
                                <FiltraggioPaP setTabUtenze={setTabUtenze} comune={comune} closeModal={closeModal}/>
                            </Modal>
                            <button id="columnSelector" onClick={openchooseColumnModal}>
                                <p>Scegli colonna</p>
                            </button>
                        </div>
                        <div className="tabellaElencoPaP">
                            <table>
                                <thead className="elencoutenzetitles">
                                    <tr>
                                    {
                                        Object.keys(tabellaElenco).map((tab, i) => {
                                            if(!arr.includes(tab)){
                                                return (<th key={i}>
                                                            {tab}
                                                        </th>
                                                    )}
                                            })
                                    }
                                    </tr>
                                </thead>
                                {/* QUA CI VA LA RENDERIZZAZIONE DEI DATI DELLA TABELLA */}
                                    <tbody>
                                        {
                                            Object.values(getPaginatedData).map((tab, i) => {
                                                return ( <tr className="rowUtenze" key={i} id={tab.id}>
                                                <td>{tab.data}</td>
                                                <td>{tab.ora}</td>
                                                <td>{tab.nome}</td>
                                                <td>{tab.cognome}</td>
                                                <td 
                                                    style={{cursor: 'pointer'}}
                                                    onClick={() => openModalInfo(tab.indirizzo)}>
                                                    {tab.indirizzo ? tab.indirizzo.slice(0, 10) + '...' : tab.indirizzo}
                                                </td>
                                                <td>{tab.civico}</td>
                                                <td>{tab.telefono1}</td>
                                                <td>{tab.telefono2}</td>
                                                <td style={{display: showOrganico}}>{tab.organico}</td>
                                                <td style={{display: showPlastica}}>{tab.plastica}</td>
                                                <td style={{display: showVetro}}>{tab.vetro}</td>
                                                <td style={{display: showCarta}}>{tab.carta}</td>
                                                <td style={{display: showIndiff}}>{tab.indifferenziato}</td>
                                                <td className="operationContainer" style={{justifyContent: 'space-evenly'}}>
                                                    {/* ICONA PER MODIFICARE LA RICHIESTA AL CLICK */}
                                                    <div style={{alignSelf: 'center'}}>
                                                        <button style={{ cursor: 'pointer' }}>
                                                            <Link to={{
                                                                pathname: "/servizio/" + comune + "/elenco_utenze/modifica_utenza",
                                                                state:{"comune": comune},
                                                                tab: [tab]
                                                                }}>
                                                                <FontAwesomeIcon 
                                                                    icon={faEdit}
                                                                    id={tab.id}
                                                                    servizio={tab.servizio}
                                                                    size="1x"
                                                                    style={{cursor: 'pointer', color: '#33448f', alignSelf: 'center', justifySelf: 'center'}}/>
                                                            </Link>
                                                        </button>
                                                    </div>
                                                    {/* ICONA PER CANCELLARE IL RECORD */}
                                                    <div style={{alignSelf: 'center'}} onClick={openasktoRemoveModal}>
                                                        <button style={{ cursor: 'pointer' }}>
                                                            <FontAwesomeIcon 
                                                                icon={faTrashAlt} 
                                                                id={tab.id} 
                                                                size="1x"
                                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                                        </button>
                                                    </div>
                                                </td>

                                                <Modal
                                                    isOpen={asktoRemove}
                                                    ariaHideApp={false}
                                                    style={customStyles}>
                                                    <p>Attenzione! Stai per rimuovere questa utenza</p>
                                                    <p>Sei sicuro?</p>
                                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                                        <FontAwesomeIcon 
                                                            icon={faCheck} 
                                                            id={tab.id}
                                                            onClick={TabUtenzeDeletion} 
                                                            size="2x"
                                                            style={{cursor: 'pointer', color: '#33448f'}}/>
                                                        <FontAwesomeIcon 
                                                            icon={faTimes} 
                                                            onClick={closeasktoRemoveModal} 
                                                            size="2x"
                                                            style={{cursor: 'pointer', color: '#33448f'}}/>
                                                    </div>
                                                </Modal>

                                                <Modal 
                                                    isOpen={modalInfo}
                                                    ariaHideApp={false}
                                                    style={customStyles}>
                                                    <button onClick={closeModalInfo} className="closingbuttonmodal">x</button>
                                                    <p style={{marginTop: '5em'}}>{modaltesto}</p>
                                                </Modal>

                                                <Modal
                                                    isOpen={modalCorrectisOpen}
                                                    ariaHideApp={false}
                                                    style={customStyles}>
                                                    <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                                                    <p style={{marginTop: '5em'}}>Utenza eliminata correttamente</p>
                                                </Modal>

                                                <Modal
                                                    isOpen={modalErrorisOpen}
                                                    ariaHideApp={false}
                                                    style={customStyles}>
                                                    <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                                                    <p style={{marginTop: '5em'}}>Non è stato possibile eliminare il record</p>
                                                </Modal>
                                            </tr>
                                            );
                                        })
                                    }
                                    </tbody>
                            </table>
                        </div>
                        {/* QUA CI VA LA PAGINAZIONE */}
                        <Paginazione
                                dataLimit={dataLimit} 
                                getPaginationGroup={getPaginationGroup}
                                goToNextPage={goToNextPage}
                                goToPreviousPage={goToPreviousPage}
                                pages={pages}
                                currentPage={currentPage}
                                changePage={changePage}
                            />
                    </div>

                    <Modal 
                            isOpen={modalChooseColumn}
                            ariaHideApp={false}
                            style={chooseColumnStyle}>
                                <button style={{alignSelf: 'flex-end'}}className="closingbuttonmodal" onClick={closeChooseColumnModal}>x</button>
                                <div className="checkboxSelection">
                                    <label htmlFor="data-ora">Data - Ora</label>
                                    <input type="checkbox" name="data-ora" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="nome">Nome</label>
                                    <input type="checkbox" name="nome" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="cognome">Cognome</label>
                                    <input type="checkbox" name="cognome" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="indirizzo">Indirizzo</label>
                                    <input type="checkbox" name="indirizzo" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="telefono">Telefono</label>
                                    <input type="checkbox" name="telefono" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="cellulare">Cellulare</label>
                                    <input type="checkbox" name="cellulare" checked readOnly></input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="organico">Organico</label>
                                    <input type="checkbox" name="organico" value={showOrganico} 
                                        onClick={hideShowColumnOrganico} defaultChecked={showOrganico === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="plastica">Plastica</label>
                                    <input type="checkbox" name="plastica" value={showPlastica} 
                                        onClick={hideShowColumnPlastica} defaultChecked={showPlastica === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="vetro">Vetro</label>
                                    <input type="checkbox" name="vetro" value={showVetro} 
                                        onClick={hideShowColumnVetro} defaultChecked={showVetro === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="carta">Carta</label>
                                    <input type="checkbox" name="carta" value={showCarta}
                                        onClick={hideShowColumnCarta} defaultChecked={showCarta === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                                <div className="checkboxSelection">
                                    <label htmlFor="indiff">Indiff.</label>
                                    <input type="checkbox" name="indiff" value={showIndiff}
                                        onClick={hideShowColumnIndiff} defaultChecked={showIndiff === 'table-cell' ? true : false}>
                                    </input>
                                </div>
                        </Modal>
            </div>
        </div>
    );
}

export default ElencoUtenze;