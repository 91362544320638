import React, { useState } from 'react';
/* import { useLocation } from 'react-router'; */
import './ricercamodal.css';

//Componente che funziona come ricerca per la tabella della dashboard

function Filtraggio( props ){

    /* const location = useLocation(); */
    const comune = props.comune /* location.state ? location.state.comune : 'capurso'; */
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamata API
    const ricercaApi = "/api/v1/tabella/ricerca";

    //Hooks
    const [campo1, setCampo1] = useState('');
    const [campo2, setCampo2] = useState('');
    const [campo3, setCampo3] = useState('');
    const [campo4, setCampo4] = useState('');
    const [campo5, setCampo5] = useState('');
    const [campo6, setCampo6] = useState('');
    const [campo7, setCampo7] = useState('');
    
    //Props
    const filter = props.setTab;
    const closeModal = props.closeModal;

    const Ricerca = (e) => {

        e.preventDefault();
        closeModal(e);

        //va fatta la get al db con gli stati della ricerca

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("nome", campo1);
            myHeaders.append("cognome", campo2);
            myHeaders.append("indirizzo", campo3);
            myHeaders.append("telefono", campo4);
            myHeaders.append("data", campo5);
            myHeaders.append("ora", campo6);
            myHeaders.append("servizio", campo7);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

        fetch(ricercaApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log('fetch db result ricerca', result)

                const dati = Object.values(result.response.risultati).map(val => {
                    return val;
                })
                filter(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
    }

    return (
        <div id="modalInputWrapper">
            <form>
                <input type="text" placeholder="Nome" value={campo1} onChange={e => setCampo1(e.target.value)}></input>
                <input type="text" placeholder="Cognome" value={campo2} onChange={e => setCampo2(e.target.value)}></input>
                <input type="text" placeholder="Indirizzo" value={campo3} onChange={e => setCampo3(e.target.value)}></input>
                <input type="text" placeholder="Telefono" value={campo4} onChange={e => setCampo4(e.target.value)}></input>
                <input type="date" placeholder="Data" value={campo5} onChange={e => setCampo5(e.target.value)}></input>
                <input type="time" placeholder="Ora" value={campo6} onChange={e => setCampo6(e.target.value)}></input>
                <div className="ricercaservizio">
                    <select placeholder="click per selezionare" 
                            name="servizio" value={campo7} onChange={e => setCampo7(e.target.value)}>
                        <option value="" >Seleziona servizio</option>
                        <option value="Ingombranti">Ingombranti</option>
                        <option value="Segnalazioni">Segnalazioni</option>
                        <option value="Richieste">Richieste</option>
                        <option value="Mancato Ritiro Organico">Mancato Ritiro Organico</option>
                        <option value="Mancato Ritiro Carta">Mancato Ritiro Carta</option>
                        <option value="Mancato Ritiro Vetro">Mancato Ritiro Vetro</option>
                        <option value="Mancato Ritiro Plastica-metallo">Mancato Ritiro Plastica-Metallo</option>
                        <option value="Mancato Ritiro Indifferenziato">Mancato Ritiro Indifferenziato</option>
                    </select>
                </div>
                
                <button onClick={Ricerca} className="buttoncercamodal">Cerca</button>
            </form>
        </div>
    );
}

export default Filtraggio;