import React from 'react'
import './buttonsvuota.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroom } from '@fortawesome/free-solid-svg-icons';


function ButtonSvuota({title2, remove}){
    return(
        <button className="clearbutton" onClick={remove}>
            <FontAwesomeIcon 
                icon={faBroom}
                className="buttonIcon"
            />
            {title2}
        </button>
    );
}

export default ButtonSvuota;