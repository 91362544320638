import React, { useState } from "react";

import './modalricercanuovapratica.css'

//Componente per la ricerca dell'utenza nella pagina del nuovo inserimento e che renderizza la lista dei corrispettivi risultati.

function FiltraggioNuovaPratica( props ){

    //props
    const comune = props.comune;
    const closeModal = props.closeModalRicerca;
    const setId = props.setId;
    const setData = props.setData;
    const setOra = props.setOra;
    const setNome = props.setNome;
    const setCognome = props.setCognome;
    const setIndirizzo = props.setIndirizzo;
    const setCivico = props.setCivico;
    const setTelefono1 = props.setTelefono1;
    const setTelefono2 = props.setTelefono2;
    const setOrganico = props.setOrganico;
    const setPlastica = props.setPlastica;
    const setVetro = props.setVetro;
    const setCarta = props.setCarta;
    const setIndifferenziato = props.setIndifferenziato;

    const token = JSON.parse(localStorage.getItem('token'));
    
    //Chiamata API
    const ricercaElencoUtenzeApi = "/api/v1/tabella/ricercaelencoutenze";

    //Hooks per i campi della ricerca
    const [campo1, setCampo1] = useState('');
    const [campo2, setCampo2] = useState('');
    const [campo3, setCampo3] = useState('');
    const [campo4, setCampo4] = useState('');
    /* const [campo5, setCampo5] = useState('');
    const [campo6, setCampo6] = useState(''); */
    const [ricerca, setRicerca] = useState([]);    

    //Funzione per la ricerca dell'utenza
    const Ricerca = (e) => {

        e.preventDefault();
        /* closeModal(e); */

        //va fatta la get al db con gli stati della ricerca

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("nome", campo1);
            myHeaders.append("cognome", campo2);
            myHeaders.append("indirizzo", campo3);
            myHeaders.append("civico", campo4);
            /* myHeaders.append("data", campo5);
            myHeaders.append("ora", campo6); */
            

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

        fetch(ricercaElencoUtenzeApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                /* console.log('fetch db result ricerca', result) */

                const dati = Object.values(result.response.risultati).map(val => {
                    return val;
                })
                setRicerca(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
    }

    //Funzione per prendere i valori dopo la ricerca per la compilazione
    function getValues(e, id, /* data, ora, */ nome, cognome, indirizzo, civico, telefono, cellulare, organico, plastica, vetro, carta, indif) {
        e.preventDefault();
        setId(id);
        setData(new Date().toISOString().split('T')[0]);
        setOra(new Date().toLocaleTimeString('it-IT'));
        setNome(nome);
        setCognome(cognome);
        setIndirizzo(indirizzo);
        setCivico(civico);
        setTelefono1(telefono ? telefono : '');
        setTelefono2(cellulare ? cellulare : '');
        setOrganico(organico);
        setPlastica(plastica);
        setVetro(vetro);
        setCarta(carta);
        setIndifferenziato(indif)
        closeModal();
    }

    return (
        <div className="modalRicercaUtenzaInputWrapper">
            <form>
                <div className="inputContainer">
                    <div className="firstRow">
                        <input type="text" placeholder="Nome" value={campo1} onChange={e => setCampo1(e.target.value)}></input>
                        <input type="text" placeholder="Cognome" value={campo2} onChange={e => setCampo2(e.target.value)}></input>
                    </div>
                    <div className="secondRow">
                        <input type="text" placeholder="Indirizzo" value={campo3} onChange={e => setCampo3(e.target.value)}></input>
                        <input type="text" placeholder="civico" value={campo4} onChange={e => setCampo4(e.target.value)}></input>
                    </div>
                </div>
                <div className="buttonContainer">
                    <button onClick={Ricerca} className="buttoncercamodal">Cerca</button>
                </div>
            </form>
            <div className="resultContainer" style={{visibility: ricerca.length > 0 ? 'visible' : 'hidden'}}> {/* IL DIV DEVE ESSE VISIBILE SOLO SE LA RICERCA HA PRODOTTO RISULTATI */}
                <table>
                    <thead className="resultContainerKeys">
                        <tr>
                            <th>Nome</th>
                            <th>Cognome</th>
                            <th>Indirizzo</th>
                            <th>Civico</th>
                            <th>Telefono</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            Object.values(ricerca).map((val, i) => {
                                /* console.log('risultato della ricerca', val) */
                                return <tr className="resultRow"
                                    key={val.id}
                                    id={val.id}
                                    onClick={(e) => getValues(e, val.id, /* val.data, val.ora, */ val.nome, val.cognome, val.indirizzo, val.civico, val.telefono1, val.telefono2,
                                    val.organico, val.plastica, val.vetro, val.carta, val.indifferenziato)}>
                                    <td>{val.nome}</td>
                                    <td>{val.cognome}</td>
                                    <td>{val.indirizzo}</td>
                                    <td>{val.civico}</td>
                                    <td>{val.telefono1}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default FiltraggioNuovaPratica;