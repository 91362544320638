import React, { useEffect, useState } from 'react'

import './tabellaAltro.css'

export default function TabellaAltro( props ){

    //variabili d'utility
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamate API
    const altreChiamateTot = '/api/v1/telefonate/altrechiamate'
    const altreChiamateGiorn = '/api/v1/telefonate/altrechiamategiorn'
    const altreChiamateSett = '/api/v1/telefonate/altrechiamatesett'

    //hooks
    const [altroTot, setAltroTot] = useState(0)
    const [altroGiorn, setAltroGiorn] = useState(0)
    const [altroSett, setAltroSett] = useState(0)
    const [totale, setTotale] = useState(0);

    //props
    const isLoading = props.isLoading;
    const showTitles = props.showTitles;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '/' + mm + '/' + dd;

    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("data", today);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(altreChiamateTot, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAltroTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                setTotale(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
            })
        fetch(altreChiamateGiorn, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAltroGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
            })
        fetch(altreChiamateSett, requestOptions)
            .then(response => response.json())
            .then(result => {
                setAltroSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
            })
            .catch(error => console.log(error))
    }, [])

    const addCallAltro = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("data", today);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(altreChiamateTot, requestOptions)
        .then(response => response.json())
        .then(result => {

            console.log('result', result)
            
            var getHeaders = new Headers();
            getHeaders.append("x-access-token", token.accessToken)
            getHeaders.append("data", today)

            var getOptions = {
                method: 'GET',
                headers: getHeaders,
                redirect: 'follow'
            }

            fetch(altreChiamateTot, getOptions)
                .then(response => response.json())
                .then(getResult => {
                    setAltroTot(Number(getResult.dati[0].totale))
                    setTotale(Number(getResult.dati[0].totale))
                })
            fetch(altreChiamateGiorn, getOptions)
                .then(response => response.json())
                .then(getResult => {
                    setAltroGiorn(Number(getResult.dati[0].giornal))
            })
            fetch(altreChiamateSett, getOptions)
                .then(response => response.json())
                .then(getResult => {
                    setAltroSett(Number(getResult.dati[0].totale))
            })
        })
        .catch(error => console.log('error', error));
    };

    return(
        <div className="tabellaAltro">
            <div className="tableTitle">
                <h4>Altre Chiamate</h4>
                <div className="tableTotalCounter" >
                    <h5>Totale:</h5>
                    <span style={{display: showTitles}}>{totale}</span>
                </div>
            </div>
            { isLoading ?
            <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            :
            <div className="datiContainerAltro">
                
                <button style={{display: showTitles}} onClick={(e) => addCallAltro(e)}>Altre Chiamate</button>
                
                <div className="valuesContainer">
                    <div style={{display: showTitles}} className="dataValues">
                        <h4 className="countersTitles">Giornal.</h4>
                        <span>{altroGiorn}</span>
                    </div>
                    <div style={{display: showTitles}} className="dataValues">
                        <h4 className="countersTitles">Settim.</h4>
                        <span>{altroSett}</span>
                    </div>
                    <div style={{display: showTitles}} className="dataValues">
                        <h4 className="countersTitles">Totali</h4>
                        <span>{altroTot}</span>
                    </div>
                </div>
            </div>
            }
        </div>
    )
};