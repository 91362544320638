import React, { useState } from 'react';

//Componente che ricerca gli Operai

function FiltraggioOperai( props ){

    const token = JSON.parse(localStorage.getItem('token'));

    //Hooks per l'inserimento
    const [campo1, setCampo1] = useState('');
    const [campo2, setCampo2] = useState('');

    //Chiamata API
    const ricercaOperaiApi = '/api/v1/operai/ricercaoperai';

    //Props
    const filterOperai = props.setShowOperai;
    const closeModal = props.closeModal;

    //Funzione per la ricerca
    const RicercaOperai = (e) => {

        e.preventDefault();
        closeModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("operaio", campo1);
            myHeaders.append("telefono", campo2);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(ricercaOperaiApi, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)

                const dati = Object.values(result.response.risultati).map(val => {
                    return val;
                })
                filterOperai((values) => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
        }

    return (
        <div id="modalInputWrapper">
            <form>

                <input type="text" placeholder="Nome" value={campo1} onChange={e => setCampo1(e.target.value)}></input>
                <input type="text" placeholder="Telefono" value={campo2} onChange={e => setCampo2(e.target.value)}></input>
                
                <button onClick={RicercaOperai}>Cerca</button>
            </form>
        </div>
    );
}

export default FiltraggioOperai;