import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { CSVLink } from 'react-csv'
import Modal from 'react-modal'

import Sidebar from '../comuni/sidebar';
import './esporta.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';

function Esporta(){

    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const token = JSON.parse(localStorage.getItem('token'));
    
    //chiamata API
    const ricercaDataAPI = '/api/v1/tabella/ricercadata';

    //hooks
    const [data1, setData1] = useState('');
    const [data2, setData2] = useState('');
    const [servizio, setServizio] = useState('');
    const [showDiv, setShowDiv] = useState('none');
    const [dati, setDati] = useState([]);
    const [modalinfo, setModalInfo] = useState(false);
    const [showDownload, setShowDownload] = useState('none'); //stato per visualizzare il bottone download una volta fetchati i dati
    const [showSpinner, setShowSpinner] = useState('none');
    const [showPlus, setShowPlus] = useState('block');
    const [showMinus, setShowMinus] = useState('none');

    //variabile contenente il nome del file che verrà generato con le date inserite
    const formattedFileName = comune.charAt(0).toUpperCase() + comune.slice(1) + "-" + data1 + "-" + data2 + ".csv"

    const openModalinfo = () => {
        setModalInfo(true);
    }

    const closeModalinfo = () => {
        setModalInfo(false);
    }

    const customStyle = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            background: 'rgba(243, 243, 243, 1)',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    }

    //funzione per prendere i dati nel range di ricerca della data
    const getData = (e) => {

        e.preventDefault();

        if(!data1 && !data2){
            openModalinfo();
        } else {

            setShowSpinner('flex');

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("data1", data1);
            myHeaders.append("data2", data2);
            myHeaders.append("servizio", servizio);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(ricercaDataAPI, requestOptions)
        .then(response => response.json())
        .then(result => { 
            //console.log(result.tab)

            const fetchResult = Object.values(result.tab).map(val => {
                return val
            })

            setDati(() => {
                return [...fetchResult]
            })

            setShowSpinner('none');
        })
        .catch(error => console.log('error', error));
        }
    };

    //useEffect che gestisce la comparsa del bottone download
    useEffect(() => {
        if(dati.length > 0){
            setShowDownload('flex')
        } else {
            setShowDownload('none')
        }
    }, [dati])

    const showOtherInput = () => {
        if(showDiv === 'none'){
            setShowDiv('flex');
            setShowMinus('block');
            setShowPlus('none');
        }
    }

    const hideOtherInput = () => {
        if(showDiv === 'flex'){
            setShowDiv('none');
            setShowPlus('flex');
            setShowMinus('none');
        }
    }

    return( 
            <div className="esportawrapper">
                <Sidebar/>
                <div className="wrapperesporta">
                    <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                    <div className="ricercadati">
                        <h4>Ricerca dati</h4>
                        <div className="inputData">
                            <label htmlFor="data1">Dal</label>
                            <input type="date" name="data1" value={data1} onChange={e => setData1(e.target.value)}></input>
                            <label htmlFor="data2">Al</label>
                            <input type="date" name="data2" value={data2} onChange={e => setData2(e.target.value)}></input>
                        </div>
                        <div className="addField">
                            <FontAwesomeIcon 
                                icon={faPlusCircle}
                                style={{display: showPlus}}
                                size="lg"
                                onClick={showOtherInput}
                            />
                            <FontAwesomeIcon 
                                icon={faMinusCircle}
                                style={{display: showMinus}}
                                size="lg"
                                onClick={hideOtherInput}
                            />
                        </div>

                        {/* DIV VISIBILE SOLO SE CLICCATO IL BOTTONE + */}
                        <div className="hideInput" style={{display: showDiv}}>
                        
                            <label htmlFor="servizio">Servizio</label>
                            <select className="selectmenu2" id="servizio" placeholder={'Servizio'} 
                                    name="servizio" value={servizio} onChange={e => setServizio(e.target.value)}>
                                <option value=""> </option>
                                <option value="Ingombranti">Ingombranti</option>
                                <option value="Segnalazioni">Segnalazioni</option>
                                <option value="Richieste">Richieste</option>
                                <option value="Mancato Ritiro Organico">Mancato Ritiro Organico</option>
                                <option value="Mancato Ritiro Carta">Mancato Ritiro Carta</option>
                                <option value="Mancato Ritiro Vetro">Mancato Ritiro Vetro</option>
                                <option value="Mancato Ritiro Plastica-metallo">Mancato Ritiro Plastica-Metallo</option>
                                <option value="Mancato Ritiro Indifferenziato">Mancato Ritiro Indifferenziato</option>
                                {
                                    comune === 'fara_in_sabina' || comune === 'morlupo'
                                    ? <option value="Potature">Potature</option>
                                    : null
                                }
                            </select>
                        </div>

                        <div className="esportaButtonContainer">
                            <button className="esportaricercabutton" onClick={getData}>Avvia ricerca</button>
                        </div>

                        <div style={{alignSelf: 'flex-end', width: '100%'}}>
                            <FontAwesomeIcon 
                                icon={faSpinner}
                                size="3x"
                                style={{display: showSpinner}}
                                className="spinner"
                            />
                        </div>
                        
                        <div style={{display: showDownload, alignSelf: 'flex-end', width: '100%'}}>
                            
                            <CSVLink 
                                data={dati} 
                                separator={";"}
                                filename={formattedFileName}
                                className="downloadlink"> 
                                Download 
                            </CSVLink>

                        </div>
                    </div>
                </div>
                <Modal 
                    isOpen={modalinfo}
                    ariaHideApp={false}
                    style={customStyle}>
                    <button onClick={closeModalinfo} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '3em'}}>Inserisci il range di ricerca</p>
                </Modal>
            </div>
    );
}

export default Esporta;