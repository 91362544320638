import React from 'react';

import { MapContainer, TileLayer, Marker, Popup, useMapEvent } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import marker from 'leaflet/dist/images/marker-icon.png'
import { Icon } from 'leaflet';
import './mappa.css'



export default function Mappa( props ){

    const lat = props.lat;
    const long = props.long;
    const civico = props.civico;
    const indirizzo = props.indirizzo;
    const comune = props.comune;
    const comuneRewrote = comune.replace(/_/g, " ");
    const coordinateCentro = props.coordinateCentro;

    function FindLocationMarker(){
        const map = useMapEvent({
            Load(){
                map.locate()
            },
            locationfound(){
                map.flyTo([lat, long], map.getZoom(17))
            }
        })

        return lat === null || long === null ? 
        <MapContainer center={lat == null || long == null ? coordinateCentro : [lat, long]} 
            zoom={16} 
            scrollWheelZoom={true}
            style={{ height: "100%", width: "100%" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={coordinateCentro} icon={new Icon({iconUrl: marker, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>Centro del paese, nessun indirizzo rilevato</Popup>
            </Marker>
        </MapContainer>
        :
        <MapContainer 
            center={lat != null || long != null ? [lat, long] : coordinateCentro} 
            zoom={17} 
            scrollWheelZoom={true}
            style={{ height: "100%", width: "100%" }}>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker position={[lat, long]} icon={new Icon({iconUrl: marker, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>{indirizzo}, {civico}, {comuneRewrote}</Popup>
            </Marker>
        </MapContainer>
        
    }

    return (
        <div id="mapContainer">
            <MapContainer 
                center={lat != null || long != null ? [lat, long] : coordinateCentro} 
                zoom={17} 
                scrollWheelZoom={true}
                style={{ height: "100%", width: "100%" }}>
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                <FindLocationMarker />
            </MapContainer>
        </div>
    );
};