import React, { createContext, useState } from 'react'


export const TokenContext = createContext();


export function TokenProvider(props){
    

    const [user1, setUser1] = useState({
        username: 'USER-1',
        password: 'Password1!'
    });

    const [user2, setUser2] = useState({
        username: 'USER-2',
        password: 'Password2!'
    })

    const [user3, setUser3] = useState({
        username: 'USER-3',
        password: 'Password3!'
    })

    return (
        <TokenContext.Provider value={{ user1: [user1, setUser1], user2: [user2, setUser2], user3: [user3, setUser3]}}>
            {props.children}
        </TokenContext.Provider>
    );
};