import React, {useState, useEffect, useContext} from 'react';
import { useLocation, useHistory } from 'react-router';
/* import { Link } from 'react-router-dom'; */
import { ConfigContext } from '../context/config';
import './reminder.css'
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCouch, faExclamation, faUsers } from '@fortawesome/free-solid-svg-icons';

//Componente che si occupa di renderizzare la parte superiore della dashboard. Nelle 4 card sono presenti rispettivamente: il count delle notifiche, che se cliccato,
//porta alla pagina delle notifiche in cui è possibile consultare le singole notifiche. Un count per gli ingombranti, uno per le segnalazioni ed un count
//delle utenze univoche presenti nel comune. Infine una card che se cliccata porta, attraverso un link, alla pagina del comune in questione.

function Reminder( props ){

    const history = useHistory();
    const location = useLocation();
    const comune = location.state ? location.state.comune : 'capurso';
    const token = JSON.parse(localStorage.getItem('token'));
    const file = useContext(ConfigContext); //file config
    const sitoComuni = file.config.sitoComuni;
    const setReminder = props.setReminder;

    //chiamate API
    const countReminderApi = '/api/v1/reminder/countReminder'
    const tabellaCountIngombrantiApi = '/api/v1/tabella/countingombranti';
    const tabellaCountSegnalazioniApi = '/api/v1/tabella/countsegnalazioni';
    const tabUtenzeApi = "/api/v1/tabella/utenze";

    //hooks
    const [countIngombranti, setCountIngombranti] = useState(0);
    const [countSegnalazioni, setCountSegnalazioni] = useState(0);
    const [countUtenze, setCountUtenze] = useState(0);
    const [unauthorizedModal, setUnauthorizedModal] = useState(false);

    //hooks per la transizione dell'overlay del link al sito del comune
    const [opacitySfondo, setOpacitySfondo] = useState(1);
    const [opacityLayer, setOpacityLayer] = useState(0);
    const [displaySfondo, setDisplaySfondo] = useState('flex');
    const [displayLayer, setDisplayLayer] = useState('none')
    
    //useEffect per mostrare il counting degli ingombranti
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };
    
        fetch(tabellaCountIngombrantiApi, requestOptions)
            .then(response => response.json())
            .then(result => {

                const dati = Object.values(result).map(val => {
                    return val;
                })
                setCountIngombranti(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
        }, [token.accessToken, comune]);

    //useEffect per mostrare il counting delle segnalazioni
    useEffect(() => {
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
        
            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        
            fetch(tabellaCountSegnalazioniApi, requestOptions)
                .then(response => response.json())
                .then(result => {
    
                    const dati = Object.values(result).map(val => {
                        return val;
                    })
                    setCountSegnalazioni(() => {
                        return [...dati]
                    })
                })
                .catch(error => console.log('error', error));
    }, [token.accessToken, comune]);

    //useEffect per mostrare il counting delle utenze servite
    useEffect(() => {
        var myHeaders = new Headers();
                myHeaders.append("x-access-token", token.accessToken);
                myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1))
    
            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
    
            fetch(tabUtenzeApi, requestOptions)
                .then(response => response.json())
                .then(result => {
    
                    const dati = Object.values(result.response.risultati).filter(item => item.deleted === false).map(val => {
                        return val;
                })
                setCountUtenze(() => {
                    return [...dati]
                })

                })
                .catch(error => console.log('error', error));
    }, [token.accessToken, comune]);

    //UseEffect per mostrare il count dei reminder
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
};

    fetch(countReminderApi, requestOptions)
            .then(response => {
                //se la response mi da un 401 significa che l'utente deve riconnettersi
                if(response.status === 401){
                    OpenModalUnauthorized();
                } else {
                    return response.json();
                }
            })
            .then(reminderData => {

                const dati = Object.values(reminderData).map(val => {
                    return val;
                })
                setReminder(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
    }, [token.accessToken, comune, setReminder])

    const OpenModalUnauthorized = () => {
        setUnauthorizedModal(true);
    };

    const CloseModalUnauthorized = () => {
        history.push('/')
        setUnauthorizedModal(false);
    }

    const customStylesGoLogin = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    }; 

    const showTransition = () => {
        setOpacitySfondo(0);
        setOpacityLayer(1);
        setDisplaySfondo('none');
        setDisplayLayer('flex');
    }

    const hideTransition = () => {
        setOpacitySfondo(1);
        setOpacityLayer(0);
        setDisplaySfondo('flex');
        setDisplayLayer('none');
    }

    return(
        <div className="reminder">
            <div className="countinginfos">
                {/* <Link 
                    to={{pathname: '/servizio/' + comune.split(" ").join("_") + "/notifiche",
                        state: {"comune": comune.split(" ").join("_"),
                                token: token.accessToken,
                                username: token.username
                            }}}
                        style={{textDecoration: 'none'}}>
                    <div className="divcountingnotifiche">
                        <FontAwesomeIcon 
                            className="imgdivnotifiche"
                            icon={faBell}
                            size="1x"
                        />
                        <h4 className="countnotifiche">{reminder}</h4>
                        <h4 className="pdivs">Apri Notifiche</h4>
                    </div>
                </Link> */}
                <div className="divcountingombranti">
                    <FontAwesomeIcon 
                        className="imgdivingombranti"
                        icon={faCouch}
                        size="1x"
                    />
                    <h4 className="countingombranti">{countIngombranti}</h4>
                    <h4 className="pdivs">Ingombranti</h4>
                </div>
                <div className="divcountsegnalazioni">
                    <FontAwesomeIcon 
                        className="imgdivsegnalazioni"
                        icon={faExclamation}
                        size="1x"
                    />
                    <h4 className="countsegnalazioni">{countSegnalazioni}</h4>
                    <h4 className="pdivs">Segnalazioni</h4>
                </div>
                <div className="divcountutenti">
                    <FontAwesomeIcon 
                        className="imgdivutenti"
                        icon={faUsers}
                        size="1x"
                    />
                    <h4 className="countutenze">{countUtenze.length}</h4>
                    <h4 className="pdivs">Utenze Servite</h4>
                </div>
            </div>
            <div className="cardreminderwrapper">
                <div className="reminderCards">
                    <div 
                        style={{backgroundImage: `url(/images/imgcomuni/${comune}.jpg)`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: '40%',
                        opacity: opacitySfondo,
                        display: displaySfondo}} 
                        alt="img_comune" className="imgdiv"
                        onMouseEnter={showTransition}>
                    </div>
                    <a href={sitoComuni[comune]} target="_blank" rel="noreferrer" style={{textDecoration: 'none'}}>
                        <div 
                            style={{
                                background: 'linear-gradient(135deg, hsla(234, 89%, 32%, 0.74), hsla(234, 89%, 32%, 0.74))',
                                color: '#fff',
                                opacity: opacityLayer,
                                display: displayLayer,
                                justifyContent: 'center'}}
                                onMouseLeave={hideTransition}
                                className="imgdiv">
                            <h4>Vai al sito di {comune.split("_").join(" ").toUpperCase()}</h4>
                        </div>
                    </a>
                </div>
            </div>

                {/* Modale che avverte del token scaduto */}
                <Modal 
                    isOpen={unauthorizedModal}
                    ariaHideApp={false}
                    style={customStylesGoLogin}>
                    <button onClick={CloseModalUnauthorized} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '3em'}}>Attenzione sessione scaduta! Effettua nuovamente l'accesso</p>
                    <p></p>
                    <button onClick={CloseModalUnauthorized} className="gobacklogin">Login</button>
                </Modal>
        </div>
    );
}

export default Reminder;