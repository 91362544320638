import React, { useState } from 'react';

//Componente per ricercare le utenze nella pagina delle potature

function FiltraggioPotature( props ){

    //Props
    const comune = props.comune;
    const filterPotature = props.setShowPotature;
    const closeModal = props.closeModal;

    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamata API
    const ricercaPotatureApi = "/api/v1/potature/ricercapotature";

    //Hooks per i campi della ricerca
    const [campo1, setCampo1] = useState('');
    const [campo2, setCampo2] = useState('');
    const [campo3, setCampo3] = useState('');
    const [campo4, setCampo4] = useState('');
    const [campo5, setCampo5] = useState('');
    const [campo6, setCampo6] = useState('');

    //Funzione per effettuare la ricerca
    const RicercaElencoUtenze = (e) => {

        e.preventDefault();
        closeModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken );
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("nome", campo1);
            myHeaders.append("cognome", campo2);
            myHeaders.append("telefono", campo4);
            myHeaders.append("data", campo5);
            myHeaders.append("ora", campo6);
            myHeaders.append("indirizzo", campo3);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

    fetch(ricercaPotatureApi, requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result)

            const dati = Object.values(result.response.risultati).map(val => {
                return val;
            })
            filterPotature((values) => {
                return [...dati]
            })
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div id="modalInputWrapper">
            <form>

                <input type="text" placeholder="Nome" value={campo1} onChange={e => setCampo1(e.target.value)}></input>
                <input type="text" placeholder="Cognome" value={campo2} onChange={e => setCampo2(e.target.value)}></input>
                <input type="text" placeholder="Indirizzo" value={campo3} onChange={e => setCampo3(e.target.value)}></input>
                <input type="text" placeholder="Telefono" value={campo4} onChange={e => setCampo4(e.target.value)}></input>
                <input type="text" placeholder="Data" value={campo5} onChange={e => setCampo5(e.target.value)}></input>
                <input type="text" placeholder="Ora" value={campo6} onChange={e => setCampo6(e.target.value)}></input>
                
                <button onClick={RicercaElencoUtenze}>Cerca</button>
            </form>
        </div>
    );
}

export default FiltraggioPotature;