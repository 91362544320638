import React, { useState, useRef, useEffect } from 'react'
import ButtonAggiungi from '../buttons/buttonaggiungi'
import ButtonEsci from '../buttons/buttonesci';
import ButtonSvuota from '../buttons/buttonsvuota';
import { useLocation } from 'react-router';
import { useHistory, useParams } from 'react-router-dom';
import Modal from 'react-modal';

import './nuovapratica.css';
import Sidebar from '../comuni/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import FiltraggioNuovaPratica from '../comuni/modals/modalricercanuovapratica';

//Componente che permette l'inserimento di una nuova pratica inerente le segnalazioni con la possibilità di cercare un utenza e precompilare i campi

function NuovaPratica(){

    const history = useHistory();
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const token = JSON.parse(localStorage.getItem('token'));
    
    //Chiamata API
    const inserimentoApi = "/api/v1/tabella/inserimento";
    const inserimentoPotatureApi = "/api/v1/potature/postpotature";

    //hook per l'acquisizione dell'id qualora sia un utenza esistente
    const [id, setId] = useState('');

    //hook per acquisizione data
    const [data, setData] = useState('');
    
    //hook per acquisizione ora
    const [ora, setOra] = useState('');

    //hook per acquisire nome
    const [nome, setNome] = useState('');

    //hook per acquisire cognome
    const [cognome, setCognome] = useState('');

    //hook per il telefono di casa
    const [telefono1, setTelefono1] = useState('');

    //hook per il telefono cellulare
    const [telefono2, setTelefono2] = useState('');

    //hook per l'indirizzo
    const [indirizzo, setIndirizzo] = useState('');

    //hook per il civico
    const [civico, setCivico] = useState('');

    //hook per il tipo
    const [tipo, setTipo] = useState('');

    //hook per il servizio
    const [servizio, setServizio] = useState('');

    //hook per la richiesta
    const [richiesta, setRichiesta] = useState('');

    //hook per il campo organico
    const [organico, setOrganico] = useState('');

    //hook per il campo plastica
    const [plastica, setPlastica] = useState('');

    //hook per il campo vetro
    const [vetro, setVetro] = useState('');

    //hook per il campo carta
    const [carta, setCarta] = useState('');

    //hook per il campo indifferenziato
    const [indifferenziato, setIndifferenziato] = useState('');

    //hook per campo info
    const [info, setInfo] = useState('');

    //hook per campo note
    const [note, setNote] = useState('');

    //hook per il count
    const [count, setCount] = useState(0);

    //hooks per l'apertura dei modali
    const [modalAddisOpen, setmodalAddisOpen] = useState(false);
    const [modalClearisOpen, setmodalClearisOpen] = useState(false);
    const [modalisOpen, setModalisOpen] = useState(false);
    
    //stati per gestire gli errori negli inserimenti
    const [errorCompileData, setErrorCompileData] = useState('rgb(243, 243, 243)');
    const [errorCompileOra, setErrorCompileOra] = useState('rgb(243, 243, 243)');
    const [errorCompileNome, setErrorCompileNome] = useState('rgb(243, 243, 243)');
    const [errorCompileCognome, setErrorCompileCognome] = useState('rgb(243, 243, 243)');
    const [errorCompileIndirizzo, setErrorCompileIndirizzo] = useState('rgb(243, 243, 243)');
    const [errorCompileCivico, setErrorCompileCivico] = useState('rgb(243, 243, 243)');
    const [errorCompileTelefono, setErrorCompileTelefono] = useState('rgb(243,243,243)');
    const [errorCompileTipo, setErrorCompileTipo] = useState('rgb(243, 243, 243)');
    const [errorCompileServizio, setErrorCompileServizio] = useState('rgb(243, 243, 243)');
    const [errorCompileRichiesta, setErrorCompileRichiesta] = useState('rgb(243, 243, 243)');
    const goTo = useRef(null);

    useEffect(() => {
        setData(new Date().toISOString().split('T')[0])
        setOra(new Date().toLocaleTimeString('it-IT'))
    }, [])

    const openModalAdd = () => {
        setmodalAddisOpen(true);
    }

    const closeModalAdd = () => {
        setmodalAddisOpen(false);
        setData('');
        setOra('');
        setNome('');
        setCognome('');
        setIndirizzo('');
        setCivico('');
        setTelefono1('');
        setTelefono2('');
        setTipo('');
        setServizio('');
        setRichiesta('');
        setOrganico('');
        setPlastica('');
        setVetro('');
        setCarta('');
        setIndifferenziato('');
        setInfo('');
        setNote('');
        setErrorCompileData('rgb(243, 243, 243)');
        setErrorCompileOra('rgb(243, 243, 243)');
        setErrorCompileNome('rgb(243, 243, 243)');
        setErrorCompileCognome('rgb(243, 243, 243)');
        setErrorCompileIndirizzo('rgb(243, 243, 243)');
        setErrorCompileCivico('rgb(243, 243, 243)');
        setErrorCompileTelefono('rgb(243, 243, 243)');
        setErrorCompileTipo('rgb(243, 243, 243)');
        setErrorCompileServizio('rgb(243, 243, 243)');
        setErrorCompileRichiesta('rgb(243, 243, 243)');
        history.goBack();
    }

    const openModalClear = () => {
        setmodalClearisOpen(true);
    }

    const closeModalClear = () => {
        setmodalClearisOpen(false);
    }

    const handleScroll = () => {
        goTo.current.scrollIntoView({ behavior: 'smooth' })
    }

    const Aggiungi = (e) => {

        /* console.log('servizio', servizio) */
        //va fatta una post al db
        e.preventDefault();

        //se servizio è Potature va fatta la chiamata api all'inserimento potature
        if(servizio === 'Potature'){
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("username", token.username);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            
            let urlencoded = new URLSearchParams();
            urlencoded.append("utenteId", id)
            urlencoded.append("data", data);
            urlencoded.append("ora", ora);
            urlencoded.append("nome", nome);
            urlencoded.append("cognome", cognome);
            urlencoded.append("indirizzo", indirizzo);
            urlencoded.append("civico", civico);
            urlencoded.append("richiesta", richiesta);
            urlencoded.append("telefono", telefono1);
            urlencoded.append("note", note)
            
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(inserimentoPotatureApi, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalAdd(e);
                }
                /* else if(response.status === 403){
                    openModalAlreadyExist(e);
                } */
                else if(response.status === 400) {
                    /* if(!data){
                        handleScroll()
                        setErrorCompileData('#F08080');
                    }
                    if(!ora){
                        handleScroll();
                        setErrorCompileOra('#F08080');
                    } */
                    if(!nome){
                        handleScroll();
                        setErrorCompileNome('#F08080');
                    }
                    if(!cognome){
                        handleScroll();
                        setErrorCompileCognome('#F08080');
                    }
                    if(!indirizzo){
                        handleScroll();
                        setErrorCompileIndirizzo('#F08080');
                    }
                    if(!civico){
                        handleScroll();
                        setErrorCompileCivico('#F08080');
                    }
                    if(!telefono1){
                        handleScroll();
                        setErrorCompileTelefono('#F08080');
                    }
                    if(!tipo){
                        handleScroll();
                        setErrorCompileTipo('#F08080');
                    }
                    if(!servizio){
                        handleScroll();
                        setErrorCompileServizio('#F08080');
                    }
                    if(!richiesta){
                        handleScroll();
                        setErrorCompileRichiesta('#F08080');
                    }
                }
            })
            //.then(result => console.log(result))
            .catch(error => console.log('error', error));
        }
        //altrimenti si fa la chiamata api all'endpoint inserimento
            let myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("username", token.username);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            
            let urlencoded = new URLSearchParams();
            urlencoded.append("utenteId", id)
            urlencoded.append("data", data);
            urlencoded.append("ora", ora);
            urlencoded.append("nome", nome);
            urlencoded.append("cognome", cognome);
            urlencoded.append("indirizzo", indirizzo);
            urlencoded.append("civico", civico);
            urlencoded.append("telefono1", telefono1);
            urlencoded.append("telefono2", telefono2);
            urlencoded.append("fraz", comune.split("_").join(" "));
            urlencoded.append("tipo", tipo);
            urlencoded.append("servizio", servizio);
            urlencoded.append("richiesta", richiesta);
            urlencoded.append("organico", organico);
            urlencoded.append("plastica", plastica);
            urlencoded.append("vetro", vetro);
            urlencoded.append("carta", carta);
            urlencoded.append("indifferenziato", indifferenziato);
            urlencoded.append("info", info);
            urlencoded.append("note", note);
            if(servizio === 'Ingombranti'){
                setCount(prevState => prevState + 1)
            urlencoded.append("count", count);
            }
            
            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: urlencoded,
                redirect: 'follow'
            };

            fetch(inserimentoApi, requestOptions)
                .then(response => {
                    response.json()
                    if(response.status === 200){
                        openModalAdd(e);
                    }
                    /* else if(response.status === 403){
                        openModalAlreadyExist(e);
                    } */
                    else if(response.status === 400) {
                        /* if(!data){
                            handleScroll()
                            setErrorCompileData('#F08080');
                        }
                        if(!ora){
                            handleScroll();
                            setErrorCompileOra('#F08080');
                        } */
                        if(!nome){
                            handleScroll();
                            setErrorCompileNome('#F08080');
                        }
                        if(!cognome){
                            handleScroll();
                            setErrorCompileCognome('#F08080');
                        }
                        if(!indirizzo){
                            handleScroll();
                            setErrorCompileIndirizzo('#F08080');
                        }
                        if(!civico){
                            handleScroll();
                            setErrorCompileCivico('#F08080');
                        }
                        if(!telefono1){
                            handleScroll();
                            setErrorCompileTelefono('#F08080');
                        }
                        if(!tipo){
                            handleScroll();
                            setErrorCompileTipo('#F08080');
                        }
                        if(!servizio){
                            handleScroll();
                            setErrorCompileServizio('#F08080');
                        }
                        if(!richiesta){
                            handleScroll();
                            setErrorCompileRichiesta('#F08080');
                        }
                    }
                })
                //.then(result => console.log(result))
                .catch(error => console.log('error', error));

    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const Clear = (e) => {
        e.preventDefault();
        setData('')
        setOra('')
        setNome('')
        setCognome('')
        setTelefono1('')
        setTelefono2('')
        setIndirizzo('')
        setCivico('')
        setTipo('')
        setServizio('')
        setRichiesta('')
        setOrganico('')
        setPlastica('')
        setVetro('')
        setCarta('')
        setIndifferenziato('')
        setInfo('')
        setNote('')
        openModalClear(e);
        setErrorCompileData('rgb(243, 243, 243)')
        setErrorCompileOra('rgb(243, 243, 243)')
        setErrorCompileNome('rgb(243, 243, 243)')
        setErrorCompileCognome('rgb(243, 243, 243)')
        setErrorCompileIndirizzo('rgb(243, 243, 243)')
        setErrorCompileCivico('rgb(243, 243, 243)')
        setErrorCompileTelefono('rgb(243, 243, 243)')
        setErrorCompileTipo('rgb(243, 243, 243)')
        setErrorCompileServizio('rgb(243, 243, 243)')
        setErrorCompileRichiesta('rgb(243, 243, 243)')
    }

    const Exit = (e) => {
        e.preventDefault();
        history.goBack();
    }

    const openModalRicerca = (e) => {
        e.preventDefault();
        setModalisOpen(true);
    }

    const closeModalRicerca = () => {
        setModalisOpen(false);
    }

    const customStyleRicercaModal = {
        content: {
            top: '15%',
            left: '25%',
            height: '50vh',
            padding: '1%',
            width: '50vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em',
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    return(
        <div className="nuovapratica">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="formwrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                <form>

                    <h5>I campi con * sono obbligatori</h5>

                    <div className="search">
                        <button id="iconSearch" onClick={openModalRicerca}>
                            <p>CERCA</p>
                            <FontAwesomeIcon style={{ marginLeft: '.3em', alignSelf: 'center'}}
                                icon={faSearch}
                                size="1x"
                            />
                        </button>
                        <Modal 
                            isOpen={modalisOpen}
                            ariaHideApp={false}
                            style={customStyleRicercaModal}
                        >
                            <button onClick={closeModalRicerca} className="closingfiltermodal">x</button>
                            <FiltraggioNuovaPratica 
                                comune={comune} 
                                closeModalRicerca={closeModalRicerca}
                                setId={setId}
                                setData={setData}
                                setOra={setOra}
                                setNome={setNome}
                                setCognome={setCognome}
                                setIndirizzo={setIndirizzo}
                                setCivico={setCivico}
                                setTelefono1={setTelefono1}
                                setTelefono2={setTelefono2}
                                setOrganico={setOrganico}
                                setPlastica={setPlastica}
                                setVetro={setVetro}
                                setCarta={setCarta}
                                setIndifferenziato={setIndifferenziato}/>
                        </Modal>
                    </div>

                    <section className="oraedata" ref={goTo}>
                        <label htmlFor="data">Data*</label>
                        <input style={{background: errorCompileData}} placeholder={new Date().toLocaleDateString('it-IT')} type="date" name="data" value={new Date().toISOString().split('T')[0]/* data ? data : '' */} onChange={e => setData(e.target.value)}></input>

                        <label htmlFor="ora">Ora*</label>
                        <input style={{background: errorCompileOra}} placeholder={new Date().toLocaleTimeString('it-IT')} type="time" name="ora" value={new Date().toLocaleTimeString('it-IT')} onChange={e => setOra(e.target.value)}></input>
                    </section>

                    {/* <h4>Informazioni Personali*</h4> */}

                    <section className="generalinfos">

                        <div className="userId">
                            <label htmlFor="userId">UserId</label>
                            <input id="idInput" type="text" name="userId" placeholder={id ? id : ''} disabled></input>
                        </div>

                        <div className="nomecognome">
                            <label htmlFor="nome">Nome*</label>
                            <input style={{background: errorCompileNome}} type="text" name="nome" placeholder="Inserisci il nome" value={nome} onChange={e => setNome(e.target.value)}></input>

                            <label htmlFor="cognome">Cognome*</label>
                            <input style={{background: errorCompileCognome}} type="text" name="cognome" placeholder="Inserisci il cognome" value={cognome} onChange={e => setCognome(e.target.value)}></input>
                        </div>
                        
                        <div className="infoindirizzo">
                            <label htmlFor="indirizzo">Indirizzo*</label>
                            <input style={{background: errorCompileIndirizzo}} type="text" name="indirizzo" value={indirizzo ? indirizzo : ''} placeholder="Inserisci l'indirizzo"
                                onChange={e => setIndirizzo(e.target.value)}></input>

                            <label htmlFor="civico">Civico*</label>
                            <input style={{background: errorCompileCivico}} type="text" placeholder="Civico" name="civico" value={civico ? civico : ''} 
                                onChange={e => setCivico(e.target.value)}></input>
                        </div>

                        <div className="fraz">
                            <label htmlFor="fraz/comune">Fraz./Comune</label>
                            <input type="text" name="fraz/comune" value={comune.split("_").join(" ")} disabled /* onChange={e => setFraz(e.target.value)} */></input>
                        </div>
                    </section>

                    {/* <h4>Informazioni di Contatto*</h4> */}

                    <section className="contactinfos">

                        <label htmlFor="telefono1">Telefono 1*</label>
                        <input style={{background: errorCompileTelefono}} type="text" placeholder="telefono di casa" name="telefono1" 
                        value={telefono1 ? telefono1 : ''} onChange={e => setTelefono1(e.target.value)}></input>

                        <label htmlFor="telefono2">Telefono 2</label>
                        <input type="text" placeholder="telefono cellulare" name="telefono2" 
                        value={telefono2 ? telefono2 : ''} onChange={e => setTelefono2(e.target.value)}></input>

                    </section>

                    {/* <h4>Informazioni Richiesta*</h4> */}

                        <section className="inforichiesta">
                            <label htmlFor="tipo">Tipo Utenza*</label>
                            <select style={{background: errorCompileTipo}} className="selectmenu1" id="tipo" placeholder="click per selezionare"
                                name="tipo" value={tipo ? tipo : ''} onChange={e => setTipo(e.target.value)}>
                                <option value=""> </option>
                                <option value="Utenza Domestica">Utenza Domestica</option>
                                <option value="Utenza Commerciale">Utenza Comm.le</option>
                            </select>

                            <label htmlFor="servizio">Servizio Richiesto*</label>
                            <select style={{background: errorCompileServizio}} className="selectmenu2" id="servizio" placeholder="click per selezionare" 
                                name="servizio" value={servizio ? servizio : ''} onChange={e => setServizio(e.target.value)}>
                                <option value=""> </option>
                                <option value="Ingombranti">Ingombranti</option>
                                <option value="Segnalazioni">Segnalazioni</option>
                                <option value="Richieste">Richieste</option>
                                <option value="Mancato Ritiro Organico">Mancato Ritiro Organico</option>
                                <option value="Mancato Ritiro Carta">Mancato Ritiro Carta</option>
                                <option value="Mancato Ritiro Vetro">Mancato Ritiro Vetro</option>
                                <option value="Mancato Ritiro Plastica-metallo">Mancato Ritiro Plastica-Metallo</option>
                                <option value="Mancato Ritiro Indifferenziato">Mancato Ritiro Indifferenziato</option>
                                {
                                    comune === 'fara_in_sabina' || comune === 'morlupo' 
                                    ? <option value="Potature">Potature</option>
                                    : null
                                }
                            </select>

                            <div className="richiesta">
                                <label htmlFor="richiesta">Richiesta*</label>
                                <textarea style={{background: errorCompileRichiesta}} type="text" name="richiesta" value={richiesta ? richiesta : ''} 
                                    onChange={e => setRichiesta(e.target.value)} maxLength="250"></textarea>
                            </div>
                        </section>

                            <h4>Informazioni Utenza PaP</h4>
                            <section className="elencoutenzeinfos">

                                <label htmlFor="indiff">Indiff.</label>
                                <input type="text" name="indiff" placeholder="Indifferenziato" value={indifferenziato ? indifferenziato : ''} 
                                    onChange={e => setIndifferenziato(e.target.value)}></input>

                                <label htmlFor="plastica">Plastica</label>
                                <input type="text" name="plastica" placeholder="Plastica" value={plastica ? plastica : ''} 
                                    onChange={e => setPlastica(e.target.value)}></input>

                                <label htmlFor="vetro">Vetro</label>
                                <input type="text" name="vetro" placeholder="Vetro" value={vetro ? vetro : ''} 
                                    onChange={e => setVetro(e.target.value)}></input>

                                <label htmlFor="carta">Carta</label>
                                <input type="text" name="vetro" placeholder="Carta" value={carta ? carta : ''} 
                                    onChange={e => setCarta(e.target.value)}></input>

                                <label htmlFor="organico">Organico</label>
                                <input type="text" name="organico" placeholder="Organico" value={organico ? organico : ''} 
                                    onChange={e => setOrganico(e.target.value)}></input>

                            </section>

                            <h4>Informazioni Aggiuntive</h4>
                            <section className="infoaggiuntive">

                                <label htmlFor="info">Info utenza</label>
                                <input type="text" name="info" placeholder="Info" value={info ? info : ''}
                                    onChange={e => setInfo(e.target.value)}></input>

                                <label htmlFor="note">Note ritiro</label>
                                <input type="text" name="note" placeholder="Note" value={note ? note : ''}
                                    onChange={e => setNote(e.target.value)}></input>

                            </section>
                    <div className="buttons">
                        <ButtonAggiungi add={Aggiungi} title1="Aggiungi" />
                        <ButtonSvuota remove={Clear} title2="Svuota" />
                        <ButtonEsci abort={Exit} title3="Esci" />
                    </div>
                </form>

                <Modal
                    isOpen={modalAddisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalAdd} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Richiesta aggiunta correttamente</p>
                </Modal>

                {/* <Modal
                    isOpen={modalErrorisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Errore! Compila i campi obbligatori</p>
                </Modal> */}

                <Modal
                    isOpen={modalClearisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalClear} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Campi svuotati correttamente</p>
                </Modal>

                {/* <Modal
                    isOpen={modalAlreadyExist}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalAlreadyExist} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Attenzione! Utente già presente</p>
                </Modal> */}
            </div>
        </div>
    );

}

export default NuovaPratica;