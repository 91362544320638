import React from 'react'
import './buttonesci.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';


function ButtonEsci({title3, abort}){
    return(
        <button className="exitbutton" onClick={abort}>
            <FontAwesomeIcon 
                icon={faTimesCircle}
                className="buttonIcon"
            />
            {title3}
        </button>
    );
}

export default ButtonEsci;