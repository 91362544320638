import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import './phoneCount.css';


export default function PhoneCount( props ){

    //props
    const comune = props.comune;
    const comuneUppercase = comune.charAt(0).toUpperCase() + comune.slice(1);
    const telefonateTot = props.telefonateTot;
    const setTelefonateTot = props.setTelefonateTot;
    const telefonateGiorn = props.telefonateGiorn;
    const setTelefonateGiorn = props.setTelefonateGiorn;
    const token = JSON.parse(localStorage.getItem('token'));

    //hook
    const [broadcastedData, setBroadcastedData] = useState()

    //chiamata API
    const igieneTelTot = '/api/v1/telefonate/igienetot';
    const igieneTelGiornal = '/api/v1/telefonate/igienegiornal';

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '/' + mm + '/' + dd;

    const bc = new BroadcastChannel('myApp');

    //useEffect per settare nello stato i dati ricevuti dalla tabella
    useEffect(() => {
        bc.addEventListener('message', e => {
            setBroadcastedData(e.data)
        })
        return () => {
            setBroadcastedData();
        }
    }, [])

    useEffect(() => {
        if(!broadcastedData){
            return
        } else {
            if(broadcastedData === comuneUppercase){
                setTelefonateGiorn(prev => prev + 1)
                setTelefonateTot(prev => prev + 1)
            }
        }
        /* return () => {
            setTelefonateGiorn(0)
            setTelefonateTot(0)
        } */
    }, [broadcastedData ? broadcastedData[0] : null])

    //useEffect per prelevare il conteggio totale delle chiamate del comune
    useEffect(() => {
        
        let myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(igieneTelTot, requestOptions)
            .then(response => response.json())
            .then(result => {
                setTelefonateTot(Number(result.dati[0].totale))
            })
            .catch(error => console.log('error', error));

            return () => {
                setTelefonateTot(0)
            }
    }, [])

    //useEffect per prelevare le chiamate giornaliere del comune
    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
        myHeaders.append("data", today);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(igieneTelGiornal, requestOptions)
            .then(response => response.json())
            .then(result => {
                setTelefonateGiorn(Number(result.dati[0].giornal))
            })
            .catch(error => console.log('error', error));

        return () => {
            setTelefonateGiorn(0)
        }
    }, [])

    //Funzione per aggiungere una chiamata
    const addCall = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
        myHeaders.append("data", today);
        myHeaders.append("tipologia", "aggiunta");

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(igieneTelTot, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                setTelefonateTot(prev => prev += 1)
                setTelefonateGiorn(prev => prev += 1)
                bc.postMessage([telefonateGiorn, comune, 'aggiunta'])
            }
        })
        .catch(error => console.log('error', error));
    };

    //Funzione per rimuovere una chiamata
    const removeCall = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
        myHeaders.append("data", today);
        myHeaders.append("tipologia", "rimozione");

    var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(igieneTelTot, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                if(telefonateGiorn === 0 || telefonateTot === 0){
                    return
                }
                setTelefonateTot(prev => prev -= 1)
                setTelefonateGiorn(prev => prev -= 1)
                bc.postMessage([telefonateGiorn, comune, 'rimuovi'])
            }
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div className="phoneCountWrapper">
            <div className="divTelefonate">
            <FontAwesomeIcon
                className="imgDivTelefonate" 
                icon={faPhone}/>
            <div className="countDiv">
                <div className="counters">
                    <h4>Giornalieri:</h4>
                    <h4 className="countTelefonate"> {telefonateGiorn ? telefonateGiorn : 0} </h4>
                </div>
                <div className="counters">
                    <h4>Totali:</h4>
                    <h4 className="countTelefonate"> {telefonateTot ? telefonateTot : 0} </h4>
                </div>
            </div>
            </div>
            <div className="divTelefonateSelection">
                <input type='text' placeholder={comune.replace(/_/g, " ")} disabled></input>
                <div className="buttonSelection">
                    <button className="checkButton" onClick={(e) => addCall(e)}>
                        <FontAwesomeIcon 
                            className="checkIcon"
                            icon={faPlus}
                            />
                    </button>
                    <button className="abortButton" onClick={(e) => removeCall(e)}>
                        <FontAwesomeIcon 
                            className="abortIcon"
                            icon={faMinus}
                            />
                    </button>
                </div>
            </div>
        </div>
    );
};