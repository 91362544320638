import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { ConfigContext } from '../context/config';
import './sidebar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faHome, faList, faCalendar, faEdit, faCommentDots, faPenAlt, faCut, faFileImage, faUsers, faFileCsv, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

//Componente che renderizza la Sidebar laterale della dashboard. Al suo interno ci sono i vari link alle pagine specifiche di ogni comune,
//ottenute dal backend con il file config, un link per tornare indietro alla schermata di selezione dei comuni e un link di logout.

function Sidebar( props ){

    const file = useContext(ConfigContext);
    const location = useLocation();
    const comune = location.state ? location.state.comune.split("/")[0] : 'capurso';
    const pagina = file.config.comuni[comune].pagine;
    const comuneSplitted = comune.split(" ").join("_") + "/" ;

    return(
        <div className="sidebar">
            <div className="linkindietro">
                    <Link to={{ pathname: "/home"}} className="linkback">
                        <FontAwesomeIcon className="arrowleft" icon={faArrowLeft} size="1x"/>
                        <p>SELEZIONE DEI COMUNI</p>
                    </Link>
                </div>
            <div className="comune">
                <h1>{props.title}</h1>
                <img src={`/images/loghicomuni/${comune}.png`} alt='stemma_comune'/>
            </div>
            <div className="sidebarWrapperLinks">
            <div className="homewrapper">
            
            <NavLink className="linksidebar"
            activeStyle={{ background: "linear-gradient(45deg,#33448f, #59b6db)", color: '#fff' }}
            to={{
                pathname: "/servizio/" + comune.split(" ").join("_") + "/dashboard",
                state: {"comune": comune.split(" ").join("_") }}}>
                    <FontAwesomeIcon icon={faHome} style={{alignSelf: 'center', padding: '1em'}}/> 
                    <p>DASHBOARD</p>
                </NavLink>
            </div>
            {
                Object.keys(pagina).map((links, i) => {
                    
                    let renderedIcon;
                    switch(pagina[links]){
                        case "edit": renderedIcon = faEdit;
                        break;
                        case "map": renderedIcon = faMapMarkedAlt;
                        break;
                        case "list": renderedIcon = faList;
                        break;
                        case "calendar": renderedIcon = faCalendar;
                        break;
                        case "comment-dots": renderedIcon = faCommentDots;
                        break;
                        case "pen-alt": renderedIcon = faPenAlt;
                        break;
                        case "file-image": renderedIcon = faFileImage;
                        break;
                        case "cut": renderedIcon = faCut;
                        break;
                        case "users": renderedIcon = faUsers;
                        break;
                        case "file-csv": renderedIcon = faFileCsv;
                        break;
                        default: renderedIcon = faHome;
                        break;
                    }
                    return (
                    <div className="linkswrapper" key={links}>
                        <NavLink 
                            className="linksidebar"
                            activeStyle={{ background: "linear-gradient(45deg,#33448f, #59b6db)", color: '#fff' }} 
                            to={{
                                pathname:  "/servizio/" + comuneSplitted + links.split(" ").join("_"),
                                state: {"pagine": links, "comune": comuneSplitted}
                                }} 
                            key={links}
                        >
                            <FontAwesomeIcon 
                                icon={renderedIcon}
                                style={{alignSelf: 'center', padding: '1em'}}
                                key={pagina[links]}
                            />
                            <p key={links}>{links.charAt(0).toUpperCase() + links.slice(1).split("_").join(" ").toUpperCase()}</p>
                        </NavLink>
                    </div>
                    );
                })

            }
            </div>
            <div className="iconuserlogout">
                    <Link to="/" style={{display:'flex', flexDirection:'row', textDecoration: 'none', color: '#fff ', fontSize: '.8em' }} onClick={e => localStorage.clear()}>
                        <p>Esci</p>
                        <FontAwesomeIcon 
                            icon={faDoorOpen}
                            style={{alignSelf: 'center', marginLeft:'4px'}}
                        />
                    </Link>
            </div>
        </div>
    );

}

export default Sidebar;