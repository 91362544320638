import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import Modal from 'react-modal';

import Sidebar from '../comuni/sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faCheck, faTimes, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import './notifiche.css'

//Componente che gestisce le varie notifiche una volta inserite.

function Notifiche(){

    const location = useLocation();
    const { comune } = useParams();
    const accessToken = location.state.token;
    const username = location.state.username;
    
    //Chiamate API
    const nascondiReminder = "/api/v1/reminder/postReminder";
    const getReminder = "/api/v1/reminder/getReminder";

    //hooks
    const [reminder, setReminder] = useState([]);
    const [asktoRemove, setasktoRemove] = useState(false);
    const [reminderId, setReminderId] = useState(0);
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [openCardInfo, setOpenCardInfo] = useState(false); //Modale per mostrare le info delle specifiche card
    const [id, setId] = useState(0);
    const [priorità, setPriorità] = useState('');
    const [titolo, setTitolo] = useState('');
    const [testo, setTesto] = useState('');
    const [autore, setAutore] = useState('');

    //UseEffect per prendere le notifiche
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("x-access-token", accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
};

    fetch(getReminder, requestOptions)
            .then(response => response.json()) 
            .then(reminderData => {
                //console.log('reminderData', reminderData)

                setReminder(() => {
                    return reminderData.table;
                })
            })
            .catch(error => console.log('error', error));
    }, [accessToken, comune])

    const expandCard = (id, priorità, titolo, testo, autore) => {
        setId(id);
        setPriorità(priorità);
        setTitolo(titolo);
        setTesto(testo);
        setAutore(autore);
        setOpenCardInfo(true);
    }

    const closeModalopenCard = () => {
        setId(0);
        setPriorità('');
        setTitolo('');
        setTesto('');
        setAutore('');
        setOpenCardInfo(false);
    }

    const opencardModal = {
        content : {
            display: 'flex',
            flexDirection: 'column',
            top: '25%',
            left: '30%',
            height: '40vh',
            padding: '1%',
            backgroundColor: 'rgba(243, 243, 243, 1)',
            boxShadow: '0px 0px 6px 0px #33448f',
            width: '40%'
        },
        overlay : {
            /* zIndex: 1, */
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(4px)'
        }
    };

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        if(!e.target.id){
            return;
        } else {
            //console.log('quando premo sulla X', e.target.id)
            setReminderId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    //funzione per il fake delete
    const ReminderDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        //console.log('reminder da passare al backend', reminderId)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", username)
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("reminderId", reminderId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiReminder, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                //console.log('response', response)
                openModalCorrect(e);
                let newState = reminder.filter((item) => {
                    /* console.log('itemId reminderId', item.id, reminderId, item.id === reminderId) */
                    return item.id !== reminderId;
                })
                /* console.log('reminder newState', reminder, newState) */
                setReminder([...newState])
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        /* .then(result => console.log(result)) */
        .catch(error => console.log('error', error));
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 102,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        setasktoRemove(false);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
        setasktoRemove(false);
    }

    return (
        <div className="wrapperNotifiche">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="notificheWrapper">
                <h2>Notifiche</h2>
                <div className="cardNotificheContainer">
                    {
                        Object.values(reminder).map((val, i) => {
                            return (
                                <div key={i} id={val.id} className="remindersContainer">
                                    <div className="coloredDiv"
                                        style={{background: val.priority === 'Bassa' ? '#008000' : (val.priority === 'Media' ? '#FFD700' : '#FF0000')}}>
                                    </div>

                                    <div className="infoContainer">
                                        <h5>{val.reminderTitle}</h5>
                                        <p>{val.reminder.length > 20 ? val.reminder.slice(0, 20) + '...' : val.reminder}</p>
                                    </div>

                                    <div className="deleteIconContainer">
                                        <FontAwesomeIcon 
                                            icon={faTrashAlt} 
                                            id={val.id} 
                                            onClick={openasktoRemoveModal} 
                                            size="1x"
                                            style={{cursor: 'pointer', alignSelf: 'center', justifyContent: 'center'}}
                                        />

                                        <FontAwesomeIcon 
                                            icon={faInfoCircle}
                                            style={{cursor: 'pointer', alignSelf: 'center', justifyContent: 'center'}}
                                            onClick={(e) => {

                                            e.preventDefault();

                                            expandCard(val.id ,val.priority, val.reminderTitle, val.reminder, val.writtenBy)
                                            }}
                                        />
                                        <p className="writer">{val.writtenBy.length > 10 ? val.writtenBy.slice(0, 10) + '...' : val.writtenBy}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Modal
                isOpen={openCardInfo}
                ariaHideApp={false}
                style={opencardModal}>
                    <button onClick={closeModalopenCard} className="closingOpenedCardModal">X</button>
                    <div className="openedCard" id={id}>
                        <div className="openedColoredDiv"
                            style={{background: priorità === 'Bassa' ? '#008000' : (priorità === 'Media' ? '#FFD700' : '#FF0000')}}>
                        </div>
                        <div className="openedInfoContainer">
                            <h5>{titolo}</h5>
                            <p>{testo}</p>
                        </div>
                        <div className="openedDeleteIconContainer">
                            <FontAwesomeIcon 
                                icon={faTrashAlt} 
                                id={id} 
                                onClick={openasktoRemoveModal} 
                                size="lg"
                                style={{cursor: 'pointer', alignSelf: 'flex-end'}}/>
                                <p className="openedWriter">{autore}</p>
                        </div>
                    </div>
            </Modal>

            {/* Modale per chiedere di cancellare */}
            <Modal
                isOpen={asktoRemove}
                ariaHideApp={false}
                style={customStyles}>
                <p>Attenzione! Stai per rimuovere questa notifica</p>
                <p>Sei sicuro?</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                    <FontAwesomeIcon 
                        icon={faCheck} 
                        id={id} 
                        onClick={ReminderDeletion} 
                        size="2x"
                        style={{cursor: 'pointer', color: '#33448f'}}/>
                    <FontAwesomeIcon 
                        icon={faTimes} 
                        onClick={closeasktoRemoveModal} 
                        size="2x"
                        style={{cursor: 'pointer', color: '#33448f'}}/>
                </div>
            </Modal>

            <Modal
                isOpen={modalCorrectisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                <p className="modalText">Notifica eliminata correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p className="modalText">Non è stato possibile eliminare la notifica</p>
            </Modal>
        </div>
    );
};

export default Notifiche;