import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Sidebar from '../comuni/sidebar'
import Tabella from '../comuni/tabella';
import './ritirisegnalazioni.css'

export default function RitiriSegnalazioni(){

    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;

    return (
        <div className="wrapperRitiriSegnalazioni">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()} />
            <div className="ritiriSegnalazioniWrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                <Tabella comune={comune}/>
            </div>
        </div>
    );
};