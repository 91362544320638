import React, { useState } from 'react';
import './App.css';
import {BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './components/login/login.js';
import Home from './components/homepage/homepage';
import Comune from './components/comuni/comune';
import NuovaPratica from './components/pagine/nuovapratica';
import ElencoUtenze from './components/pagine/elencoutenze';
import Calendario from './components/pagine/calendario';
import InsReminder from './components/pagine/inserimentoreminder';
import Eternit from './components/pagine/eternit';
import GuidaServizio from './components/pagine/guidaservizio';
import RitiroPotature from './components/pagine/ritiropotature';
import Infos from './components/pagine/informazionioperai';
import { ConfigProvider } from './components/context/config';
import { TokenProvider } from './components/context/token/token';
import Esporta from './components/pagine/esporta';
import Notifiche from './components/pagine/notifiche';
import ModificaRichieste from './components/pagine/modifica_richieste';
import ModificaUtenza from './components/pagine/modifica_utenze';
import RitiriSegnalazioni from './components/pagine/ritirisegnalazioni';
import Telefonate from './components/homepage/telefonate';
import DashboardChiamate from './components/homepage/dashboardChiamate';
import ModificaPotature from './components/pagine/modifica_potature';


function App(){

  const [telefonateTot, setTelefonateTot] = useState(0);
  const [telefonateGiorn, setTelefonateGiorn] = useState(0);
  const [totale, setTotale] = useState(0);


  return (
    <Router>
          <Switch>
            <TokenProvider>
            <ConfigProvider>
              <Route exact path ="/dashboardchiamate" render={props => <DashboardChiamate {...props} />} /> 
              <Route exact path ="/" render={props => <Login {...props}/>}  />
              <Route exact path ="/telefonate" render={props => <Telefonate {...props} totale={totale} setTotale={setTotale} />} />
              <Route exact path="/home" render={props => <Home {...props}/>}  />
              <Route exact path="/servizio/:comune/dashboard" render={props => <Comune {...props} 
                telefonateTot={telefonateTot} setTelefonateTot={setTelefonateTot}
                telefonateGiorn={telefonateGiorn} setTelefonateGiorn={setTelefonateGiorn} />} />
              <Route exact path="/servizio/:comune/notifiche" render={props => <Notifiche {...props}/>} />
              <Route exact path="/servizio/:comune/inserimento" render={props => <NuovaPratica {...props}/>} />
              <Route exact path="/servizio/:comune/ritiri_e_segnalazioni/modifica_richiesta" render={props => <ModificaRichieste {...props}/>}/>
              <Route exact path="/servizio/:comune/ritiri_e_segnalazioni" render={props => <RitiriSegnalazioni {...props}/>}/>
              <Route exact path="/servizio/:comune/elenco_utenze/modifica_utenza" render={props => <ModificaUtenza {...props}/>}/>
              <Route exact path="/servizio/:comune/elenco_utenze" render={props => <ElencoUtenze {...props}/>} />
              <Route exact path="/servizio/:comune/calendario" render={props => <Calendario {...props}/>} />
              <Route exact path="/servizio/:comune/nuova_notifica" render={props => <InsReminder {...props}/>} />
              <Route exact path="/servizio/:comune/eternit" render={props => <Eternit {...props}/>} />
              <Route exact path="/servizio/:comune/esporta" render={props => <Esporta {...props}/>} />
              <Route exact path="/servizio/:comune/guida_servizio" render={props => <GuidaServizio {...props}/>} />
              <Route exact path="/servizio/:comune/informazioni_operai" render={props => <Infos {...props}/>} />
              <Route exact path="/servizio/:comune/ritiro_potature" render={props => <RitiroPotature {...props}/>} />
              <Route exact path="/servizio/:comune/ritiro_potature/modifica_potature" render={props => <ModificaPotature {...props}/>}/>
              </ConfigProvider>
              </TokenProvider>
          </Switch>
    </Router>
  );
}

export default App;