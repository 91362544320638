import React from 'react';
import './buttonaggiungi.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';




function ButtonAggiungi({title1, add}){
    
    return(
        <button className="addbutton" onClick={add}>
            <FontAwesomeIcon 
                icon={faPlusCircle}
                className="buttonIcon"
            />
            {title1}
        </button>
    );
}

export default ButtonAggiungi;