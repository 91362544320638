import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Sidebar from '../comuni/sidebar';
import './guidaservizio.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes, faCheck, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faTable } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';

//Componente che gestisce l'inserimento e la visualizzazione delle pagine 

function GuidaServizio(){

    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamate API
    const inserimentoGuidaApi = '/api/v1/guidaservizio/postguidaservizio';
    const mostraGuidaApi = '/api/v1/guidaservizio/getguidaservizio';
    const nascondiGuidaApi = '/api/v1/guidaservizio/postguidaservizio';
    let imgUrl = 'https://scr.avrgroup.it/public';

    //hooks
    const [showForm, setshowForm] = useState('none'); //hook per mostrare o nascondere il form
    const [showtable, setshowTable] = useState('flex') //hook per mostrare o nascondere la tabella
    const [guida, setGuida] = useState(/* localStorage.getItem('guida') */[]);
    const [modalGuidaRemoved, setModalGuidaRemoved] = useState(false);
    const [modalGuidaRemovedError, setModalGuidaRemovedError] = useState(false);
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [asktoRemove, setasktoRemove] = useState(false);
    const [guidaId, setGuidaId] = useState(0);
    const [GuidaServizioOpacity, setGuidaServizioOpacity] = useState(1);
    const [divBackground, setDivBackground] = useState('rgba(51, 51, 51, 0');
    const [showDelete, setShowDelete] = useState('none');

    const openModalGuidaRemoved = () => {
        setModalGuidaRemoved(true);
    }

    const closeModalGuidaRemoved = () => {
        setModalGuidaRemoved(false);
        window.location.reload(false);
    }

    const openModalGuidaRemovedError = () => {
        setModalGuidaRemovedError(true);
    }

    const closeModalGuidaRemovedError = () => {
        setModalGuidaRemovedError(false);
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        setasktoRemove(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
        setasktoRemove(false);
    }

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        if(!e.target.id){
            return;
        } else {
            console.log('quando premo sulla X', e.target.id);
            setGuidaId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const toggleForm = () => {
        
        if(showForm === 'none'){
            setshowForm('flex');
            setshowTable('none');
        } else {
            setshowForm('none');
            setshowTable('flex');
        }

    }

    //Funzione che permette l'inserimento delle immagini nel db
    const imgSubmit = (e) => {
        e.preventDefault();
        //faccio una post al db
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", token.username);

        var formdata = new FormData();
            for(let i = 0; i < guida.length; i++){
            formdata.append("images", guida[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(inserimentoGuidaApi, requestOptions)
            .then(response => {
                response.json()

                if(response.status === 200){
                    openModalCorrect(e);
                }
                if(response.status === 400){
                    openModalError(e);
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }

    //funzione che setta i file inseriti nello stato della guida
    const selectedFiles = (e) => {
        setGuida(e.target.files);
    }

    //useEffect che permette la ricezione delle immagini dal db per poi renderizzarle a video
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("username", token.username);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(mostraGuidaApi, requestOptions)
            .then(response => response.json())
            .then(guidaData => {
                console.log(guidaData)

                const dati = Object.values(guidaData.image)/* .filter(item => item.deleted === false) */.map(val => {
                    return val;
                })
                setGuida(() => {
                    return [...dati]
                })

            })
            .catch(error => console.log('error', error));
    }, [token.accessToken, token.username, comune]);

    //funzione per il fake delete
    const GuidaDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("guidaservizioId", guidaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiGuidaApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalGuidaRemoved(e);
            }
            if(response.status >= 400){
                openModalGuidaRemovedError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const showTransition = () => {
        setGuidaServizioOpacity(0.3);
        setDivBackground('rgba(51, 51, 51, 1)')
        setShowDelete('block');
    }

    const hideTransition = () => {
        setGuidaServizioOpacity(1);
        setDivBackground('rgba(51, 51, 51, 0)')
        setShowDelete('none');
    }


    return(
        <div className="wrapperguidaservizio">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="guidaserviziowrapper">
            <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
            <div className="toggleform">
                <FontAwesomeIcon className="plusicon" icon={faPlusCircle} onClick={toggleForm} size="2x" style={{display: showtable, color: '#33448f'}}/>
                <FontAwesomeIcon className="tableicon" icon={faTable} onClick={toggleForm} size="2x" style={{display: showForm, color: '#33448f'}}/>
            </div>
            <form
            style={{display: showForm}}
            className="inserimentoGuida"
            onSubmit={imgSubmit}>
                <h4 htmlFor="file">Inserisci Img (max 2 file)</h4>
                <input type="file" name="file" aria-label="File browser" multiple onChange={selectedFiles}></input>
                <p>Il file deve essere un PNG, JPG o JPEG, non deve contenere spazi ed essere superiore di 5 Mb</p>
                <button type="submit">Upload</button>
            </form>
            <div className="GuideServizioContainer" style={{display: showtable}}>
                {
                    Object.values(guida).map((val, i) => {
                        return <div 
                                className="imgGuidaServizioLoaded"
                                key={i}
                                id={val.id}
                                style={{background: divBackground}}>
                            <img className="imgGuidaServizio" 
                                src={imgUrl + val.url} 
                                id={val.id} 
                                alt="guida_servizio"
                                style={{opacity: GuidaServizioOpacity}}
                                onMouseEnter={showTransition}
                                onMouseLeave={hideTransition}
                            />

                            <FontAwesomeIcon 
                                className="GuidaServizioDelete"
                                icon={faTrashAlt} 
                                id={val.id}
                                onClick={openasktoRemoveModal} 
                                size="1x"
                                style={{cursor: 'pointer', display: showDelete}}
                                onMouseEnter={showTransition}
                            />

                            <Modal
                                isOpen={asktoRemove}
                                ariaHideApp={false}
                                style={customStyles}>
                                <p>Attenzione! Stai per rimuovere questa guida</p>
                                <p>Sei sicuro?</p>
                                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                    <FontAwesomeIcon 
                                    icon={faCheck} 
                                    id={val.id}
                                    onClick={GuidaDeletion} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                    <FontAwesomeIcon 
                                    icon={faTimes} 
                                    onClick={closeasktoRemoveModal} 
                                    size="2x"
                                    style={{cursor: 'pointer', color: '#33448f'}}/>
                                </div>
                            </Modal>
                        </div>
                    })
                }
            </div>

            <Modal
                isOpen={modalCorrectisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>File aggiunti correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Nessun file aggiunto</p>
            </Modal>

            <Modal
                isOpen={modalGuidaRemoved}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalGuidaRemoved} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Guida rimossa con successo</p>
            </Modal>

            <Modal
                isOpen={modalGuidaRemovedError}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalGuidaRemovedError}>x</button>
                <p>Non è stato possibile eliminare la guida</p>
            </Modal>
            </div>
        </div>
    );
}

export default GuidaServizio;