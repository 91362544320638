import React, { useEffect, useState } from 'react';
import Sidebar from '../comuni/sidebar';
import Reminder from '../comuni/reminder';
import { useLocation } from 'react-router';
import './comune.css'
import MyChart from '../comuni/charts/chart';
import Notifies from './notifies';
import PhoneCount from './phoneCount';

//Componente che renderizza la dashboard del comune composta da 5 componenti principali: Sidebar, Reminder, Notifies, PhoneCount e i Chart.

function Comune( props ){

    //variabili d'utility
    const location = useLocation();
    const comune = location.state ? location.state.comune : 'capurso';
    const token = JSON.parse(localStorage.getItem('token'));

    //props
    const telefonateTot = props.telefonateTot;
    const setTelefonateTot = props.setTelefonateTot;
    const telefonateGiorn = props.telefonateGiorn;
    const setTelefonateGiorn = props.setTelefonateGiorn;

    //hooks
    const [reminder, setReminder] = useState(); //hook per il count dei reminder 
    const [notifiche, setNotifiche] = useState([]);

    //chiamate API
    const getReminder = "/api/v1/reminder/getReminder";

    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(getReminder, requestOptions)
            .then(response => response.json()) 
            .then(reminderData => {
                /* console.log('reminderData', reminderData) */

                setNotifiche(() => {
                    if(reminderData.length <= 5){
                    return reminderData ? reminderData.table : null;
                    } else {
                        return reminderData ? reminderData.table.slice(0, 5) : null;
                    }
                })
            })
            .catch(error => console.log('error', error));
    }, [])

    return(
        <div className="screenwrapper">
            <Sidebar title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="comunewrapper">
                <Reminder setReminder={setReminder}/>
                <div className="midComuneWrapper">
                    <Notifies comune={comune} reminder={reminder} notifiche={notifiche}/>
                    <PhoneCount 
                        comune={comune}
                        telefonateTot={telefonateTot}
                        setTelefonateTot={setTelefonateTot}
                        telefonateGiorn={telefonateGiorn}
                        setTelefonateGiorn={setTelefonateGiorn}
                        />
                </div>
                <MyChart />
            </div>
        </div>
    );
}

export default Comune;