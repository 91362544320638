import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useParams } from 'react-router-dom';
import ButtonAggiungi from '../buttons/buttonaggiungi';
import ButtonEsci from '../buttons/buttonesci';
import './inserimentoreminder.css';
import Sidebar from '../comuni/sidebar';
import Modal from 'react-modal';

//Componente che gestisce l'inserimento delle notifiche da parte degli operatori inerenti il comune. Dovranno scegliere un livello di priorità che verrà visualizzato
//sottoforma di colore (verde = basso, giallo = medio, rosso = alto), in più un titolo, il corpo della notifica e l'autore della notifica verrà preso in automatico.

function InsReminder(){

    const history = useHistory();
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamata API
    const inserimentoReminderApi = "/api/v1/reminder/postreminder";
    
    //Hooks
    const [testo, setTesto] = useState('');
    const [modalErrorisOpen, setmodalErrorisOpen] = useState(false);
    const [modalCorrectisOpen, setmodalCorrectisOpen] = useState(false);
    const [chooseColor, setChooseColor] = useState('');
    const [reminderTitle, setReminderTitle] = useState('');

    const openModalCorrect = () => {
        setmodalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setmodalCorrectisOpen(false);
        setChooseColor('');
        setReminderTitle('');
        setTesto('')
    }

    const openModalError = () => {
        setmodalErrorisOpen(true);
    }

    const closeModalError = () => {
        setmodalErrorisOpen(false);
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    //Funzione che aggiunge la notifica
    const Aggiungi = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
            myHeaders.append('username', token.username);
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));

        var urlencoded = new URLSearchParams();
            urlencoded.append("reminder", testo);
            urlencoded.append("titolo", reminderTitle)
            urlencoded.append("writer", token.username);
            urlencoded.append("priorità", chooseColor);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
};

    fetch(inserimentoReminderApi, requestOptions)
        .then(response => {
            response.json()

            if(response.status === 200){
                openModalCorrect(e);
            }

            if(response.status === 400){
                openModalError(e);
            }
        })
        /* .then(result => console.log(result)) */
        .catch(error => console.log('error', error));
    } 

    const Exit = (e) => {
        e.preventDefault();
        history.goBack();
    }

    return(
        <div className="wrapperInserimentoReminder">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="inserimentoreminderwrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                <form className="inserimentoReminder">
                    <h4>Inserisci la notifica</h4>
                    <div className="inserimentoReminderInput">
                        <label htmlFor="selpriority">Sel.livello priorità</label>
                        <select className="selPriority" name="selpriority" value={chooseColor} onChange={e => setChooseColor(e.target.value)}>
                            <option value=""> </option>
                            <option value="Bassa">Bassa</option>
                            <option value="Media">Media</option>
                            <option value="Alta">Alta</option>
                        </select>
                        <label htmlFor="reminderTitle">Titolo</label>
                        <input type="text" value={reminderTitle} name="reminderTitolo" placeholder="Titolo notifica..." onChange={e => setReminderTitle(e.target.value)} />
                        <label htmlFor="reminder1">Testo</label>
                        <textarea name="reminder1" maxLength="250" placeholder="Testo notifica..." value={testo} onChange={e => setTesto(e.target.value)}></textarea>
                    </div>
                    <div className="reminderButtons">
                        <ButtonAggiungi add={Aggiungi} title1="Aggiungi"/>
                        <ButtonEsci abort={Exit} title3 ="Esci"/>
                    </div>
                </form>
            </div>

            <Modal
                isOpen={modalCorrectisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Notifica aggiunta correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Ops! Qualcosa è andato storto</p>
            </Modal>
        </div>
    );
}

export default InsReminder;