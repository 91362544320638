import React, { useContext, useEffect, useState } from 'react'

import { ConfigContext } from '../../context/config';

export default function TabellaIgiene( props ){

    //variabili d'utility
    const file = useContext(ConfigContext);
    const comuni = Object.values(file.config.ENUMcomuni.telefonate)
    const token = JSON.parse(localStorage.getItem('token'));

    //chiamate Api
    const igieneTelTot = '/api/v1/telefonate/igienetot';
    const igieneTelGiornal = '/api/v1/telefonate/igienegiornal';
    const igieneTelSett = '/api/v1/telefonate/igienesett';

    //Hooks
    const [telefonateTotCapurso, setTelefonateTotCapurso] = useState(0);
    const [telefonateGiornCapurso, setTelefonateGiornCapurso] = useState(0);
    const [telefonateSettCapurso, setTelefonateSettCapurso] = useState(0);
    const [telefonateTotFara, setTelefonateTotFara] = useState(0);
    const [telefonateGiornFara, setTelefonateGiornFara] = useState(0);
    const [telefonateSettFara, setTelefonateSettFara] = useState(0);
    const [telefonateTotMonteflavio, setTelefonateTotMonteflavio] = useState(0);
    const [telefonateGiornMonteflavio, setTelefonateGiornMonteflavio] = useState(0);
    const [telefonateSettMonteflavio, setTelefonateSettMonteflavio] = useState(0);
    const [telefonateTotMontelibretti, setTelefonateTotMontelibretti] = useState(0);
    const [telefonateGiornMontelibretti, setTelefonateGiornMontelibretti] = useState(0);
    const [telefonateSettMontelibretti, setTelefonateSettMontelibretti] = useState(0);
    const [telefonateTotMorlupo, setTelefonateTotMorlupo] = useState(0);
    const [telefonateGiornMorlupo, setTelefonateGiornMorlupo] = useState(0);
    const [telefonateSettMorlupo, setTelefonateSettMorlupo] = useState(0);
    const [telefonateTotSantoreste, setTelefonateTotSantoreste] = useState(0);
    const [telefonateGiornSantoreste, setTelefonateGiornSantoreste] = useState(0);
    const [telefonateSettSantoreste, setTelefonateSettSantoreste] = useState(0);
    const [telefonateTotSava, setTelefonateTotSava] = useState(0);
    const [telefonateGiornSava, setTelefonateGiornSava] = useState(0);
    const [telefonateSettSava, setTelefonateSettSava] = useState(0);
    const [telefonateTotTaurianova, setTelefonateTotTaurianova] = useState(0);
    const [telefonateGiornTaurianova, setTelefonateGiornTaurianova] = useState(0);
    const [telefonateSettTaurianova, setTelefonateSettTaurianova] = useState(0);
    const [telefonateTotVillacidro, setTelefonateTotVillacidro] = useState(0);
    const [telefonateGiornVillacidro, setTelefonateGiornVillacidro] = useState(0);
    const [telefonateSettVillacidro, setTelefonateSettVillacidro] = useState(0);
    const [telefonateTotGrottaglie, setTelefonateTotGrottaglie] = useState(0);
    const [telefonateGiornGrottaglie, setTelefonateGiornGrottaglie] = useState(0);
    const [telefonateSettGrottaglie, setTelefonateSettGrottaglie] = useState(0);
    const [telefonateTotAltri, setTelefonateTotAltri] = useState(0);
    const [telefonateGiornAltri, setTelefonateGiornAltri] = useState(0);
    const [telefonateSettAltri, setTelefonateSettAltri] = useState(0);

    const [broadcastedData, setBroadcastedData] = useState();

    //props
    const isLoading = props.isLoading;
    const setIsLoading = props.setIsLoading;
    const showTitles = props.showTitles;
    const setShowTitles = props.setShowTitles;
    const totale = props.totale;
    const setTotale = props.setTotale;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '/' + mm + '/' + dd;

    const bc = new BroadcastChannel('myApp');

    //useEffect per prendere i cambiamenti dalla dashboard e settarli in uno stato
    useEffect(() => {
        bc.addEventListener('message', e => {
            setBroadcastedData(e.data)
        })
    },[])

    //useEffect per ascoltare i cambiamenti dalla dashboard e applicarli alla tabella
    useEffect(() => {
        if(!broadcastedData){
            return
        } else {
            if(broadcastedData[2] === 'aggiunta'){ //quando clicco il + nella dashboard
                switch(broadcastedData[1]){
                    case "capurso":
                        setTelefonateGiornCapurso(prev => prev + 1)
                        setTelefonateSettCapurso(prev => prev + 1)
                        setTelefonateTotCapurso(prev => prev + 1)
                    break;
                    case "fara_in_sabina":
                        setTelefonateGiornFara(prev => prev + 1)
                        setTelefonateTotFara(prev => prev + 1)
                        setTelefonateSettFara(prev => prev + 1)
                    break;
                    case "monteflavio":
                        setTelefonateGiornMonteflavio(prev => prev + 1)
                        setTelefonateTotMonteflavio(prev => prev + 1)
                        setTelefonateSettMonteflavio(prev => prev + 1)
                    break;
                    case "montelibretti":
                        setTelefonateGiornMontelibretti(prev => prev + 1)
                        setTelefonateTotMontelibretti(prev => prev + 1)
                        setTelefonateSettMontelibretti(prev => prev + 1)
                    break;
                    case "morlupo":
                        setTelefonateGiornMorlupo(prev => prev + 1)
                        setTelefonateTotMorlupo(prev => prev + 1)
                        setTelefonateSettMorlupo(prev => prev + 1)
                    break;
                    case "santoreste":
                        setTelefonateGiornSantoreste(prev => prev + 1)
                        setTelefonateTotSantoreste(prev => prev + 1)
                        setTelefonateSettSantoreste(prev => prev + 1)
                    break;
                    case "sava":
                        setTelefonateGiornSava(prev => prev + 1)
                        setTelefonateTotSava(prev => prev + 1)
                        setTelefonateSettSava(prev => prev + 1)
                    break;
                    case "taurianova":
                        setTelefonateTotTaurianova(prev => prev + 1)
                        setTelefonateGiornTaurianova(prev => prev + 1)
                        setTelefonateSettTaurianova(prev => prev + 1)
                    break;
                    case "villacidro":
                        setTelefonateGiornVillacidro(prev => prev + 1)
                        setTelefonateTotVillacidro(prev => prev + 1)
                        setTelefonateSettVillacidro(prev => prev + 1)
                    break;
                }
            } else { //quando clicco il - nella dashboard
                switch(broadcastedData[1]){
                    case "capurso":
                        setTelefonateGiornCapurso(prev => prev - 1)
                        setTelefonateSettCapurso(prev => prev - 1)
                        setTelefonateTotCapurso(prev => prev - 1)
                    break;
                    case "fara_in_sabina":
                        setTelefonateGiornFara(prev => prev - 1)
                        setTelefonateTotFara(prev => prev - 1)
                        setTelefonateSettFara(prev => prev - 1)
                    break;
                    case "monteflavio":
                        setTelefonateGiornMonteflavio(prev => prev - 1)
                        setTelefonateTotMonteflavio(prev => prev - 1)
                        setTelefonateSettMonteflavio(prev => prev - 1)
                    break;
                    case "montelibretti":
                        setTelefonateGiornMontelibretti(prev => prev - 1)
                        setTelefonateTotMontelibretti(prev => prev - 1)
                        setTelefonateSettMontelibretti(prev => prev - 1)
                    break;
                    case "morlupo":
                        setTelefonateGiornMorlupo(prev => prev - 1)
                        setTelefonateTotMorlupo(prev => prev - 1)
                        setTelefonateSettMorlupo(prev => prev - 1)
                    break;
                    case "santoreste":
                        setTelefonateGiornSantoreste(prev => prev - 1)
                        setTelefonateTotSantoreste(prev => prev - 1)
                        setTelefonateSettSantoreste(prev => prev - 1)
                    break;
                    case "sava":
                        setTelefonateGiornSava(prev => prev - 1)
                        setTelefonateTotSava(prev => prev - 1)
                        setTelefonateSettSava(prev => prev - 1)
                    break;
                    case "taurianova":
                        setTelefonateTotTaurianova(prev => prev - 1)
                        setTelefonateGiornTaurianova(prev => prev - 1)
                        setTelefonateSettTaurianova(prev => prev - 1)
                    break;
                    case "villacidro":
                        setTelefonateGiornVillacidro(prev => prev - 1)
                        setTelefonateTotVillacidro(prev => prev - 1)
                        setTelefonateSettVillacidro(prev => prev - 1)
                    break;
                }
            }
        }
    }, [broadcastedData ? broadcastedData[0] : null])

    //useEffect per prendere i dati alla renderizzazione della pagina
    useEffect(() => {
        
        for(let i = 0; i < comuni.length; i++){
            
            var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comuni[i])
            myHeaders.append("data", today);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            }
        
            fetch(igieneTelTot, requestOptions) //fetch per i dati totali
            .then(response => response.json())
            .then(result => {
                switch(result.luogo){
                    case "Capurso": 
                        setTelefonateTotCapurso(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Fara_in_sabina":
                        setTelefonateTotFara(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Monteflavio":
                        setTelefonateTotMonteflavio(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Montelibretti":
                        setTelefonateTotMontelibretti(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Morlupo":
                        setTelefonateTotMorlupo(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Santoreste":
                        setTelefonateTotSantoreste(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Sava":
                        setTelefonateTotSava(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Villacidro":
                        setTelefonateTotVillacidro(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Grottaglie":
                        setTelefonateTotGrottaglie(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Altri":
                        setTelefonateTotAltri(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                }
            })
            fetch(igieneTelGiornal, requestOptions) //fetch per i dati giornalieri
                .then(response => response.json())
                .then(result => {
                switch(result.luogo){
                    case "Capurso": 
                        setTelefonateGiornCapurso(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Fara_in_sabina":
                        setTelefonateGiornFara(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Monteflavio":
                        setTelefonateGiornMonteflavio(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Montelibretti":
                        setTelefonateGiornMontelibretti(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Morlupo":
                        setTelefonateGiornMorlupo(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Santoreste":
                        setTelefonateGiornSantoreste(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Sava":
                        setTelefonateGiornSava(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Villacidro":
                        setTelefonateGiornVillacidro(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Grottaglie":
                        setTelefonateGiornGrottaglie(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                    case "Altri":
                        setTelefonateGiornAltri(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                    break;
                }
            })
            fetch(igieneTelSett, requestOptions) //fetch per i dati settimanali
            .then(response => response.json())
            .then(result => {
                
                switch(result.luogo){
                    case "Capurso": 
                        setTelefonateSettCapurso(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Fara_in_sabina":
                        setTelefonateSettFara(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Monteflavio":
                        setTelefonateSettMonteflavio(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Montelibretti":
                        setTelefonateSettMontelibretti(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Morlupo":
                        setTelefonateSettMorlupo(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Santoreste":
                        setTelefonateSettSantoreste(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Sava":
                        setTelefonateSettSava(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Villacidro":
                        setTelefonateSettVillacidro(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Grottaglie":
                        setTelefonateSettGrottaglie(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                    break;
                    case "Altri":
                        setTelefonateSettAltri(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                        setIsLoading(false)
                        setShowTitles('flex')
                    break;
                }
            })
            .catch(error => console.log(error))
        }
        
        /* console.log('oggetto datiIgiene', datiIgiene); */
    }, [])

    //useEffect per modificare dinamicamente totale
    useEffect(() => {
        setTotale(telefonateTotCapurso + telefonateTotFara + telefonateTotMonteflavio + telefonateTotMontelibretti + telefonateTotMorlupo +
            telefonateTotSantoreste + telefonateTotSava + telefonateTotTaurianova + telefonateTotVillacidro + telefonateTotGrottaglie + telefonateTotAltri)
    }, [telefonateTotCapurso, telefonateTotFara, telefonateTotMonteflavio, telefonateTotMontelibretti, telefonateTotMorlupo,
    telefonateTotSantoreste, telefonateTotSava, telefonateTotTaurianova, telefonateTotVillacidro, telefonateTotGrottaglie, telefonateTotAltri])

    //Funzione per fare il +1 della chiamata
    const addCallIgiene = (e, item) => {
        e.preventDefault();

        let località = item;

        bc.postMessage(località);

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", località);
        myHeaders.append("data", today);
        myHeaders.append("tipologia", "aggiunta");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(igieneTelTot, requestOptions)
        .then(response => response.json())
        .then(result => {

            switch(result.luogo){
                case "Capurso": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Capurso");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        setTelefonateTotCapurso(Number(getResult.dati[0].totale))
                    })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateGiornCapurso(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('risultati settimanali capurso', getResult)
                        setTelefonateSettCapurso(Number(getResult.dati[0].totale))
                    })
                break;

                case "Fara_in_sabina": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Fara_in_sabina");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotFara(Number(getResult.dati[0].totale))
                    })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornFara(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettFara(Number(getResult.dati[0].totale))
                    })
                break;

                case "Monteflavio": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Monteflavio");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotMonteflavio(Number(getResult.dati[0].totale))
                    })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornMonteflavio(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettMonteflavio(Number(getResult.dati[0].totale))
                    })
                break;

                case "Montelibretti": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Montelibretti");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotMontelibretti(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornMontelibretti(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettMontelibretti(Number(getResult.dati[0].totale))
                    })
                break;
                
                case "Morlupo": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Morlupo");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotMorlupo(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornMorlupo(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettMorlupo(Number(getResult.dati[0].totale))
                    })
                break;

                case "Santoreste": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Santoreste");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotSantoreste(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornSantoreste(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettSantoreste(Number(getResult.dati[0].totale))
                    })
                break;

                case "Sava": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Sava");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotSava(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornSava(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettSava(Number(getResult.dati[0].totale))
                    })
                break;

                case "Taurianova": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Taurianova");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotTaurianova(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornTaurianova(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettTaurianova(Number(getResult.dati[0].totale))
                    })
                break;

                case "Villacidro": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Villacidro");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotVillacidro(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornVillacidro(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettVillacidro(Number(getResult.dati[0].totale))
                    })
                break;

                case "Grottaglie": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Grottaglie");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotGrottaglie(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornGrottaglie(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettGrottaglie(Number(getResult.dati[0].totale))
                    })
                break;

                case "Altri": 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("luogo", "Altri");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(igieneTelTot, getOptions)
                    .then(response => response.json())
                    .then(getResult => { 
                        console.log('result get', getResult) 
                        setTelefonateTotAltri(Number(getResult.dati[0].totale))
                })
                fetch(igieneTelGiornal, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        console.log('result get giorn', getResult)
                        setTelefonateGiornAltri(Number(getResult.dati[0].giornal))
                    })
                fetch(igieneTelSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setTelefonateSettAltri(Number(getResult.dati[0].totale))
                    })
                break;
            }

        })
        .then(() => {
            setTotale(Number(telefonateTotCapurso + telefonateTotFara + telefonateTotMonteflavio + telefonateTotMontelibretti + telefonateTotMorlupo +
                telefonateTotSantoreste + telefonateTotSava + telefonateTotVillacidro + telefonateTotGrottaglie + telefonateTotAltri))
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div className="tabellaIgiene">
            <div className="tableTitle">
                <h4>Igiene</h4>
                <div className="tableTotalCounter">
                    <h5>Totale:</h5>
                    <span style={{display: showTitles}}>{totale}</span>
                </div>
            </div>
                {
                    isLoading ? 
                        <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        :
                        <div className="datiContainerIgiene">
                            {
                                comuni.map((item, i) => {
                                    switch(item){
                                    
                                    case "Capurso":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornCapurso}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettCapurso}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotCapurso}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Fara_in_sabina":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornFara}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettFara}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotFara}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Monteflavio":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornMonteflavio}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettMonteflavio}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotMonteflavio}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Montelibretti":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornMontelibretti}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettMontelibretti}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotMontelibretti}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Morlupo":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornMorlupo}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettMorlupo}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotMorlupo}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Santoreste":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornSantoreste}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettSantoreste}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotSantoreste}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Sava":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornSava}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettSava}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotSava}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Taurianova":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornTaurianova}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettTaurianova}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotTaurianova}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Villacidro":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornVillacidro}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettVillacidro}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotVillacidro}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Grottaglie":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornGrottaglie}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettGrottaglie}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotGrottaglie}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );

                                    case "Altri":
                                    return (
                                    <div className="elementsMappedContainer" key={i}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallIgiene(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{telefonateGiornAltri}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{telefonateSettAltri}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{telefonateTotAltri}</span>
                                            </div>
                                        </div>
                                    </div>
                                    );
                                    }
                                })
                            }
                        </div>
                    }
                </div>
    )
};