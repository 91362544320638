import React, { useState } from 'react';

import TabellaAltro from './tabelleTelefonate/tabellaAltro';
import TabellaIgiene from './tabelleTelefonate/tabellaIgiene';
import TabellaStrade from './tabelleTelefonate/tabellaStrade';
import './telefonate.css';

export default function Telefonate( props ){

    const [isLoading, setIsLoading] = useState(true)
    const [showTitles, setShowTitles] = useState('none')
    const totale = props.totale;
    const setTotale = props.setTotale;

    return (
        <div className="telefonateWrapper">
            <h2>Chiamate Ricevute</h2>
            <div className="tabelleContainer">

                <TabellaIgiene 
                    isLoading={isLoading} 
                    setIsLoading={setIsLoading} 
                    showTitles={showTitles} 
                    setShowTitles={setShowTitles}
                    totale={totale}
                    setTotale={setTotale}/>

                <TabellaStrade isLoading={isLoading} showTitles={showTitles} />
                
                <TabellaAltro isLoading={isLoading} showTitles={showTitles} />

            </div>
        </div>
    );
};