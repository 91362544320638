import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom'
import ButtonAggiungi from '../buttons/buttonaggiungi'
import { ConfigContext } from '../context/config';
import Sidebar from '../comuni/sidebar';
import './informazionioperai.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTable, faSearch, faTrashAlt, faCheck, faUserPlus, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import FiltraggioOperai from '../comuni/modals/modalricercaoperai';
import Modal from 'react-modal';
import Paginazione from '../comuni/paginazione';

//Componente complesso. Deve gestire l'inserimento dell'immagini di info del giro degli operai e cancellarla, inoltre deve gestire l'inserimento
//la modifica e la cancellazione dei vari operai.

function Infos(){

    const file = useContext(ConfigContext);
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state
    const tabellaOperai = file.config.comuni[comune].tabellaOperai;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamate API
    const inserimentoOperaiApi = '/api/v1/operai/postoperai';
    const mostraOperaiApi = '/api/v1/operai/getoperai';
    const inserimentoOperaiImgApi = '/api/v1/operai/postoperaiimg';
    const nascondiOperaiImgApi = '/api/v1/operai/postoperaiimg';
    const mostraOperaiImgApi = '/api/v1/operai/getoperaiimg';
    const nascondiOperaiApi = '/api/v1/operai/postoperai';
    const modificaOperaiApi = '/api/v1/operai/modificaoperai';
    let imgUrl = 'https://scr.avrgroup.it/public';

    //hooks
    const [imgOperai, setImgOperai] = useState([]);
    const [operaio, setOperaio] = useState('');
    const [numero, setNumero] = useState('');
    const [showOperai, setShowOperai] = useState([]);
    const [showicontable, setShowIconTable] = useState('none')
    const [showForm, setshowForm] = useState('none'); //hook per mostrare o nascondere il form
    const [showFormOperaio, setFormOperaio] = useState('none'); //hook per mostrare il form d'inserimento dell'operaio
    const [showtable, setshowTable] = useState('flex') //hook per mostrare o nascondere la tabella
    const [modalisOpen, setmodalisOpen] = useState(false); //hook per apertura del modale
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false); //hook per il modale corretto d'aggiunta delle img
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false); //hook per il modale errore d'aggiunta delle img
    const [modalOperaioCorrectisOpen, setModalOperaioCorrectisOpen] = useState(false); //hook per il modale corretto degli operai
    const [modalOperaioErrorisOpen, setModalOperaioErrorisOpen] = useState(false);  //hook per il modale sbagliato degli operai
    const [asktoRemove, setasktoRemove] = useState(false);
    const [imageId, setImageId] = useState(0); //stato per l'id delle img da cancellare
    const [modalRemoveImgCorrect, setModalRemoveImgCorrect] = useState(false); //hook per apertura modale alla cancellazione delle img giuste
    const [modalRemoveImgError, setModalRemoveImgError] = useState(false);  //hook per apertura modale alla cancellazione delle img con errore
    const [dataLimit] = useState(5);
    const [imgOpacity, setImgOpacity] = useState(1);
    const [divBackground, setDivBackground] = useState('rgba(51, 51, 51, 0');
    const [showDelete, setShowDelete] = useState('none');
    const [asktoRemoveImg, setasktoRemoveImg] = useState(false);
    const [operaioId, setOperaioId] = useState(0);
    const [modificaOperaio, setModificaOperaio] = useState('');
    const [modificaNumero, setModificaNumero] = useState('');

    //hooks per cambio elemento
    const [showTd, setShowTd] = useState('table-cell')
    const [showTd2, setShowTd2] = useState('table-cell')
    const [showInput1, setShowInput1] = useState('none');
    const [showInput2, setShowInput2] = useState('none');

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        console.log(e.target)
        if(!e.target.id){
            return;
        } else {
            console.log('quando premo sulla X', e.target.id)
            setOperaioId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const customStylesOperaiModal = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    //funzione per il fake delete
    const OperaioDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("operaioId", operaioId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(nascondiOperaiApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrect(e);
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const ShowHideInputOperaio = (e) => {
        e.preventDefault();

        setOperaioId(e.target.id)
        
        if(showTd === 'table-cell'){
            setShowTd('none');
            setShowInput1('table-cell')
        }
    }

    //Funzione per modificare il nome dell'operaio
    const ModificaOperaio = (e) => {

        e.preventDefault();

        console.log('record da passare al backend', operaioId)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("username", token.username);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("operaioId", operaioId);
            urlencoded.append("operaio", modificaOperaio);
            urlencoded.append("telefono", "");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(modificaOperaiApi, requestOptions)
            .then(response => {
                response.json()

                if(response.status === 200){
                    openModalCorrect(e)
                } else {
                    openModalError(e)
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }


    const ShowHideInputNumero = (e) => {
        e.preventDefault();

        setOperaioId(e.target.id);

        if(showTd2 === 'table-cell'){
            setShowTd2('none');
            setShowInput2('table-cell')
        }
    }

    //
    const ModificaNumeroOperaio = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("operaioId", operaioId);
            urlencoded.append("operaio", "");
            urlencoded.append("telefono", modificaNumero);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(modificaOperaiApi, requestOptions)
            .then(response => {
                response.json()

                if(response.status === 200){
                    openModalCorrect(e)
                } else {
                    openModalError(e)
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
        }
    
    const HideInput1 = (e) => {

        e.preventDefault();

        setShowTd('table-cell');
        setShowInput1('none');
    }

    const HideInput2 = (e) => {

        e.preventDefault();

        setShowTd2('table-cell')
        setShowInput2('none')
    }

    //PAGINAZIONE
    const [pages, setPages] = useState();

    useEffect(() => {
        setPages(Math.ceil(showOperai.length / dataLimit))
    }, [showOperai, dataLimit])

    var pageLimit = Math.ceil(showOperai.length / dataLimit);

    const [currentPage, setCurrentPage] = useState(1);
    
    //funzione per scorrere la pagina di uno
    function goToNextPage(){
        if(currentPage === pages || currentPage === pages + 1){
            return;
        } else {
        setCurrentPage((page) => page + 1);
        }
    }

    //funzione per la pagina di una posizione indietro
    function goToPreviousPage() {
        if(currentPage === 1){
            return;
        } else {
            setCurrentPage((page) => page - 1);
        }
    }

    //funzione per scorrere la pagina quando viene cliccato un numero
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        if(pageNumber === 0 || pageNumber === pages + 1){
            return;
        } else {
            setCurrentPage(pageNumber);
        }
    }

    //variabili per l'inizio e la fine dei dati
    const endIndex = currentPage * dataLimit;
    const startIndex = endIndex - dataLimit;

    //variabile per i dati paginati
    const getPaginatedData = showOperai.slice(startIndex, endIndex);

    //funzione per mostrare i numeri giusti delle pagine
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        if(Math.ceil(pages/* getPaginatedData.length */ / dataLimit) <= pageLimit){
            pageLimit = Math.ceil(pages/dataLimit) * pageLimit/* Math.ceil(pages *//* getPaginatedData.length */ /* / dataLimit) */;
            if(dataLimit <= 3){
                pageLimit = 3;
            }
            console.log('pageLimit in paginationGroup', pageLimit)
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        } else {
            console.log('sono nell else')
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        }
        
    };

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
    }

    const openModalOperaioCorrect = () => {
        setModalOperaioCorrectisOpen(true);
    }

    const closeModalOperaioCorrect = () => {
        setModalOperaioCorrectisOpen(false);
        window.location.reload(false);
    }

    const openModalOperaioError = () => {
        setModalOperaioErrorisOpen(true);
    }

    const closeModalOperaioError = () => {
        setModalOperaioErrorisOpen(false);
    }

    //funzione per aprire modale corretta cancellazione
    const openModalImgCorrectDelete = () => {
        setModalRemoveImgCorrect(true);
        window.location.reload(false)
    }

    //funzione per chiudere modale corretta cancellazione
    const closeModalImgCorrectDelete = () => {
        setModalRemoveImgCorrect(false);
    }

    //funzione per aprire modale errore cancellazione
    const openModalImgErrorDelete = () => {
        setModalRemoveImgError(true);
    }

    //funzione per chiudere modale errore cancellazione
    const closeModalImgErrorDelete = () => {
        setModalRemoveImgError(false)
    }
    const customStylesModalButtons = {
        content: {
            top: '20%',
            left: '35%',
            height: '20vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    //Funzione per aggiungere le informazioni dell'operaio (nome, telefono) nel db
    const Aggiungi = (e) => {
        e.preventDefault();
        //faccio una post al db
        var myHeaders = new Headers();
            myHeaders.append("username", token.username);
            myHeaders.append("x-access-token", token.accessToken);

        var urlencoded = new URLSearchParams();
            urlencoded.append("operaio", operaio);
            urlencoded.append("telefono", numero);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(inserimentoOperaiApi, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalOperaioCorrect(e);
                }
                if(response.status === 400){
                    openModalOperaioError(e);
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    //useEffect per prendere i dati degli operai dal db per visualizzarli a video
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(mostraOperaiApi, requestOptions)
        .then(response => response.json())
        .then(operaiData => { 
            console.log('Response show Operai:', operaiData)

            const dati = Object.values(operaiData.response.risultati)/* .filter(item => item.deleted === false) */.map(val => {
                return val;
            })
            setShowOperai(() => {
                return [...dati]
            })
        })
        .catch(error => console.log('error', error));
    }, [token.accessToken])

    //Funzione per aggiungere le immagini al db
    const AggiungiFoto = (e) => {
        e.preventDefault();
        //faccio una post al db

        var myHeaders = new Headers();
            myHeaders.append("username", token.username);
            myHeaders.append("x-access-token", token.accessToken);

        var formdata = new FormData();
        for(let i = 0; i < imgOperai.length; i++){
            formdata.append("images", imgOperai[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

    fetch(inserimentoOperaiImgApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrect(e);
            }
            if(response.status === 400){
                openModalError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    //Funzione che mi setta le immagini nello stato
    const selectedFiles = (e) => {
        setImgOperai(e.target.files)
    }

    //useEffect che preleva le immagini per poi visualizzarle
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(mostraOperaiImgApi, requestOptions)
            .then(response => response.json())
            .then(imgOperaiData => {
                console.log(imgOperaiData)
                
                const dati = Object.values(imgOperaiData.image)/* .filter(item => item.deleted === false) */.map(val => {
                    return val;
                })
                setImgOperai(() => {
                    return [...dati]
                })
            })
            .catch(error => console.log('error', error));
        }, [token.accessToken])

    //apertura modale ricerca
    const openModal = () => {
            setmodalisOpen(true);
    }
        
    //chiusura modale ricerca
    const closeModal = () => {
            setmodalisOpen(false);
    }
    
    const customStyles = {
            content: {
                top: '20%',
                left: '25%',
                height: '20%',
                padding: '1%',
                width: '50%',
                backgroundColor: '#ffffff'
            },
            overlay: {
                zIndex: 100,
                backgroundColor      : 'rgb(255 255 255 / 0%)',
                backdropFilter       : 'blur(4px)'
            }
    };

    const toggleForm = (e) => {
        e.preventDefault();
        if(showForm === 'none' && showFormOperaio === 'none'){
            setshowForm('flex');
            setshowTable('none');
            setFormOperaio('none');
            setShowIconTable('flex');
        } else if(showFormOperaio === 'flex' && showForm === 'none'){
            setFormOperaio('none');
            setshowForm('none');
            setshowTable('flex');
            setShowIconTable('none');
        }else {
                setshowForm('none');
                setshowTable('flex');
                setFormOperaio('none');
                setShowIconTable('none');
            }
    }

    const toggleFormOperai = (e) => {
        e.preventDefault();
        if(showFormOperaio === 'none'){
            setFormOperaio('flex');
            setshowForm('none');
            setshowTable('none');
            setShowIconTable('flex');
        } else {
            setFormOperaio('none');
            setshowForm('none');
            setshowTable('flex');
            setShowIconTable('none');
        }
    }

    const openasktoRemoveImgModal = (e) => {
        e.preventDefault();
        console.log(e.target)
        if(!e.target.id){
            return;
        } else {
            console.log('quando premo sulla X', e.target.id);
            setImageId(e.target.id)
            setasktoRemoveImg(true);
        }
    }

    //funzione per il fake delete dell'img
    const ImageDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("imageId", imageId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiOperaiImgApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalImgCorrectDelete(e);
            }
            if(response.status >= 400){
                openModalImgErrorDelete(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const showTransition = () => {
        setImgOpacity(0.3);
        setDivBackground('rgba(51, 51, 51, 1)')
        setShowDelete('block');
    }

    const hideTransition = () => {
        setImgOpacity(1);
        setDivBackground('rgba(51, 51, 51, 0)')
        setShowDelete('none');
    }

    return(
        <div className="wrapperOperai">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()} />
            <div className="operaiwrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                <div className="toggleform">
                    <FontAwesomeIcon className="plusicon" icon={faCalendarAlt} onClick={toggleForm} size="2x" style={{display: showtable, color: '#33448f'}}/>
                    <FontAwesomeIcon className="plusicon" icon={faUserPlus} onClick={toggleFormOperai} size="2x" style={{display: showtable, marginLeft: '1em', color: '#33448f'}}/>
                    <FontAwesomeIcon className="tableicon" icon={faTable} onClick={toggleForm} size="2x" style={{display: showicontable, color: '#33448f'}}/>
                </div>
                
                <div className="forminserimento">
                    <form
                        style={{display: showForm}}
                        className="inserimentoinformazioni"
                        onSubmit={AggiungiFoto}
                        encType="multipart/form-data">
                            <label htmlFor="file">Inserisci Img (Max 2 file)</label>
                            <p>Il file deve essere un PNG, JPG o JPEG, non deve contenere spazi ed essere superiore di 5 Mb</p>
                            <input type="file" name="file" aria-label="File browser" multiple onChange={selectedFiles}></input>
                            <button type="submit">Upload</button>
                    </form>
                    <form style={{display: showFormOperaio}} className="inserimentoinformazioni">
                        <label htmlFor="nome">Operaio</label>
                        <input type="text" name="nome" placeholder="Inserisci il nome e cognome" value={operaio} onChange={e => setOperaio(e.target.value)}></input>

                        <label htmlFor="tel">Num.Operaio</label>
                        <input type="text" name="tel" placeholder="Inserisci il numero di telefono" value={numero} onChange={e => setNumero(e.target.value)}></input>

                        <div className="buttoninserimentooperai">
                            <ButtonAggiungi add={Aggiungi} title1="Aggiungi Operaio"/>
                        </div>
                    </form>
                </div>
                <div className="wrapperinfo">
                    <div className="wrapperimg">
                        <div className="imgWrapper" style={{display: showtable}}>
                            {
                                Object.values(imgOperai).map((val, i) => {
                                    if(val.image === ''){
                                        return null;
                                    } else {
                                        return ( <div className="operaiImgLoaded"
                                    key={i} 
                                    id={val.id}
                                    style={{background: divBackground}}>
                                <img className="imgMappaOperai"
                                    alt="imgMappaOperai" 
                                    src={imgUrl + val.image} 
                                    id={val.id}
                                    style={{opacity: imgOpacity}}
                                    onMouseEnter={showTransition}
                                    onMouseLeave={hideTransition}
                                /> 
                                <FontAwesomeIcon 
                                    className="imgOperaiDelete"
                                    icon={faTrashAlt} 
                                    id={val.id}
                                    onClick={openasktoRemoveImgModal} 
                                    size="1x"
                                    style={{cursor: 'pointer', display: showDelete}}
                                    onMouseEnter={showTransition}/>

                                <Modal
                                    isOpen={asktoRemoveImg}
                                    ariaHideApp={false}
                                    style={customStylesModalButtons}>
                                    <p>Attenzione! Stai per rimuovere questa immagine</p>
                                    <p>Sei sicuro?</p>
                                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                    <FontAwesomeIcon 
                                        icon={faCheck} 
                                        id={val.id}
                                        onClick={ImageDeletion} 
                                        size="2x"
                                        style={{cursor: 'pointer', color: '#33448f'}}/>
                                    <FontAwesomeIcon 
                                        icon={faTimes} 
                                        onClick={closeasktoRemoveModal} 
                                        size="2x"
                                        style={{cursor: 'pointer', color: '#33448f'}}/>
                                    </div>
                                </Modal>

                                <Modal
                                    isOpen={modalRemoveImgCorrect}
                                    ariaHideApp={false}
                                    style={customStylesModalButtons}>
                                    <button onClick={closeModalImgCorrectDelete} className="closingbuttonmodal">x</button>
                                    <p>Immagine rimossa con successo</p>
                                </Modal>

                                <Modal
                                    isOpen={modalRemoveImgError}
                                    ariaHideApp={false}
                                    style={customStylesModalButtons}>
                                    <button onClick={closeModalImgErrorDelete} className="closingbuttonmodal">x</button>
                                    <p>Non è stato possibile eliminare l'immagine</p>
                                </Modal>
                            </div>);
                            }
                        })
                        }
                        </div>
                    </div>
                <div className="listaOperaiWrapper" style={{display: showtable}}>
                    <div className="modalicon">
                        <button id="iconSearch" style={{display: showtable}} onClick={openModal}>
                            <p>CERCA</p>
                            <FontAwesomeIcon icon={faSearch} size="1x" style={{display: showtable, marginLeft: '.2em', alignSelf: 'center'}}/>
                        </button>
                        <Modal isOpen={modalisOpen}
                            ariaHideApp={false}
                            style={customStyles}>
                            <button onClick={closeModal} className="closingbuttonmodaloperaio">x</button>
                            <FiltraggioOperai setShowOperai={setShowOperai} closeModal={closeModal}/>
                        </Modal>
                    </div>
                    <div className="tabellaOperai" style={{display: showtable}}>
                        <table>
                            <thead className="tabellaOperaiKeys">
                                {/* QUA CI VA TABLE KEYS */}
                                <tr>
                                {
                                    Object.keys(tabellaOperai).map((tab, i) => {
                                        return <th key={i}>
                                            {tab}
                                        </th>
                                    })
                                }
                                </tr>
                            </thead>

                            {/* QUA CI VANNO I DATI DELLA TABELLA */}
                            <tbody>
                                {
                                    Object.values(getPaginatedData).map((val, i) => {
                                        if(val.operaio === ''){
                                            return null;
                                        } else {
                                    return ( <tr className="rowOperai" key={i} id={val.id}>
                                <td
                                    style={{display: showTd, cursor: 'pointer'}}
                                    id={val.id}
                                    onClick={ShowHideInputOperaio}
                                    >{val.operaio}
                                </td>
                                <td
                                    style={{display: showInput1}}
                                    id={val.id}>
                                    <input type="text" placeholder="Nome e cognome" value={modificaOperaio} onChange={e => setModificaOperaio(e.target.value)}></input>
                                    <FontAwesomeIcon 
                                        icon={faCheck}
                                        id={val.id}
                                        onClick={ModificaOperaio}
                                        size="1x" 
                                        style={{cursor: 'pointer', color: 'green'}}
                                    />
                                    <FontAwesomeIcon 
                                        icon={faTimes}
                                        id={val.id}
                                        onClick={HideInput1}
                                        size="1x"
                                        style={{cursor: 'pointer', color: 'red', marginLeft: '1em'}}
                                    />
                                </td>
                                <td
                                    style={{display: showTd2, cursor: 'pointer'}}
                                    id={val.id}
                                    onClick={ShowHideInputNumero}
                                    >{val.telefono}
                                </td>
                                <td
                                    style={{display: showInput2}}
                                    id={val.id}>
                                    <input type="text" placeholder="numero di telefono" value={modificaNumero} onChange={e => setModificaNumero(e.target.value)}></input>
                                    <FontAwesomeIcon 
                                        icon={faCheck}
                                        id={val.id}
                                        onClick={ModificaNumeroOperaio}
                                        size="1x" 
                                        style={{cursor: 'pointer', color: 'green'}}
                                    />
                                    <FontAwesomeIcon 
                                        icon={faTimes}
                                        id={val.id}
                                        onClick={HideInput2}
                                        size="1x"
                                        style={{cursor: 'pointer', color: 'red', marginLeft: '1em'}}
                                    />
                                </td>
                                <td>
                                    <FontAwesomeIcon 
                                        icon={faTrashAlt} 
                                        id={val.id}
                                        onClick={openasktoRemoveModal} 
                                        size="lg"
                                        style={{cursor: 'pointer', alignSelf: 'center'}}
                                    />
                                </td>

                                    <Modal
                                        isOpen={asktoRemove}
                                        ariaHideApp={false}
                                        style={customStylesOperaiModal}>
                                        <p>Attenzione! Stai per rimuovere questo record</p>
                                        <p>Sei sicuro?</p>
                                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
                                        <FontAwesomeIcon 
                                            icon={faCheck} 
                                            id={val.id}
                                            onClick={OperaioDeletion} 
                                            size="2x"
                                            style={{cursor: 'pointer', color: '#33448f'}}/>
                                        <FontAwesomeIcon 
                                            icon={faTimes} 
                                            onClick={closeasktoRemoveModal} 
                                            size="2x"
                                            style={{cursor: 'pointer', color: '#33448f'}}/>
                                        </div>
                                    </Modal>

                                    <Modal
                                        isOpen={modalCorrectisOpen}
                                        ariaHideApp={false}
                                        style={customStylesOperaiModal}>
                                        <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                                        <p style={{marginTop: '5em'}}>Operazione eseguita correttamente</p>
                                    </Modal>

                                    <Modal
                                        isOpen={modalErrorisOpen}
                                        ariaHideApp={false}
                                        style={customStylesOperaiModal}>
                                        <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                                        <p style={{marginTop: '5em'}}>Ops! Qualcosa è andato storto!</p>
                                    </Modal>
                                </tr>
                                );
                            }
                            })
                            }
                            </tbody>
                        </table>
                        
                    </div>
                    <Paginazione
                        dataLimit={dataLimit}
                        getPaginationGroup={getPaginationGroup}
                        goToNextPage={goToNextPage}
                        goToPreviousPage={goToPreviousPage}
                        pages={pages}
                        currentPage={currentPage}
                        changePage={changePage} 
                    />
                </div>
            </div>  

            <Modal
                isOpen={modalCorrectisOpen}
                ariaHideApp={false}
                style={customStylesModalButtons}>
                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '3em'}}>File aggiunti correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStylesModalButtons}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '3em'}}>Nessun file aggiunto</p>
            </Modal>

            <Modal
                isOpen={modalOperaioCorrectisOpen}
                ariaHideApp={false}
                style={customStylesModalButtons}>
                <button onClick={closeModalOperaioCorrect} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '3em'}}>Informazioni aggiunte correttamente</p>
            </Modal>

            <Modal
                isOpen={modalOperaioErrorisOpen}
                ariaHideApp={false}
                style={customStylesModalButtons}>
                <button onClick={closeModalOperaioError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '3em'}}>Errore! Compila entrambi i campi</p>
            </Modal>
            </div>
        </div>
    );
}

export default Infos;