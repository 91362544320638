import React from 'react';
import { useLocation, useParams} from 'react-router-dom';

import Sidebar from '../comuni/sidebar';
import './eternit.css';

function Eternit(){

    const { comune } = useParams();
    const location = useLocation();
    const { pagine } = location.state;

    //hook per l'input
    /* const [modulo, setModulo] = useState('');

    const history = useHistory();

    const location = useLocation()
    const { pagine } = location.state

    const Aggiungi = (e) => {
        e.preventDefault();
        //faccio una post al db
    }

    const Exit = (e) => {
        e.preventDefault();
        history.goBack();
    } */

    //devo fare una get al db per le img

    return(
        <div className="wrapperEternit">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="eternitWrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>
                <p>Coming Soon</p>
            </div>
            {/* <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1)}</h2>
            <form>
                <label htmlFor="file">Inserisci Img</label>
                <input type="file" name="file" aria-label="File browser" multiple></input>
                <ButtonAggiungi add={Aggiungi} title1="Aggiungi"/>
            </form>
            <img alt="imgmodulo"></img>
            <ButtonEsci abort={Exit} title3="Annulla ed Esci" /> */}
        </div>
    );
}

export default Eternit;