import React, { useEffect, useContext, useState } from 'react'

import {ConfigContext} from '../context/config';

import './dashboardChiamate.css'

export default function DashboardChiamate(){

    //variabili d'utility
    const file = useContext(ConfigContext);
    const comuni = Object.values(file.config.ENUMcomuni.telefonate);
    const serviziStrade = Object.values(file.config.strade.servizi);

    //creazione dell'array con le date della settimana
    const dates = (startDate, num) => Array.from(
        { length: num },
        (_, i) => new Date(startDate.getTime() + (i * 60000 * 60 * 24)).toLocaleDateString().split('/')[0] + '/' + new Date(startDate.getTime() + (i * 60000 * 60 * 24)).toLocaleDateString().split('/')[1].slice(0, 10)
    );

    const lastWeek = () => {
        let date = new Date();
        date.setDate(date.getDate() - date.getDay() - 6);
        return dates(date, 7);
    }

    //chiamata API
    const getDatiforTheBoss = '/api/v1/telefonate/dashboardchiamate'

    const [tabIgieneData, setTabIgieneData] = useState([]);
    const [tabStradeData, setTabStradedata] = useState([]);
    const [tabAltreChiamateData, setTabAltreChiamateData] = useState([]);

    //oggetti per modificare la response in un dato più consono al mio utilizzo
    const datiIgiene = {};
    const datiStrade = {};
    const datiAltreChiamate = {};

    comuni.map(comune => {
        datiIgiene[comune] = [
            {
                total: 0,
                data: []
            }
        ];
    })

    serviziStrade.map(servizio => {
        datiStrade[servizio] = [
            {
                total: 0,
                data: []
            }
        ];
    })

    //useEffect per prelevare i dati
    useEffect(() => {

        var myHeaders = new Headers();
        myHeaders.append("allowed", "YXZyc3Bh");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(getDatiforTheBoss, requestOptions)
        .then(response => response.json())
        .then(result => {
            setTabIgieneData(result.datiIgiene)
            setTabStradedata(result.datiStrade)
            setTabAltreChiamateData(result.datiAltro)
        })
        .catch(error => console.log('error', error));
    
    }, [])

    /* console.log('tabIgieneData', tabIgieneData) */

    for(let i = 0; i < tabIgieneData.length; i++){
        let counter = 0;
        let total = 0;
        var item = datiIgiene[tabIgieneData[i].luogo][0].data;
        
        item.push({
            data: tabIgieneData[i].data,
            count: tabIgieneData[i].telefonate_count,
        })

        for(let j = 0; j < item.length; j++){
            total += item[j].count;
            counter++;
            if(counter === item.length){
                datiIgiene[tabIgieneData[i].luogo][0].total = total;
                total = 0;
            }
        }
    }

    console.log('dati igiene', datiIgiene)

    for(let i = 0; i < tabStradeData.length; i++){
        let counter = 0;
        let total = 0;
        let item = datiStrade[tabStradeData[i].servizio][0].data;

        item.push({
            data: tabStradeData[i].data,
            count: tabStradeData[i].telefonate_count,
        })

        for(let j = 0; j < item.length; j++){
            total += item[j].count;
            counter++;
            if(counter === item.length){
                datiStrade[tabStradeData[i].servizio][0].total = total;
                total = 0;
            }
        }
    }

    //console.log('dati strade', datiStrade)

    datiAltreChiamate['Altre Chiamate'] = [
        {
            total: 0,
            data: []
        }
    ];

    /* var totalCountAltreChiamate = 0; */

    for(let i = 0; i < tabAltreChiamateData.length; i++){
        let counter = 0;
        let total = 0;
        let item = datiAltreChiamate[tabAltreChiamateData[i].altreChiamate][0].data;
        /* totalCountAltreChiamate += tabAltreChiamateData[k].telefonate_count; */
        item.push({
            data: tabAltreChiamateData[i].data,
            count: tabAltreChiamateData[i].telefonate_count,
        })

        for(let j = 0; j < item.length; j++){
            total += item[j].count;
            counter++;
            if(counter === item.length){
                datiAltreChiamate[tabAltreChiamateData[i].altreChiamate][0].total = total;
                total = 0;
            }
        }
    }

    //console.log('dati Altre chiamate', datiAltreChiamate)

    return (
        <div className="resocontoChiamateWrapper">
            <h2>Resoconto chiamate</h2>
            <div className="resocontoChiamateContainer">
            
                <div className="risultatiTabellaIgiene">
                    <div className="divForScrolling">
                        <div className="titoloTabellaIgieneContainer">
                            <h4 className="titoloTabellaIgiene">Igiene</h4>
                        </div>

                        <div className="titoliResoconti">
                        
                            <div className="colonneTabella">
                            
                                <div className="colonnaTipologia">
                                    <h4>Luogo</h4>
                                </div>
                                <div className="dataTabellaContainer">
                                {
                                    lastWeek().map((data, i) => {
                                        return (
                                            <h6 key={i}>{data}</h6>
                                        );
                                    })
                                }
                                </div>
                                <div className="colonnaTotali">
                                    <h4>Totali</h4>
                                </div>
                            </div>
                        </div>
                        <div className="datiTabella">
                        {
                            Object.keys(datiIgiene).map((nome, k) => {
                                return (
                                    <div className="rigaComune" key={k}>
                                        <h5>{nome.replace(/_/g, " ")}</h5>
                                        <div className="countersDati">
                                        {
                                            Object.values(datiIgiene[nome][0].data).map((items, i) => {
                                                return (
                                                    <h6 key={i}>{items.count}</h6>
                                                );
                                            })
                                        }
                                        </div>
                                        <div className="countersTotali">{datiIgiene[nome][0].total}</div>
                                    </div>
                                );
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className="risultatiTabellaStrade">
                    <div className="divForScrolling">
                        <div className="titoloTabellaStradeContainer">
                            <h4 className="titoloTabellaStrade">Servizi Strade</h4>
                        </div>
                        <div className="titoliResoconti">
                        
                            <div className="colonneTabella">
                                <div className="colonnaTipologia">
                                    <h4>Servizio</h4>
                                </div>
                                <div className="dataTabellaContainer">
                                {
                                    lastWeek().map(data => {
                                        return (
                                            <h6>{data}</h6>
                                        );
                                    })
                                }
                                </div>
                                <div className="colonnaTotali">
                                    <h4>Totali</h4>
                                </div>
                            </div>
                        </div>
                        <div className="datiTabella">
                        {
                            Object.keys(datiStrade).map((servizio, i) => {
                                return (
                                    <div className="rigaComuneStrade">
                                        <h5>{servizio.replace(/_/g, " ")}</h5>
                                        <div className="countersDatiStrade">
                                        {
                                            Object.values(datiStrade[servizio][0].data).map((items, i) => {
                                                return (
                                                    <h6 key={i}>{items.count}</h6>
                                                );
                                            })
                                        }
                                        </div>
                                        <div className="countersTotali">{datiStrade[servizio][0].total}</div>
                                    </div>
                                );
                            })
                        }
                        </div>
                    </div>
                </div>
                <div className="risultatiTabellaAltreChiamate">
                    <div className="divForScrolling">
                        <div className="titoloTabellaAltreChiamateContainer">
                            <h4 className="titoloTabellaAltreChiamate">Altre Chiamate</h4>
                        </div>
                        <div className="titoliResoconti">
                        
                            <div className="colonneTabella">
                                <div className="colonnaTipologia">
                                    <h4>Altro</h4>
                                </div>
                                <div className="dataTabellaContainer">
                                {
                                    lastWeek().map(data => {
                                        return (
                                            <h6>{data}</h6>
                                        );
                                    })
                                }
                                </div>
                                <div className="colonnaTotali">
                                    <h4>Totali</h4>
                                </div>
                            </div>
                        </div>
                        <div className="datiTabella">
                            <div className="rigaComune">
                                <h5>Altro</h5>
                                <div className="countersDati">
                                {
                                    Object.values(datiAltreChiamate['Altre Chiamate'][0].data).map((items, i) => {
                                        return (
                                            <h6 key={i}>{items.count}</h6>
                                        );
                                    })
                                }
                                </div>
                                <div className="countersTotali">{datiAltreChiamate['Altre Chiamate'][0].total}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};