import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import ButtonAggiungi from '../buttons/buttonaggiungi';
import ButtonEsci from '../buttons/buttonesci';
import Sidebar from '../comuni/sidebar';
import './modifica_richieste.css';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faUserEdit } from '@fortawesome/free-solid-svg-icons';

//Componente che permette la modifica di una richiesta
function ModificaRichieste(){

    const history = useHistory();
    const { comune } = useParams();
    const token = JSON.parse(localStorage.getItem('token'));
    const location = useLocation();

    /* console.log('location', location) */

    //Chiamate API
    const modificaRichiestaApi = "/api/v1/tabella/home";
    const modificaUtenzeApi = "/api/v1/tabella/modificaUtenze";
    /* const ricercaApi = "/api/v1/tabella/ricerca"; */

    //Hooks
    const [showInput, setShowInput] = useState('none');   //stato per mostrare il form d'inserimento
    const [ricercaClick, setRicercaClick] = useState([])
    const [isdisabled, setIsDisabled] = useState(true); //stato per gestire gli input che devo modificare
    const [disabledBg, setDisabledBg] = useState('#e3e3e3');

    //hook per l'apertura dei modali
    const [modalModifyisOpen, setmodalModifyisOpen] = useState(false);
    const [modalErrorisOpen, setmodalErrorisOpen] = useState(false);
    const [modaltext, setModalText] = useState('');
    const [modalModifyUtenza, setModalModifyUtenza] = useState(false);
    /* const [errorCompileData, setErrorCompileData] = useState('rgb(243, 243, 243)');
    const [errorCompileOra, setErrorCompileOra] = useState('rgb(243, 243, 243)');
    const goTo = useRef(null); */

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '30vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const openModalModify = () => {
        setmodalModifyisOpen(true);
    }

    const closeModalModify = () => {
        setmodalModifyisOpen(false);
        history.goBack()
    }

    const openModalError = testo => {
        setModalText(testo)
        setmodalErrorisOpen(true);
    }

    const closeModalError = () => {
        setmodalErrorisOpen(false);
        history.goBack()
    }

    //hook per l'id della richiesta
    const [id, setId] = useState('')

    //hook per l'id dell'utente
    const [userId, setUserId] = useState('')

    //hook per acquisizione data
    const [data, setData] = useState('');

    //hook per acquisizione ora
    const [ora, setOra] = useState('');

    //hook per acquisire nome
    const [nome, setNome] = useState('');

    //hook per acquisire cognome
    const [cognome, setCognome] = useState('');

    //hook per il telefono di casa
    const [telefono1, setTelefono1] = useState('');

    //hook per il telefono cellulare
    const [telefono2, setTelefono2] = useState('');

    //hook per l'indirizzo
    const [indirizzo, setIndirizzo] = useState('');

    //hook per il civico
    const [civico, setCivico] = useState('');

    //hook per il tipo
    const [tipo, setTipo] = useState('');

    //hook per il servizio
    const [servizio, setServizio] = useState('');

    //hook per la richiesta
    const [richiesta, setRichiesta] = useState('');

    //hook per il campo organico
    const [organico, setOrganico] = useState('');

    //hook per il campo plastica
    const [plastica, setPlastica] = useState('');

    //hook per il campo vetro
    const [vetro, setVetro] = useState('');

    //hook per il campo carta
    const [carta, setCarta] = useState('');

    //hook per il campo indifferenziato
    const [indifferenziato, setIndifferenziato] = useState('');

    //hook per campo info
    const [info, setInfo] = useState('');

    //hook per campo note
    const [note, setNote] = useState('');

    const Exit = (e) => {
        //console.log('comune', comune)
        e.preventDefault();
        history.goBack();
    }

    //PUT verso il backend per la modifica
    const Modifica = (e) => {
        e.preventDefault();

        //se lo stato degli input è disabled deve fare la modifica solo dei campi della tabella richieste
        if(isdisabled === true){

            var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
            urlencoded.append("userId", id);
            urlencoded.append("data", data);
            urlencoded.append("ora", ora);
            urlencoded.append("nome", nome);
            urlencoded.append("cognome", cognome);
            urlencoded.append("indirizzo", indirizzo);
            urlencoded.append("civico", civico);
            urlencoded.append("telefono1", telefono1);
            urlencoded.append("telefono2", telefono2);
            urlencoded.append("servizio", servizio);
            urlencoded.append("richiesta", richiesta);
            urlencoded.append("organico", organico);
            urlencoded.append("plastica", plastica);
            urlencoded.append("vetro", vetro);
            urlencoded.append("carta", carta);
            urlencoded.append("indifferenziato", indifferenziato);
            urlencoded.append("info", info);
            urlencoded.append("note", note)

        let requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

            fetch(modificaRichiestaApi, requestOptions)
                .then(response => {
                    response.json()
                    if(response.status === 200){
                        openModalModify(e);
                    }
                    if(response.status >= 400){
                        openModalError("Errore! Qualcosa è andato storto durante l'operazione");
                        /* if(!data){
                            handleScroll()
                            setErrorCompileData('#F08080');
                        }
                        if(!ora){
                            handleScroll();
                            setErrorCompileOra('#F08080');
                        } */
                    }
                })
                /* .then(result => console.log(result)) */
                .catch(error => console.log('error', error));
            } 
        //altrimenti fa la modifica della tabella utenze
        else {

        let myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        let urlencoded = new URLSearchParams();
            urlencoded.append("userId", userId);
            urlencoded.append("data", data);
            urlencoded.append("ora", ora);
            urlencoded.append("nome", nome);
            urlencoded.append("cognome", cognome);
            urlencoded.append("indirizzo", indirizzo);
            urlencoded.append("civico", civico);
            urlencoded.append("telefono1", telefono1);
            urlencoded.append("telefono2", telefono2);
            urlencoded.append("servizio", servizio);
            urlencoded.append("richiesta", richiesta);
            urlencoded.append("organico", organico);
            urlencoded.append("plastica", plastica);
            urlencoded.append("vetro", vetro);
            urlencoded.append("carta", carta);
            urlencoded.append("indifferenziato", indifferenziato);
            urlencoded.append("info", info);
            urlencoded.append("note", note);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

            fetch(modificaUtenzeApi, requestOptions)
                .then(response => {
                    response.json()
                    if(response.status === 200){
                        openModalModify(e);
                    }
                    else if(response.status >= 400){
                        /* console.log('id', id)
                        console.log('nome', nome) */
                        openModalError("Errore! Qualcosa è andato storto durante l'operazione");
                        /* if(!data){
                            handleScroll()
                            setErrorCompileData('#F08080');
                        }
                        if(!ora){
                            handleScroll();
                            setErrorCompileOra('#F08080');
                        } */
                    }
                })
                /* .then(result => console.log(result)) */
                .catch(error => console.log('error', error));
            }
        }

    //useEffect che ascolta i cambiamenti dello stato contenente i dati relativi al click
    useEffect(() => {
        if(location.tab !== undefined){
            setRicercaClick(location.tab)
            setShowInput('flex')
        } else {
            return;
        }
    }, [location.tab])

    //UseEffect che gestisce l'attribuzione ai vari stati dei dati provenienti dal click sulla tabella della dashboard
    useEffect(() => {
        Object.values(ricercaClick).map(val => {
            /* console.log('val useEffect post click', val) */
            setId(val.id); //richiestaId
            setUserId(val.utenze.id)
            setData(val.data);
            setOra(val.ora);
            setNome(val.utenze.nome);
            setCognome(val.utenze.cognome);
            setIndirizzo(val.utenze.indirizzo);
            setCivico(val.utenze.civico);
            setTelefono1(val.utenze.telefono1);
            setTelefono2(val.utenze.telefono2)
            setServizio(val.servizio);
            setRichiesta(val.richiesta)
            setOrganico(val.utenze.organico);
            setCarta(val.utenze.carta);
            setVetro(val.utenze.vetro);
            setIndifferenziato(val.utenze.indifferenziato);
            setPlastica(val.utenze.plastica);
            setNote(val.note);
            setInfo(val.utenze.info);
            return val;
        })
    },[ricercaClick])
    
    const openAskToModifyUtenza = () => {
        setModalModifyUtenza(true);
    }

    const closeModalModifyUtenza = () => {
        setModalModifyUtenza(false);
    }

    const enableInputs = () => {
        setIsDisabled(false);
        setDisabledBg('#fff');
        closeModalModifyUtenza();
    }

    /* const handleScroll = () => {
        goTo.current.scrollIntoView({ behavior: 'smooth' })
    } */

    return (
        <div className="modificaRichiesta">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="formwrapper">
                <h2>{isdisabled ? 'Modifica Richiesta' : 'Modifica Utenza'}</h2>

                <div className="ricercautentecompilazione">
                    <form
                        style={{display: showInput}}>

                    <h5>I campi con * sono obbligatori</h5>

                    <section className="oraedata" /* ref={goTo} */>
                        <label htmlFor="data">Data*</label>
                        <input /* style={{background: errorCompileData}} */ placeholder={new Date().toLocaleDateString('it-IT')} type="date" name="data" 
                            value={data || ''} onChange={e => setData(e.target.value)} style={{background: 'rgba(243, 243, 243, 0.4)'}}>
                        </input>

                        <label htmlFor="ora">Ora*</label>
                        <input /* style={{background: errorCompileOra}} */ placeholder={new Date().toLocaleTimeString('it-IT')} type="time" name="ora" 
                            value={ora || ''} onChange={e => setOra(e.target.value)} style={{background: 'rgba(243, 243, 243, 0.4)'}}>
                        </input>
                    </section>

                    {/* <h4>Informazioni Personali*</h4> */}

                    <section className="generalinfos">

                        <div className="userId">
                            <label htmlFor="userId">{isdisabled ? 'richiestaId' : 'userId'}</label>
                            <input id="idInput" type="text" name="userId" placeholder={isdisabled ? id : userId} style={{background: disabledBg}} disabled></input>

                            <FontAwesomeIcon 
                                icon={faUserEdit}
                                size="lg"
                                className="modifyUserIcon"
                                onClick={openAskToModifyUtenza}
                            />
                        </div>
                        <div className="nomecognome">
                            <label htmlFor="nome">Nome</label>
                            <input type="text" name="nome" placeholder={nome ? nome : 'Inserisci il nome'} 
                                value={nome || ''} onChange={e => setNome(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                            <label htmlFor="cognome">Cognome</label>
                            <input type="text" name="cognome" placeholder={cognome ? cognome : 'Inserisci il cognome'} 
                                value={cognome || ''} onChange={e => setCognome(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>
                        </div>
    
                        <div className="infoindirizzo">
                            <label htmlFor="indirizzo">Indirizzo</label>
                            <input type="text" name="indirizzo" value={indirizzo || ''} placeholder={indirizzo ? indirizzo : "Inserisci l'indirizzo "}
                                onChange={e => setIndirizzo(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                            <label htmlFor="civico">Civico</label>
                            <input type="number" placeholder={civico ? civico : 'Inserisci il civico'} name="civico" 
                                value={civico || ''} 
                                onChange={e => setCivico(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>
                        </div>

                        <div className="fraz">
                            <label htmlFor="fraz/comune">Fraz./Comune</label>
                            <input type="text" name="fraz/comune" value={comune.split("_").join(" ")} style={{background: disabledBg}} disabled={isdisabled}></input>
                        </div>
                    </section>

                    {/* <h4>Informazioni di Contatto*</h4> */}

                    <section className="contactinfos">

                        <label htmlFor="telefono1">Telefono fisso</label>
                        <input type="text" placeholder={telefono1 ? telefono1 : 'Inserisci il telefono di casa'} name="telefono1" 
                            value={telefono1 || ''} onChange={e => setTelefono1(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="telefono2">Telefono cellulare</label>
                        <input type="text" placeholder={telefono2 ? telefono2 : 'Inserisci il telefono cellulare'} name="telefono2"
                            value={telefono2 || ''} onChange={e => setTelefono2(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>
                    </section>

                    {/* <h4>Informazioni Richiesta*</h4> */}
                    {
                        isdisabled 
                    ? <section className="inforichiesta">
                        <label htmlFor="tipo">Tipo Utenza*</label>
                        <select className="selectmenu1" id="tipo" placeholder="click per selezionare"
                                name="tipo" value={tipo || ''} onChange={e => setTipo(e.target.value)}>
                            <option value="Utenza Domestica">Utenza Domestica</option>
                            <option value="Utenza Commerciale">Utenza Comm.le</option>
                        </select>

                        <label htmlFor="servizio">Servizio Richiesto*</label>
                        <select className="selectmenu2" id="servizio" placeholder={servizio ? servizio : 'Servizio'} 
                                name="servizio" value={servizio || ''} onChange={e => setServizio(e.target.value)}>
                            <option value=""> </option>
                            <option value="Ingombranti">Ingombranti</option>
                            <option value="Segnalazioni">Segnalazioni</option>
                            <option value="Richieste">Richieste</option>
                            <option value="Mancato Ritiro Organico">Mancato Ritiro Organico</option>
                            <option value="Mancato Ritiro Carta">Mancato Ritiro Carta</option>
                            <option value="Mancato Ritiro Vetro">Mancato Ritiro Vetro</option>
                            <option value="Mancato Ritiro Plastica-metallo">Mancato Ritiro Plastica-Metallo</option>
                            <option value="Mancato Ritiro Indifferenziato">Mancato Ritiro Indifferenziato</option>
                            {
                                comune === 'fara_in_sabina' || comune === 'morlupo' 
                                ? <option value="Potature">Potature</option>
                                : null
                            }
                        </select>

                        <div className="richiesta">
                            <label htmlFor="richiesta">Richiesta*</label>
                            <textarea type="text" name="richiesta" placeholder={richiesta ? richiesta : 'Richiesta'} value={richiesta || ''} 
                                onChange={e => setRichiesta(e.target.value)} maxLength="250"></textarea>
                        </div>
                    </section>
                    : null
                    }

                    <h4>Informazioni Utenza PaP</h4>
                    <section className="elencoutenzeinfos">

                        <label htmlFor="indiff">Indiff.</label>
                        <input type="text" name="indiff" placeholder={indifferenziato ? indifferenziato : 'Codice Indiff. PaP'} 
                            value={indifferenziato || ''} 
                            onChange={e => setIndifferenziato(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="plastica">Plastica</label>
                        <input type="text" name="plastica" placeholder={plastica ? plastica : 'Codice Plastica PaP'} value={plastica || ''} 
                            onChange={e => setPlastica(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="vetro">Vetro</label>
                        <input type="text" name="vetro" placeholder={vetro ? vetro : 'Codice Vetro PaP'} value={vetro || ''} 
                            onChange={e => setVetro(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="carta">Carta</label>
                        <input type="text" name="carta" placeholder={carta ? carta : 'Codice Carta PaP'} value={carta || ''} 
                            onChange={e => setCarta(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="organico">Organico</label>
                        <input type="text" name="organico" placeholder={organico ? organico : 'Codice Organico PaP'} value={organico || ''} 
                            onChange={e => setOrganico(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                    </section>

                    <h4>Informazioni Aggiuntive</h4>
                    <section className="infoaggiuntive">

                        <label htmlFor="info">Info utenza</label>
                        <input type="text" name="info" placeholder={info === 'undefined' ? 'info' : info} value={info || ''}
                            onChange={e => setInfo(e.target.value)} style={{background: disabledBg}} disabled={isdisabled}></input>

                        <label htmlFor="note">Note ritiro</label>
                        <input type="text" name="note" placeholder={note ? note : 'Note'} value={note || ''}
                            onChange={e => setNote(e.target.value)}></input>

                    </section>
                        <div className="buttons">
                            <ButtonAggiungi add={Modifica} title1="Modifica" />
                            <ButtonEsci abort={Exit} title3="Annulla ed Esci" />
                        </div>
                    </form>

                    <Modal
                        isOpen={modalModifyisOpen}
                        ariaHideApp={false}
                        style={customStyles}>
                        <button onClick={closeModalModify} className="closingbuttonmodal">x</button>
                        <p style={{marginTop: '5em'}}>Richiesta modificata correttamente</p>
                    </Modal>

                    <Modal
                        isOpen={modalErrorisOpen}
                        ariaHideApp={false}
                        style={customStyles}>
                        <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                        <p style={{marginTop: '5em'}}>{modaltext}</p>
                    </Modal>

                </div>

                
            </div>
            <Modal
                isOpen={modalModifyUtenza}
                ariaHideApp={false}
                style={customStyles}>
                    <button onClick={closeModalModifyUtenza} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '3em'}}>Attenzione! Stai per modificare l'utenza.</p>
                    <p>Sei sicuro?</p>
                    <div className="modalChooseOptions">
                        <FontAwesomeIcon 
                            icon={faCheck} 
                            size="2x"
                            style={{cursor: 'pointer'}}
                            onClick={enableInputs}
                        />
                        <FontAwesomeIcon 
                            icon={faTimes} 
                            size="2x"
                            style={{cursor: 'pointer'}}
                            onClick={closeModalModifyUtenza}
                        />
                    </div>
            </Modal>
        </div>
    );
}

export default ModificaRichieste;