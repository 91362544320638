import React, { useEffect, useState, useRef } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Modal from 'react-modal';

import './calendario.css'
import Sidebar from '../comuni/sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTimes, faTrashAlt, faCheck, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faTable } from '@fortawesome/free-solid-svg-icons';


//Componente che gestisce l'inserimento, la visualizzazione e la cancellazione dei calendari

function Calendario(){
    
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamate API
    const postCalendarioAPI = "/api/v1/calendario/postcalendario";
    const getCalendarioAPI = "/api/v1/calendario/getcalendario";
    const nascondiCalendarioAPI = "/api/v1/calendario/postcalendario";

    //hooks
    const [showForm, setshowForm] = useState('none'); //hook per mostrare o nascondere il form
    const [showtable, setshowTable] = useState('flex') //hook per mostrare o nascondere la tabella
    const [calendario, setCalendario] = useState([]);
    const [modalCalendarRemoved, setModalCalendarRemoved] = useState(false);
    const [modalCalendarRemovedError, setModalCalendarRemovedError] = useState(false);
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [asktoRemove, setasktoRemove] = useState(false);
    const [calendarioId, setCalendarioId] = useState(0);
    const [calendarOpacity, setCalendarOpacity] = useState(1);
    const [divBackground, setDivBackground] = useState('rgba(51, 51, 51, 0');
    const [showDelete, setShowDelete] = useState('none');
    const [imgId, setImgId] = useState(0);
    const [imgSrc, setImgSrc] = useState('');
    const [imgAlt, setImgAlt] = useState('');

    const openModalCalendarRemoved = () => {
        setModalCalendarRemoved(true);
    }

    const closeModalCalendarRemoved = () => {
        setModalCalendarRemoved(false);
        window.location.reload(false);
    }

    const openModalCalendarRemovedError = () => {
        setModalCalendarRemovedError(true);
    }

    const closeModalCalendarRemovedError = () => {
        setModalCalendarRemovedError(false);
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        setasktoRemove(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
        setasktoRemove(false);
    }

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        //console.log(e.target)
        if(!e.target.id){
            return;
        } else {
            //console.log('quando premo sulla X', e.target.id);
            setCalendarioId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(4px)'
        }
    };

    //Funzione per inserire le immagini
    const formSubmit = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", token.username);
            myHeaders.append("x-access-token", token.accessToken);

        var formdata = new FormData();
            for(let i = 0; i < calendario.length; i++){
            formdata.append('images', calendario[i]);
        }

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(postCalendarioAPI, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalCorrect(e)
                }
                if(response.status === 400){
                    openModalError(e)
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
}

    const selectedFiles = (e) => {
        setCalendario(e.target.files)
    }

    //Funzione per prendere le immagini dal db
    useEffect(() => {
        var myHeaders = new Headers();
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("x-access-token", token.accessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
    };

    fetch(getCalendarioAPI, requestOptions)
        .then(response => response.json())
        .then(calendarioData => { 
            //console.log('calendari dal backend', calendarioData)

            const dati = Object.values(calendarioData.image)/* .filter(item => item.deleted === false) */.map(val => {
                return val;
            })
            setCalendario(() => {
                return [...dati]
            })

        })
        .catch(error => console.log('error', error));
    }, [token.accessToken, comune]);

    const toggleForm = () => {
        if(showForm === 'none'){
            setshowForm('flex');
            setshowTable('none');
        } else {
            setshowForm('none');
            setshowTable('flex');
        }
    }

    //Funzione per il fake delete
    const CalendarDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        //console.log('record da passare al backend', calendarioId)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", token.username);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("calendarioId", calendarioId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiCalendarioAPI, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCalendarRemoved(e);
            }
            if(response.status >= 400){
                openModalCalendarRemovedError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const showTransition = () => {
        setCalendarOpacity(0.3);
        setDivBackground('rgba(51, 51, 51, 1)')
        setShowDelete('block');
    }

    const hideTransition = () => {
        setCalendarOpacity(1);
        setDivBackground('rgba(51, 51, 51, 0)')
        setShowDelete('none');
    }

    const targetImg = (e) => {
        //console.log(e.target.src);
        setImgId(e.target.id);
        setImgSrc(e.target.src);
        setImgAlt(e.target.alt);
    }

    //Variabile per lo scroll
    const ref = useRef(null)

    const scroll = (scrollOffset) => {
        //console.log(scrollOffset)
        ref.current.scrollLeft += scrollOffset;
    };

    let imgUrl = 'https://scr.avrgroup.it/public';

    return(
        <div className="wrapperCalendario">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="calendariowrapper">
                    <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1)}</h2>
                    <div className="toggleform">
                        <FontAwesomeIcon className="plusicon" icon={faPlusCircle} onClick={toggleForm} size="2x" style={{display: showtable, color: '#33448f'}}/>
                        <FontAwesomeIcon className="tableicon" icon={faTable} onClick={toggleForm} size="2x" style={{display: showForm, color: '#33448f'}}/>
                    </div>
                    <form 
                        style={{display: showForm}}
                        className="inserimentoImg"
                        onSubmit={formSubmit}
                        encType="multipart/form-data">
                        <h4>Upload Calendario (max 12 file)</h4>
                        <input type="file" name="images" aria-label="File browser" multiple onChange={selectedFiles}></input>
                        <p>Il file deve essere un PNG, JPG o JPEG, non deve contenere spazi ed essere superiore di 5 Mb</p>
                        <button type="submit">Upload</button>
                    </form>
                <div className="imgContainer" style={{display: showtable}}>

                    {/* Qua va mostrata la prima immagine o quella che clicco*/}


                    {/* Col ternary operator controllo lo stato se non è presente prendo le info direttamente dallo stato calendario
                    per mostrare la prima immagine altrimenti applico la logica del click */}
                    {
                        imgSrc === '' ? 
                        
                        Object.values(calendario).slice(0, 1).map((img, i) => {
                            //console.log(img)
                            return <div className="imgLoaded"
                                    key={i}
                                    id={img.id}
                                    style={{background: divBackground}}>
                                <img className="calendarPage"
                                    alt={img.alt}
                                    src={imgUrl + img.resize}
                                    id={img.id}
                                    style={{opacity: calendarOpacity}}
                                    onMouseEnter={showTransition}
                                    onMouseLeave={hideTransition}
                                />

                            <FontAwesomeIcon 
                                className="calendarDelete"
                                icon={faTrashAlt} 
                                id={img.id}
                                onClick={openasktoRemoveModal} 
                                size="1x"
                                style={{cursor: 'pointer', display: showDelete}}
                                onMouseEnter={showTransition}
                        />
                            </div>
                        })
                    
                        :

                    <div 
                        className="imgLoaded"
                        id={imgId}
                        style={{background: divBackground, display: calendario.length > 0 ? 'flex' : 'none'}}
                        >
                        <img className="calendarpage" 
                            alt={imgAlt} 
                            src={imgSrc} 
                            id={imgId}
                            style={{opacity: calendarOpacity}}
                            onMouseEnter={showTransition}
                            onMouseLeave={hideTransition}
                        />

                        <FontAwesomeIcon 
                            icon={faTrashAlt} 
                            id={imgId}
                            onClick={openasktoRemoveModal} 
                            size="1x"
                            style={{cursor: 'pointer', display: showDelete}}
                            className="calendarDelete"
                            onMouseEnter={showTransition}
                        />

                </div>
                }

            <Modal
                isOpen={asktoRemove}
                ariaHideApp={false}
                style={customStyles}>
                <p>Attenzione! Stai per rimuovere questo calendario</p>
                <p>Sei sicuro?</p>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                <FontAwesomeIcon 
                    icon={faCheck}
                    id={imgId}
                    onClick={CalendarDeletion} 
                    size="2x"
                    style={{cursor: 'pointer', color: '#33448f'}}/>
                <FontAwesomeIcon 
                    icon={faTimes} 
                    onClick={closeasktoRemoveModal} 
                    size="2x"
                    style={{cursor: 'pointer', color: '#33448f'}}/>
                </div>
            </Modal>

                </div>
                <div className="calendarListWrapper" style={{display: showtable}} >
                    

                    {/* Bottone per scorrere a sinistra */}

                    <div className="buttonLeft" style={{display: calendario.length > 5 ? 'flex' : 'none'}} onMouseDown={() => scroll(-120)}>
                        <FontAwesomeIcon 
                            icon={faArrowLeft}
                            size="1x"
                        />
                    </div>

                    <div className="calendarListContainerWrapper" style={{overflowX: calendario.length > 5 ? 'scroll' : 'hidden', scrollBehavior: 'smooth', display: calendario.length > 0 ? 'flex' : 'none'}} ref={ref}>
                    {
                        Object.values(calendario).map((img, i) => {
                            return <div className="calendarListContainer" key={i}>
                                <img className="calendarListed" 
                                    alt={img.nome_img} 
                                    src={imgUrl + img.resize} 
                                    id={img.id}
                                    onClick={targetImg}
                                />
                            </div>
                        })
                    }
                    </div>

                    {/* Bottone per scorrere a destra */}

                    <div className="buttonRight" style={{display: calendario.length > 5 ? 'flex' : 'none'}} onMouseDown={() => scroll(120)}>
                        <FontAwesomeIcon 
                            icon={faArrowRight}
                            size="1x"
                        />
                    </div>
                    
                </div>
            </div>

            <Modal
                isOpen={modalCorrectisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>File aggiunti correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Nessun file aggiunto</p>
            </Modal>

            <Modal
                isOpen={modalCalendarRemoved}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCalendarRemoved} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Calendario rimosso con successo</p>
            </Modal>

            <Modal
                isOpen={modalCalendarRemovedError}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalCalendarRemovedError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Non è stato possibile eliminare il calendario</p>
            </Modal>
        </div>
    );
}

export default Calendario;