import React, { useState, useEffect } from 'react';
import { useParams, useHistory, useLocation } from 'react-router';
import Modal from 'react-modal';

import Sidebar from '../comuni/sidebar';
import './modifica_utenze.css'
import ButtonAggiungi from '../buttons/buttonaggiungi';
import ButtonEsci from '../buttons/buttonesci';

//Componente che permette la modifica da parte dell'operatore delle informazioni inerenti la specifica utenza una volta selezionato il record.

function ModificaUtenza(){

    const history = useHistory();
    const location = useLocation();
    const { comune } = useParams();
    const token = JSON.parse(localStorage.getItem('token'));

    //Chimata API
    const modificaUtenzeAPI = '/api/v1/tabella/modificaUtenze';
    
    //Hook per lo stato della ricerca 
    const [ricercaClick, setRicercaClick] = useState([]);

    //hooks per l'apertura dei modali
    const [modalModifyisOpen, setmodalModifyisOpen] = useState(false);
    const [modalErrorisOpen, setmodalErrorisOpen] = useState(false);
    const [modaltext, setModalText] = useState('');

    //useEffect che setta le informazioni del click nello stato
    useEffect(() => {
        if(location.tab !== undefined){
            setRicercaClick(location.tab)
        } else {
            return;
        }
    }, [location.tab])

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const openModalModify = () => {
        setmodalModifyisOpen(true);
    }

    const closeModalModify = () => {
        setmodalModifyisOpen(false);
        history.push('/servizio/' + comune + '/dashboard', {comune: comune}) //FIX QUI
    }

    const openModalError = testo => {
        setModalText(testo)
        setmodalErrorisOpen(true);
    }

    const closeModalError = () => {
        setmodalErrorisOpen(false);
        history.push('/servizio/' + comune + '/dashboard', {comune: comune})
    }

    //hook per l'id
    const [id, setId] = useState('')

    //hook per acquisizione data
    const [data, setData] = useState('');

    //hook per acquisizione ora
    const [ora, setOra] = useState('');

    //hook per acquisire nome
    const [nome, setNome] = useState('');

    //hook per acquisire cognome
    const [cognome, setCognome] = useState('');

    //hook per il telefono di casa
    const [telefono1, setTelefono1] = useState('');

    //hook per il telefono cellulare
    const [telefono2, setTelefono2] = useState('');

    //hook per l'indirizzo
    const [indirizzo, setIndirizzo] = useState('');

    //hook per il civico
    const [civico, setCivico] = useState('');

    //hook per il campo organico
    const [organico, setOrganico] = useState('');

    //hook per il campo plastica
    const [plastica, setPlastica] = useState('');

    //hook per il campo vetro
    const [vetro, setVetro] = useState('');

    //hook per il campo carta
    const [carta, setCarta] = useState('');

    //hook per il campo indifferenziato
    const [indifferenziato, setIndifferenziato] = useState('');

    //hook per campo info
    const [info, setInfo] = useState('');

    const Exit = (e) => {
        e.preventDefault();
        history.goBack();
        /* history.go('/servizio/' + comune); */
    }

    //PUT verso il backend per la modifica
    const ModificaUtente = (e) => {
        e.preventDefault();
        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("userId", id);
            urlencoded.append("data", data);
            urlencoded.append("ora", ora);
            urlencoded.append("nome", nome);
            urlencoded.append("cognome", cognome);
            urlencoded.append("indirizzo", indirizzo);
            urlencoded.append("civico", civico);
            urlencoded.append("telefono1", telefono1);
            urlencoded.append("telefono2", telefono2);
            urlencoded.append("organico", organico);
            urlencoded.append("plastica", plastica);
            urlencoded.append("vetro", vetro);
            urlencoded.append("carta", carta);
            urlencoded.append("indifferenziato", indifferenziato);
            urlencoded.append("info", info);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(modificaUtenzeAPI, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalModify(e);
                }
                if(response.status >= 400){
                    openModalError("Errore! Qualcosa è andato storto durante l'operazione");
                }
            })
            //.then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    //UseEffect che gestisce l'attribuzione ai vari stati dei dati provenienti dal click sulla tabella della dashboard
    useEffect(() => {
        Object.values(ricercaClick).map(val => {
            setId(val.id);
            setData(val.data);
            setOra(val.ora);
            setNome(val.nome);
            setCognome(val.cognome);
            setIndirizzo(val.indirizzo);
            setCivico(val.civico);
            setTelefono1(val.telefono1);
            setTelefono2(val.telefono2)
            setOrganico(val.organico);
            setCarta(val.carta);
            setVetro(val.vetro);
            setIndifferenziato(val.indifferenziato);
            setPlastica(val.plastica);
            setInfo(val.info);
            return val;
        })
    },[ricercaClick])
    
    return (
        <div className="modificaUtente">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="formwrapper">
                <h2>Modifica Utenza</h2>
                <div className="compilazioneUtente">
                    <form>
                        <h5>I campi con * sono obbligatori</h5>
                        <section className="oraedata">
                            <label htmlFor="data">Data*</label>
                            <input placeholder={new Date().toLocaleDateString('it-IT')} type="date" name="data" value={data || ''} onChange={(e) => setData(e.target.value)}></input>

                            <label htmlFor="ora">Ora*</label>
                            <input placeholder={new Date().toLocaleTimeString('it-IT')} type="time" name="ora" value={ora || ''} onChange={(e) => setOra(e.target.value)}></input>
                        </section>

                        <section className="generalinfos">

                            <div className="userId">
                                <label htmlFor="userId">UserId</label>
                                <input id="idInput" type="text" name="userId" placeholder={id} style={{background: '#e3e3e3'}} disabled></input>
                            </div>

                            <div className="nomecognome">
                                <label htmlFor="nome">Nome*</label>
                                <input type="text" name="nome" placeholder={nome ? nome : 'Inserisci il nome'} 
                                    value={nome || ''} onChange={(e) => setNome(e.target.value)}>
                                </input>

                                <label htmlFor="cognome">Cognome*</label>
                                <input type="text" name="cognome" placeholder={cognome ? cognome : 'Inserisci il cognome'} 
                                    value={cognome || ''} onChange={(e) => setCognome(e.target.value)}>
                                </input>
                            </div>

                            <div className="infoindirizzo">
                                <label htmlFor="indirizzo">Indirizzo*</label>
                                <input type="text" name="indirizzo" value={indirizzo || ''} placeholder={indirizzo ? indirizzo : "Inserisci l'indirizzo "}
                                    onChange={(e) => setIndirizzo(e.target.value)}>
                                </input>

                                <label htmlFor="civico">Civico*</label>
                                <input type="text" placeholder={civico ? civico : 'Inserisci il civico'} name="civico" value={civico || ''} 
                                    onChange={(e) => setCivico(e.target.value)}>
                                </input>
                            </div>

                            <div className="fraz">
                                <label htmlFor="fraz/comune">Fraz./Comune</label>
                                <input type="text" name="fraz/comune" value={comune.split("_").join(" ")} style={{background: '#e3e3e3'}} disabled></input>
                            </div>
                        </section>

                        <section className="contactinfos">

                            <label htmlFor="telefono1">Telefono fisso*</label>
                            <input type="text" placeholder={telefono1 ? telefono1 : 'Inserisci il telefono di casa'} name="telefono1" 
                                value={telefono1 || ''} onChange={(e) => setTelefono1(e.target.value)}>
                            </input>

                            <label htmlFor="telefono2">Telefono cellulare</label>
                            <input type="text" placeholder={telefono2 ? telefono2 : 'Inserisci il telefono cellulare'} name="telefono2"
                                value={telefono2 || ''} onChange={(e) => setTelefono2(e.target.value)}>
                            </input>
                        </section>

                            <h4>Informazioni Utenza PaP</h4>
                            <section className="elencoutenzeinfos">

                                <label htmlFor="indiff">Indiff.</label>
                                <input type="text" name="indiff" placeholder={indifferenziato ? indifferenziato : 'Codice Indiff. PaP'} 
                                    value={indifferenziato || ''} onChange={(e) => setIndifferenziato(e.target.value)}>
                                </input>

                                <label htmlFor="plastica">Plastica</label>
                                <input type="text" name="plastica" placeholder={plastica ? plastica : 'Codice Plastica PaP'} value={plastica || ''} 
                                    onChange={(e) => setPlastica(e.target.value)}>
                                </input>

                                <label htmlFor="vetro">Vetro</label>
                                <input type="text" name="vetro" placeholder={vetro ? vetro : 'Codice Vetro PaP'} value={vetro || ''} 
                                    onChange={(e) => setVetro(e.target.value)}>
                                </input>

                                <label htmlFor="carta">Carta</label>
                                <input type="text" name="carta" placeholder={carta ? carta : 'Codice Carta PaP'} value={carta || ''} 
                                    onChange={(e) => setCarta(e.target.value)}>
                                </input>

                                <label htmlFor="organico">Organico</label>
                                <input type="text" name="organico" placeholder={organico ? organico : 'Codice Organico PaP'} value={organico || ''} 
                                    onChange={(e) => setOrganico(e.target.value)}>
                                </input>

                            </section>

                            <h4>Informazioni Aggiuntive</h4>
                            <section className="infoaggiuntive">

                                <label htmlFor="info">Info utenza</label>
                                <input type="text" name="info" placeholder={info ? info : 'Info'} value={info || ''}
                                    onChange={e => setInfo(e.target.value)}>
                                </input>

                            </section>
                            <div className="buttons">
                                <ButtonAggiungi add={ModificaUtente} title1="Modifica" />
                                <ButtonEsci abort={Exit} title3="Annulla ed Esci" />
                            </div>
                    </form>
                </div>
            </div>
            <Modal
                isOpen={modalModifyisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalModify} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>Richiesta modificata correttamente</p>
            </Modal>

            <Modal
                isOpen={modalErrorisOpen}
                ariaHideApp={false}
                style={customStyles}>
                <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                <p style={{marginTop: '5em'}}>{modaltext}</p>
            </Modal>
        </div>
    );
}

export default ModificaUtenza;