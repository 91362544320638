import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ConfigProvider } from '../context/config.js';
import ListaComuni from './lista/listacomuni.js';
import './homepage.css'
import imgsfondo from './sfondohome.jpg'

//Componente della homepage che renderizza la lista dei comuni accessibili all'utente

function Home() {

    const history = useHistory();

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token'));
        /* console.log('Token from home page', token, history); */

        if(token == null){
            history.push('/');
        }
    }, [])

    return(
        <div className="home">
            <ConfigProvider>
                <ListaComuni listaTitle="Registro Comuni"/>
            </ConfigProvider>
            <div className="imghome">
                <img src={imgsfondo}
                alt="imghome"
                style={{height: '100vh'}}/>
            </div>
        </div>
    );
}

export default Home;