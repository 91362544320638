import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../context/config';
import './listacomuni.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorOpen, faSignInAlt } from '@fortawesome/free-solid-svg-icons'

//Componente contenente la logica per la renderizzazione della lista dei comuni con i rispettivi link alle varie dashboard dei comuni

function ListaComuni(){

    //file config dal context
    const file = useContext(ConfigContext);
    
    const comuni = file.config.comuni;
    const regioni = file.config.regioni;

    return(
        <>
        <div className="listaComuni">
            {/* <h3>{props.listaTitle}</h3> */}
            {
                Object.keys(comuni).map((comuni, i) => {
                    return <Link to={{
                                pathname: "/servizio/" + comuni + "/dashboard",
                                state: {"comune": comuni.split(" ").join(" ")},
                                }}
                                key={i}
                                style={{display: 'flex',flexDirection: 'row', width: '70%', textDecoration: 'none', cursor: 'default', alignSelf: 'center'}}
                            >
                            <div className="card">
                                <div className="logoContainer">
                                    <img className="stemmi"
                                        src={`/images/loghicomuni/${comuni}.png`}
                                        alt='stemmi_comuni'/>
                                </div>
                                <div className="comuneregione">
                                    <h5 className="nomecomune">{comuni.charAt(0).toUpperCase() + comuni.slice(1).split("_").join(" ")}</h5>
                                    <p className="nomeregione">{regioni[comuni]}</p>
                                </div>
                                <div className="icona">
                                    <FontAwesomeIcon icon={faSignInAlt} />
                                </div>
                            </div>
                    </Link>
                })
            }
            <div className="iconuserlogout">
        <Link to="/" style={{display:'flex', flexDirection:'row', textDecoration: 'none', color: '#333 ' }} onClick={e => localStorage.clear()}>
            <p>Esci</p>
            <FontAwesomeIcon 
                icon={faDoorOpen}
                style={{alignSelf: 'center', marginLeft:'4px'}}
            />
        </Link>
        </div>
        </div>
</>
    );

}

export default ListaComuni;