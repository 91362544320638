import React, { useContext, useEffect, useState } from 'react'

import { ConfigContext } from '../../context/config';
import './tabellaStrade.css'


export default function TabellaStrade( props ){

    //variabili d'utility
    const file = useContext(ConfigContext);
    const serviziStrade = Object.values(file.config.strade.servizi)
    const token = JSON.parse(localStorage.getItem('token'));

    //chiamate API
    const StradeTel = '/api/v1/telefonate/strade'
    const getStradeGiorn = '/api/v1/telefonate/stradegiorn'
    const getStradeSett = '/api/v1/telefonate/stradesett'

    //hooks
    /* const [areseTot, setAreseTot] = useState(0)
    const [areseGiorn, setAreseGiorn] = useState(0)
    const [areseSett, setAreseSett] = useState(0) */
    const [astralTot, setAstralTot] = useState(0)
    const [astralGiorn, setAstralGiorn] = useState(0)
    const [astralSett, setAstralSett] = useState(0)
    /* const [casalnuovoTot, setCasalnuovoTot] = useState(0)
    const [casalnuovoGiorn, setCasalnuovoGiorn] = useState(0)
    const [casalnuovoSett, setCasalnuovoSett] = useState(0) */
    const [romaCapitaleTot, setRomaCapitaleTot] = useState(0)
    const [romaCapitaleGiorn, setRomaCapitaleGiorn] = useState(0)
    const [romaCapitaleSett, setRomaCapitaleSett] = useState(0)
    const [faraBucheTot, setFaraBucheTot] = useState(0)
    const [faraBucheGiorn, setFaraBucheGiorn] = useState(0)
    const [faraBucheSett, setFaraBucheSett] = useState(0)
    const [reggioTot, setReggioTot] = useState(0)
    const [reggioGiorn, setReggioGiorn] = useState(0)
    const [reggioSett, setReggioSett] = useState(0)
    const [totale, setTotale] = useState(0);

    //props
    const isLoading = props.isLoading;
    const showTitles = props.showTitles;

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + '/' + mm + '/' + dd;

    //useEffect per prendere i dati al momento del render iniziale
    useEffect(() => {

            for(let i = 0; i < serviziStrade.length; i++){
                
                var myHeaders = new Headers();
                myHeaders.append("x-access-token", token.accessToken);
                myHeaders.append("servizio", serviziStrade[i])
                myHeaders.append("data", today);

                var requestOptions = {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow'
                }

                fetch(StradeTel, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        switch(result.servizio){
                            /* case 'Arese':
                                setAreseTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break; */
                            case 'Astral':
                                setAstralTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break;
                            /* case 'Casalnuovo':
                                setCasalnuovoTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break; */
                            case 'Roma_Capitale':
                                setRomaCapitaleTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break;
                            case 'Fara_sabina_buche':
                                setFaraBucheTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break;
                            case 'Reggio_calabria':
                                setReggioTot(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                            break;
                        }
                    })
                fetch(getStradeGiorn, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        switch(result.servizio){
                            /* case 'Arese':
                                setAreseGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break; */
                            case 'Astral':
                                setAstralGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break;
                            /* case 'Casalnuovo':
                                setCasalnuovoGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break; */
                            case 'Roma_Capitale':
                                setRomaCapitaleGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break;
                            case 'Fara_sabina_buche':
                                setFaraBucheGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break;
                            case 'Reggio_calabria':
                                setReggioGiorn(Number(result.dati[0].giornal == null ? 0 : result.dati[0].giornal))
                            break;
                        }
                    })
                    fetch(getStradeSett, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            switch(result.servizio){
                                /* case 'Arese':
                                    setAreseSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break; */
                                case 'Astral':
                                    setAstralSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break;
                                /* case 'Casalnuovo':
                                    setCasalnuovoSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break; */
                                case 'Roma_Capitale':
                                    setRomaCapitaleSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break;
                                case 'Fara_sabina_buche':
                                    setFaraBucheSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break;
                                case 'Reggio_calabria':
                                    setReggioSett(Number(result.dati[0].totale == null ? 0 : result.dati[0].totale))
                                break;
                            }
                        })
                    .catch(error => console.log(error))
            }
    }, [])

    //useEffect per mostrare il count totale in maniera dinamica
    useEffect(() => {
        setTotale(/* areseTot + */ astralTot + /* casalnuovoTot + */ romaCapitaleTot + faraBucheTot + reggioTot)
    }, [/* areseTot, */ astralTot, /* casalnuovoTot, */ romaCapitaleTot, faraBucheTot, reggioTot])

    //funzione quando premo il bottone
    const addCallStrade = (e, item) => {
        e.preventDefault();

        let servizio = item;

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("servizio", servizio);
        myHeaders.append("data", today);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow'
        };

    fetch(StradeTel, requestOptions)
        .then(response => response.json())
        .then(result => {

            switch(result.servizio){

                /* case 'Arese': 
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Arese");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAreseTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAreseGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAreseSett(Number(getResult.dati[0].totale))
                    })
                break; */

                case 'Astral':
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Astral");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAstralTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAstralGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setAstralSett(Number(getResult.dati[0].totale))
                    })
                break;

                /* case 'Casalnuovo':
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Casalnuovo");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setCasalnuovoTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setCasalnuovoGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setCasalnuovoSett(Number(getResult.dati[0].totale))
                    })
                break; */

                case 'Roma_Capitale':
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Roma_Capitale");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setRomaCapitaleTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setRomaCapitaleGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setRomaCapitaleSett(Number(getResult.dati[0].totale))
                    })
                break;

                case 'Fara_sabina_buche':
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Fara_sabina_buche");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setFaraBucheTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setFaraBucheGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setFaraBucheSett(Number(getResult.dati[0].totale))
                    })
                break;

                case 'Reggio_calabria':
                var getHeaders = new Headers();
                getHeaders.append("x-access-token", token.accessToken);
                getHeaders.append("servizio", "Reggio_calabria");
                getHeaders.append("data", today);
            
                var getOptions = {
                    method: 'GET',
                    headers: getHeaders,
                    redirect: 'follow'
                } 

                fetch(StradeTel, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setReggioTot(Number(getResult.dati[0].totale))
                    })
                fetch(getStradeGiorn, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setReggioGiorn(Number(getResult.dati[0].giornal))
                    })
                fetch(getStradeSett, getOptions)
                    .then(response => response.json())
                    .then(getResult => {
                        setReggioSett(Number(getResult.dati[0].totale))
                    })
                break;
            }
        })
        .then(() => {
            setTotale(Number(/* areseTot + */ astralTot + /* casalnuovoTot + */ romaCapitaleTot + faraBucheTot + reggioTot))
        })
        .catch(error => console.log('error', error));
    };

    return (
        <div className="tabellaStrade">
            <div className="tableTitle">
                <h4>Servizio Strade</h4>
                <div className="tableTotalCounter">
                    <h5>Totale:</h5>
                    <span style={{display: showTitles}}>{totale}</span>
                </div>
            </div>
            {
                isLoading ? 
                <div className="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                :
                <div className="datiContainerStrade">
                    {
                        serviziStrade.map((item, k) => {
                            switch(item){
                                /* case "Arese":
                                    return (
                                    <div className="elementsMappedContainer" key={k}>
                                    <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                
                                    <div className="valuesContainer">
                                        <div style={{display: showTitles}} className="dataValues">
                                            <h4 className="countersTitles">Giornal.</h4>
                                            <span>{areseGiorn}</span>
                                        </div>
                                        <div style={{display: showTitles}} className="dataValues">
                                            <h4 className="countersTitles">Settim.</h4>
                                            <span>{areseSett}</span>
                                        </div>
                                        <div style={{display: showTitles}} className="dataValues">
                                            <h4 className="countersTitles">Totali</h4>
                                            <span>{areseTot}</span>
                                        </div>
                                    </div>
                                    </div>
                                    ); */
                                case "Astral":
                                    return (
                                        <div className="elementsMappedContainer" key={k}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                    
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{astralGiorn}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{astralSett}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{astralTot}</span>
                                            </div>
                                        </div>
                                        </div>
                                    );
                                /* case "Casalnuovo":
                                    return (
                                        <div className="elementsMappedContainer" key={k}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                    
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{casalnuovoGiorn}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{casalnuovoSett}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{casalnuovoTot}</span>
                                            </div>
                                        </div>
                                        </div>
                                    ); */
                                case "Roma_Capitale":
                                    return (
                                        <div className="elementsMappedContainer" key={k}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                    
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{romaCapitaleGiorn}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{romaCapitaleSett}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{romaCapitaleTot}</span>
                                            </div>
                                        </div>
                                        </div>
                                    );
                                case "Fara_sabina_buche":
                                    return (
                                        <div className="elementsMappedContainer" key={k}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                    
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{faraBucheGiorn}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{faraBucheSett}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{faraBucheTot}</span>
                                            </div>
                                        </div>
                                        </div>
                                    );
                                case "Reggio_calabria":
                                    return (
                                        <div className="elementsMappedContainer" key={k}>
                                        <button className="buttonMapped" style={{display: showTitles}} onClick={(e) => addCallStrade(e, item)}>{item.replace(/_/g, " ")}</button>
                    
                                        <div className="valuesContainer">
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Giornal.</h4>
                                                <span>{reggioGiorn}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Settim.</h4>
                                                <span>{reggioSett}</span>
                                            </div>
                                            <div style={{display: showTitles}} className="dataValues">
                                                <h4 className="countersTitles">Totali</h4>
                                                <span>{reggioTot}</span>
                                            </div>
                                        </div>
                                        </div>
                                    );
                            }
                        })
                    }
                </div>
            }
        </div>
    );
};