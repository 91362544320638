import React, { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useState, useContext } from 'react'
import { ConfigContext } from '../context/config';
import Sidebar from '../comuni/sidebar';
import './ritiropotature.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch, faTimes, faTrashAlt, faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import FiltraggioPotature from '../comuni/modals/modalricercapotature';
import Paginazione from '../comuni/paginazione';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function RitiroPotature(){

    const file = useContext(ConfigContext);
    const location = useLocation();
    const { comune } = useParams();
    const { pagine } = location.state;
    const tabellaPotature = file.config.comuni[comune].tabellaPotature;
    const token = JSON.parse(localStorage.getItem('token'));

    //Chiamata API
    /* const inserimentoPotatureApi = "/api/v1/potature/postpotature"; */
    const mostraPotatureApi = "/api/v1/potature/getpotature";
    const nascondiPotatureApi = '/api/v1/potature/postpotature';
    const eseguiPotaturaApi = '/api/v1/potature/potaturaEseguita';
    const addCountApi = '/api/v1/potature/potaturaCount';
    const modificaNotePotaturaApi = '/api/v1/potature/modificaNotePotatura';

    //hooks
    const [modalisOpen, setmodalisOpen] = useState(false); //hook per apertura del modale
    const [showPotature, setShowPotature] = useState([]);
    const [modalCorrectisOpen, setModalCorrectisOpen] = useState(false);
    const [modalErrorisOpen, setModalErrorisOpen] = useState(false);
    const [modalCorrectAddisOpen, setModalCorrectAddisOpen] = useState(false);
    const [chooseFilter, setChooseFilter] = useState('tabGenerica')

    const [asktoRemove, setasktoRemove] = useState(false);
    const [askToExecute, setaskToExecute] = useState(false);
    const [askToAddCount, setAskToAddCount] = useState(false);
    const [tabellaId, setTabellaId] = useState(0);
    const [modalinfo, setModalInfo] = useState(false);
    const [modaltext, setModalText] = useState('');
    const [dataLimit, setDataLimit] = useState(10)
    const [modalNote, setModalNote] = useState(false)
    const [notaPotatura, setNotaPotatura] = useState('')

    //useEffect per prelevare i dati dal db per poi visualizzarli
    useEffect(() => {

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("iseseguito", chooseFilter)

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(mostraPotatureApi, requestOptions)
            .then(response => response.json())
            .then(potatureData => {
                /* console.log(potatureData) */

            const dati = Object.values(potatureData.response.risultati)/* .filter(item => item.deleted === false) */.map(val => {
                return val;
            })
            setShowPotature(() => {
                return [...dati]
            })
            })
            .catch(error => console.log('error', error));
    }, [comune, token.accessToken, chooseFilter])

    const openasktoRemoveModal = (e) => {
        e.preventDefault();
        /* console.log(e.target) */
        if(!e.target.id){
            return;
        } else {
            /* console.log('quando premo sulla X', e.target.id) */
            setTabellaId(e.target.id)
            setasktoRemove(true);
        }
    }

    const closeasktoRemoveModal = () => {
        setasktoRemove(false);
    }

    const openaskToExecuteModal = (e) => {
        e.preventDefault();
        if(!e.target.id){
            return
        }
        setTabellaId(e.target.id)
        setaskToExecute(true);
    } 

    const closeaskToExecuteModal = () => {
        setaskToExecute(false)
    }

    const openAskToAddCountModal = (e) => {
        e.preventDefault();
        if(!e.target.id){
            return
        }
        setTabellaId(e.target.id)
        setAskToAddCount(true);
    }

    const closeAskToAddCountModal = () => {
        setAskToAddCount(false)
    }

    /* const openModalInfo = (testo) => {
        setModalText(testo);
        setModalInfo(true);
    } */

    const closeModalinfo = () => {
        setModalInfo(false);
        setModalText('');
    }

    //funzione per il fake delete
    const PotatureDeletion = e => {
        e.preventDefault();
        
        closeasktoRemoveModal(e);

        console.log('record da passare al backend', tabellaId)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("username", token.username);
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(nascondiPotatureApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrect(e);
            }
            if(response.status >= 400){
                openModalError(e);
            }
        })
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
    }

    const [pages, setPages] = useState();

    useEffect(() => {
        setPages(Math.ceil(showPotature.length / dataLimit))
    }, [showPotature, dataLimit])
    
    var pageLimit = Math.ceil(showPotature.length / dataLimit);

    const [currentPage, setCurrentPage] = useState(1);

    //funzione per scorrere la pagina di uno
    function goToNextPage(){
        if(currentPage === pages || currentPage === pages + 1){
            return;
        } else {
        setCurrentPage((page) => page + 1);
        }
    }

    //funzione per la pagina di una posizione indietro
    function goToPreviousPage() {
        if(currentPage === 1){
            return;
        } else {
            setCurrentPage((page) => page - 1);
        }
    }

    //funzione per scorrere la pagina quando viene cliccato un numero
    function changePage(event) {
        const pageNumber = Number(event.target.textContent);
        if(pageNumber === 0 || pageNumber === pages + 1){
            return;
        } else {
            setCurrentPage(pageNumber);
        }
    }

    //variabili per l'inizio e la fine dei dati
    const endIndex = currentPage * dataLimit;
    const startIndex = endIndex - dataLimit;

    //variabile per i dati paginati
    const getPaginatedData = showPotature.slice(startIndex, endIndex);

    //funzione per mostrare i numeri giusti delle pagine
    /* const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        let displayedPageLimit = Math.min(pageLimit, Math.ceil(pages / dataLimit)); // Calcola il numero massimo di pagine da visualizzare
        return new Array(displayedPageLimit).fill().map((_, idx) => start + idx + 1);
    }; */
    
    const getPaginationGroup = () => {
        let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
        if(Math.ceil(pages / dataLimit) <= pageLimit){
            pageLimit = Math.ceil(pages/dataLimit) * pageLimit;
            /* if(dataLimit >= 3){
                pageLimit = 3;
            } */
            if(dataLimit >= 10 && dataLimit < 50){
                pageLimit = 3;
            } else if(dataLimit >= 50 && dataLimit < 100){
                pageLimit = 5;
            } 
            else {
                pageLimit = 1;
            }
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        } else {
            return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
        }
    };

    const openModalCorrectAdd = () => {
        setModalCorrectAddisOpen(true);
    }

    const closeModalCorrectAdd = () => {
        setModalCorrectAddisOpen(false);
        window.location.reload(false);
    }

    const openModalErrorAdd = () => {
        setModalErrorisOpen(true);
    }

    const openModalCorrect = () => {
        setModalCorrectisOpen(true);
    }

    const closeModalCorrect = () => {
        setModalCorrectisOpen(false);
        window.location.reload(false);
    }

    const openModalError = () => {
        setModalErrorisOpen(true);
    }

    const closeModalError = () => {
        setModalErrorisOpen(false);
    }

    const customStylesModalButton = {
        content: {
            top: '20%',
            left: '35%',
            height: '25vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f',
            borderRadius: '20px',
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    /* console.log(showPotature); */

    const openModal = () => {
        setmodalisOpen(true);
    }

    const closeModal = () => {
        setmodalisOpen(false);
    }

    const customStyles = {
        content: {
            top: '30%',
            left: '35%',
            height: '35vh',
            padding: '1%',
            width: '35vw',
            backgroundColor: '#ffffff',
            boxShadow: '0px 0px 6px 0px #33448f'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(4px)'
        }
    };

    const potaturaEseguita = (e) => {
        e.preventDefault();

        closeaskToExecuteModal();

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            /* myHeaders.append("username", token.username); */
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);
            urlencoded.append("data_eseguito", new Date().toISOString().split('T')[0])

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

    fetch(eseguiPotaturaApi, requestOptions)
        .then(response => {
            response.json()
            if(response.status === 200){
                openModalCorrectAdd(e);
            }
            if(response.status >= 400){
                openModalErrorAdd(e);
            }
        })
        /* .then(result => console.log(result)) */
        .catch(error => console.log('error', error));
    }

    const addCount = (e) => {
        e.preventDefault();

        closeAskToAddCountModal()

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            /* myHeaders.append("username", token.username); */
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(addCountApi, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalCorrectAdd(e);
                }
                if(response.status >= 400){
                    openModalErrorAdd(e);
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    const openModalNote = (id) => {
        setTabellaId(id)
        setModalNote(true)
    }

    /* FUNZIONE PER INSERIRE LA NOTA DELLA POTATURA */
    const closeAndSendNotaPotatura = (e) => {
        e.preventDefault();

        if(notaPotatura.length === 0){
            setModalNote(false)
            return
        }

        setModalNote(false)

        var myHeaders = new Headers();
            myHeaders.append("x-access-token", token.accessToken);
            myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
            myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
            urlencoded.append("tabellaId", tabellaId);
            urlencoded.append("nota", notaPotatura)

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        fetch(modificaNotePotaturaApi, requestOptions)
            .then(response => {
                response.json()
                if(response.status === 200){
                    openModalCorrectAdd(e);
                }
                if(response.status >= 400){
                    openModalErrorAdd(e);
                }
            })
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
    }

    return(
        <div className="wrapperpotature">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="potaturewrapper">
                <h2>{pagine.charAt(0).toUpperCase() + pagine.slice(1).split("_").join(" ")}</h2>

                <div className="tabellaPotatureWrapper">
                    <div className="modalicon">
                        <button id="iconSearch" onClick={openModal}>
                            <p>CERCA</p>
                            <FontAwesomeIcon icon={faSearch} size="1x" style={{marginLeft: '.3em', alignSelf: 'center'}} />
                        </button>
                        <Modal isOpen={modalisOpen}
                            ariaHideApp={false}
                            style={customStyles}>
                            <button onClick={closeModal} className="closingfilterbutton">x</button>
                            <FiltraggioPotature setShowPotature={setShowPotature} comune={comune} closeModal={closeModal}/>
                        </Modal>
                        <select style={{margin: '1%', alignSelf: 'center', padding: '.5em', fontSize: '.8em'}} value={chooseFilter} onChange={(e) => setChooseFilter(e.target.value)}>
                            <option value="tabGenerica">Tabella generica</option>
                            <option value="eseguito">Eseguito</option>
                            <option value="nonEseguito">Non eseguito</option>
                        </select>
                        <div className="selectPaginazionePotature">
                            <p>Elementi da visualizzare</p>
                            <select name="paginazione" value={dataLimit} onChange={e => setDataLimit(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                {/* <option value="100">100</option>
                                <option value={showPotature.length}>Tutti</option> */}
                            </select>
                        </div>
                    </div>
                    <div className="tabellaPotature">
                        <table>
                            <thead className="tabellaPotatureKeys">
                                <tr>
                                {
                                    Object.keys(tabellaPotature).map((tab, i) => {
                                        return <th key={i}>
                                            {tab}
                                        </th>
                                    })
                                }
                                </tr>
                            </thead>
                            {/* QUI VA RENDERIZZATO I DATI */}
                            <tbody>
                                {
                                    Object.values(getPaginatedData).map((val, i) => {
                                        console.log(val)
                                        return ( <tr className="rowPotature"
                                    key={i}
                                    id={val.id}>
                                    <td>{val.data} - {val.ora}</td>
                                    <td>{val.nome}</td>
                                    <td>{val.cognome}</td>
                                    <td 
                                        /*  style={{cursor: 'pointer'}}
                                        onClick={() => openModalInfo(val.indirizzo)} */>
                                        {val.indirizzo /* ? val.indirizzo.slice(0, 10) + '...' : val.indirizzo */}
                                    </td>
                                    <td>{val.civico}</td>
                                    <td>{val.telefono}</td>
                                    <td>{val.richiesta}</td>
                                    <td style={{cursor: 'pointer'}}
                                        onClick={() => openModalNote(val.id)/* () => openModalInfo(val.note) */}>
                                        {val.note /* ? val.note.slice(0, 10) + '...' : val.note */}
                                    </td>
                                    <td>{val.eseguito ? 'Si': 'No'}</td>
                                    <td>{val.eseguito_in_data}</td>
                                    <td>{val.count}</td>
                                    <td>
                                        <button style={{ cursor: 'pointer', marginRight: '.5em' }} title='Modifica' disabled={!val.eseguito ? false : true}>
                                            <Link to={{
                                                pathname: "/servizio/" + comune + "/ritiro_potature/modifica_potature",
                                                state:{"comune": comune},
                                                tab: [val]
                                            }}>
                                                <FontAwesomeIcon 
                                                icon={faEdit}
                                                id={val.id}
                                                size="lg"
                                                style={{cursor: 'pointer', color: '#33448f', alignSelf: 'center', justifySelf: 'center'}}/>
                                            </Link>
                                        </button>
                                        <button 
                                            style={{cursor: 'pointer', marginRight: '.5em'}} 
                                            onClick={openasktoRemoveModal}
                                            disabled={!val.deleted ? false : true}
                                            title='Elimina'>
                                            <FontAwesomeIcon 
                                                icon={faTrashAlt} 
                                                id={val.id}
                                                size="lg"
                                                style={{color: '#33448f'}}/>
                                        </button>
                                        <button 
                                            style={{cursor: 'pointer', marginRight: '.5em'}}
                                            disabled={!val.eseguito ? false : true}
                                            onClick={openAskToAddCountModal}
                                            title='Aggiungi chiamate'>
                                            <FontAwesomeIcon 
                                            icon={faPlusCircle}
                                            id={val.id}
                                            size="lg"
                                            style={{color: '#33448f'}}
                                            />
                                        </button>
                                        <button 
                                            style={{cursor: 'pointer', marginRight: '.5em'}} 
                                            disabled={!val.eseguito ? false : true}
                                            onClick={openaskToExecuteModal}
                                            title='Eseguito'>
                                            <FontAwesomeIcon 
                                            icon={faCheck}
                                            id={val.id}
                                            size="lg"
                                            style={{color: '#33448f'}}
                                        />
                                        </button>
                                    </td>
                                        <Modal 
                                            isOpen={modalNote}
                                            ariaHideApp={false}
                                            style={customStyles}>
                                            <button onClick={(e) => closeAndSendNotaPotatura(e)} className="closingbuttonmodal">x</button>
                                            <textarea 
                                                maxlength="100"
                                                placeholder="Massimo 100 caratteri consentiti..."
                                                style={{resize: 'none', outline: 'none', height: '50%', padding: '1%', marginTop: '5em', width: '80%', backgroundColor: 'white'}} 
                                                onChange={(e) => setNotaPotatura(e.target.value)}
                                            />
                                        </Modal>
                                        <Modal
                                            isOpen={asktoRemove}
                                            ariaHideApp={false}
                                            style={customStyles}>
                                            <p>Attenzione! Stai per rimuovere questo record</p>
                                            <p>Sei sicuro?</p>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                            <FontAwesomeIcon 
                                                icon={faCheck} 
                                                id={val.id}
                                                onClick={PotatureDeletion} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            <FontAwesomeIcon 
                                                icon={faTimes} 
                                                onClick={closeasktoRemoveModal} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            </div>
                                        </Modal>
                                        <Modal
                                            isOpen={askToAddCount}
                                            ariaHideApp={false}
                                            style={customStyles}>
                                            <p>Attenzione! Stai per incrementare il count delle chiamate per questa utenza</p>
                                            <p>Sei sicuro?</p>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                            <FontAwesomeIcon 
                                                icon={faCheck} 
                                                id={val.id}
                                                onClick={addCount} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            <FontAwesomeIcon 
                                                icon={faTimes} 
                                                onClick={closeAskToAddCountModal} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            </div>
                                        </Modal>
                                        <Modal
                                            isOpen={askToExecute}
                                            ariaHideApp={false}
                                            style={customStyles}>
                                            <p>Attenzione! Stai per modificare questo record come eseguito</p>
                                            <p>Sei sicuro?</p>
                                            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: '2em'}}>
                                            <FontAwesomeIcon 
                                                icon={faCheck} 
                                                id={val.id}
                                                onClick={potaturaEseguita} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            <FontAwesomeIcon 
                                                icon={faTimes} 
                                                onClick={closeaskToExecuteModal} 
                                                size="2x"
                                                style={{cursor: 'pointer', color: '#33448f'}}/>
                                            </div>
                                        </Modal>
                                    </tr>
                                    );
                                })
                                }
                            </tbody>
                        </table>
                    </div>
                    <Paginazione
                        dataLimit={dataLimit}
                        getPaginationGroup={getPaginationGroup}
                        goToNextPage={goToNextPage}
                        goToPreviousPage={goToPreviousPage}
                        pages={pages}
                        currentPage={currentPage}
                        changePage={changePage}
                    />
                </div>

                <Modal
                    isOpen={modalCorrectAddisOpen}
                    ariaHideApp={false}
                    style={customStylesModalButton}>
                    <button onClick={closeModalCorrectAdd} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Operazione eseguita correttamente</p>
                </Modal>

                <Modal
                    isOpen={modalCorrectisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalCorrect} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Record eliminato correttamente</p>
                </Modal>

                <Modal
                    isOpen={modalErrorisOpen}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>Non è stato possibile eseguire l'operazione</p>
                </Modal>

                <Modal 
                    isOpen={modalinfo}
                    ariaHideApp={false}
                    style={customStyles}>
                    <button onClick={closeModalinfo} className="closingbuttonmodal">x</button>
                    <p style={{marginTop: '5em'}}>{modaltext}</p>
                </Modal>
            </div>
        </div>
    );
}

export default RitiroPotature;