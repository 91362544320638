import React from 'react';

import './paginazione.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons';

//Componente che renderizza i tasti della paginazione alla fine di ciascuna tabella. I dati che riceve sono solo di lettura, la logica risiede nel componente padre.

function Paginazione( props ) {

    const getPaginationGroup = props.getPaginationGroup;
    const goToNextPage = props.goToNextPage;
    const goToPreviousPage = props.goToPreviousPage;
    const pages = props.pages;
    const currentPage = props.currentPage;
    const changePage = props.changePage;


    /* RENDER PAGINAZIONE */
    return ( 
        <div className="pagination">
            {/* previous button */}
            <button
                onClick={goToPreviousPage}
                className={`prev ${currentPage === 1 ? 'disabled' : ''}`}>
            <FontAwesomeIcon 
                icon={faAngleDoubleLeft}
                size="lg"
            />
            </button>

            {/* show page numbers */}
                {getPaginationGroup().map((item, index) => {
                    return (
                        <div key={index}>
                            <button
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item /* - 1 */ ? 'active' : null}`}>
                                <span>{item}</span>
                            </button>
                            {
                                getPaginationGroup.length > 2 ? 
                            <button
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item + 1 ? 'active' : null}`}>
                                <span>{item + 1}</span>
                            </button> : null
                            }
                            {
                                getPaginationGroup.length > 3 ?
                            <button
                                onClick={changePage}
                                className={`paginationItem ${currentPage === item + 2 ? 'active' : null}`}>
                                <span>{item + 2}</span>
                            </button> : null
                            }
                    </div>
                    );
                })}

                {/* next button */}
                <button
                    onClick={goToNextPage}
                    className={`next ${currentPage === pages ? 'disabled' : ''}`}>
                <FontAwesomeIcon 
                    icon={faAngleDoubleRight}
                    size="lg"
                />
                </button>
            </div>
            )
}

export default Paginazione;