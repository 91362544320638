import React, { useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import ButtonAggiungi from '../buttons/buttonaggiungi';
import ButtonEsci from '../buttons/buttonesci';
import Sidebar from '../comuni/sidebar';
import './modifica_richieste.css';
import Modal from 'react-modal';

/**
 * 
 * pagina che serve a modificare solo il record inerente i ritiri delle potature
 * il css è il solito di modifica_richieste
 */

//Componente che permette la modifica di una richiesta
function ModificaPotature(){

    const history = useHistory();
    const { comune } = useParams();
    const token = JSON.parse(localStorage.getItem('token'));
    const location = useLocation();

    /* console.log('location', location) */

    //Chiamata API
    const modificaRitiroRichiestaPotature = '/api/v1/potature/modificaRitiroPotatura';

    //Hooks
    const [ricercaClick, setRicercaClick] = useState([])

    //hook per l'apertura dei modali
    const [modalModifyisOpen, setmodalModifyisOpen] = useState(false);
    const [modalErrorisOpen, setmodalErrorisOpen] = useState(false);
    const [modaltext, setModalText] = useState('');

    const customStyles = {
        content: {
            top: '20%',
            left: '35%',
            height: '30vh',
            padding: '1%',
            width: '30vw',
            backgroundColor: '#ffffff',
            borderRadius: '20px',
            boxShadow: '0px 0px 6px 0px #33448f',
            /* border: '10px solid #333', */
            fontSize: '1.2em'
        },
        overlay: {
            zIndex: 100,
            backgroundColor      : 'rgb(255 255 255 / 0%)',
            backdropFilter       : 'blur(8px)'
        }
    };

    const openModalModify = () => {
        setmodalModifyisOpen(true);
    }

    const closeModalModify = () => {
        setmodalModifyisOpen(false);
        history.goBack()
    }

    const openModalError = testo => {
        setModalText(testo)
        setmodalErrorisOpen(true);
    }

    const closeModalError = () => {
        setmodalErrorisOpen(false);
        history.goBack()
    }

    //hook per l'id della richiesta
    const [id, setId] = useState('')

    //hook per acquisizione data
    const [data, setData] = useState('');

    //hook per acquisizione ora
    const [ora, setOra] = useState('');

    //hook per acquisire nome
    const [nome, setNome] = useState('');

    //hook per acquisire cognome
    const [cognome, setCognome] = useState('');

    //hook per il telefono di casa
    const [telefono1, setTelefono1] = useState('');

    //hook per l'indirizzo
    const [indirizzo, setIndirizzo] = useState('');

    //hook per il civico
    const [civico, setCivico] = useState('');

    //hook per la richiesta
    const [richiesta, setRichiesta] = useState('')

    //hook per campo note
    const [note, setNote] = useState('');

    const Exit = (e) => {
        //console.log('comune', comune)
        e.preventDefault();
        history.goBack();
    }

    //PUT verso il backend per la modifica
    const Modifica = (e) => {
        e.preventDefault();

        var myHeaders = new Headers();
        myHeaders.append("x-access-token", token.accessToken);
        myHeaders.append("luogo", comune.charAt(0).toUpperCase() + comune.slice(1));
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        var urlencoded = new URLSearchParams();
          urlencoded.append("potaturaId", id);
          urlencoded.append("data", data);
          urlencoded.append("ora", ora);
          urlencoded.append("nome", nome);
          urlencoded.append("cognome", cognome);
          urlencoded.append("indirizzo", indirizzo);
          urlencoded.append("civico", civico);
          urlencoded.append("richiesta", richiesta);
          urlencoded.append("telefono1", telefono1);
          urlencoded.append("note", note)

        var requestOptions = {
          method: 'PUT',
          headers: myHeaders,
          body: urlencoded,
          redirect: 'follow'
        };

          fetch(modificaRitiroRichiestaPotature, requestOptions)
              .then(response => {
                  response.json()
                  if(response.status === 200){
                      openModalModify(e);
                  }
                  if(response.status >= 400){
                      openModalError("Errore! Qualcosa è andato storto durante l'operazione");
                  }
              })
              /* .then(result => console.log(result)) */
              .catch(error => console.log('error', error));
          } 

    //useEffect che ascolta i cambiamenti dello stato contenente i dati relativi al click
    useEffect(() => {
        if(location.tab !== undefined){
            setRicercaClick(location.tab)
        } else {
            return;
        }
    }, [location.tab])

    //UseEffect che gestisce l'attribuzione ai vari stati dei dati provenienti dal click sulla tabella della dashboard
    useEffect(() => {
      Object.values(ricercaClick).map(val => {
        /* console.log('val useEffect post click', val) */
        setId(val.id); //richiestaId
        setData(val.data);
        setOra(val.ora);
        setNome(val.nome);
        setCognome(val.cognome);
        setIndirizzo(val.indirizzo);
        setCivico(val.civico);
        setRichiesta(val.richiesta);
        setTelefono1(val.telefono);
        setNote(val.note);
        return val;
      })
    },[ricercaClick])

    return (
        <div className="modificaRichiesta">
            <Sidebar sidebar="Menù" title={comune.split("_").join(" ").toUpperCase()}/>
            <div className="formwrapper">
                <h2>Modifica richiesta ritiro potature</h2>

                <div className="ricercautentecompilazione">
                    <form>
                    <h5>I campi con * sono obbligatori</h5>

                    <section className="oraedata">
                        <label htmlFor="data">Data*</label>
                        <input placeholder={new Date().toLocaleDateString('it-IT')} type="date" name="data" 
                            value={data || ''} onChange={e => setData(e.target.value)} style={{background: 'rgba(243, 243, 243, 0.4)'}}>
                        </input>

                        <label htmlFor="ora">Ora*</label>
                        <input placeholder={new Date().toLocaleTimeString('it-IT')} type="time" name="ora" 
                            value={ora || ''} onChange={e => setOra(e.target.value)} style={{background: 'rgba(243, 243, 243, 0.4)'}}>
                        </input>
                    </section>

                    {/* <h4>Informazioni Personali*</h4> */}

                    <section className="generalinfos">
                      <div className="nomecognome">
                          <label htmlFor="nome">Nome</label>
                          <input type="text" name="nome" placeholder={nome ? nome : 'Inserisci il nome'} 
                              value={nome || ''} onChange={e => setNome(e.target.value)}></input>

                          <label htmlFor="cognome">Cognome</label>
                          <input type="text" name="cognome" placeholder={cognome ? cognome : 'Inserisci il cognome'} 
                              value={cognome || ''} onChange={e => setCognome(e.target.value)}></input>
                      </div>
  
                      <div className="infoindirizzo">
                          <label htmlFor="indirizzo">Indirizzo</label>
                          <input type="text" name="indirizzo" value={indirizzo || ''} placeholder={indirizzo ? indirizzo : "Inserisci l'indirizzo "}
                              onChange={e => setIndirizzo(e.target.value)}></input>

                          <label htmlFor="civico">Civico</label>
                          <input type="number" placeholder={civico ? civico : 'Inserisci il civico'} name="civico" 
                              value={civico || ''} 
                              onChange={e => setCivico(e.target.value)}></input>
                      </div>

                      <div className="fraz">
                          <label htmlFor="fraz/comune">Fraz./Comune</label>
                          <input type="text" name="fraz/comune" value={comune.split("_").join(" ")} disabled={true} style={{ background: '#e3e3e3'}}></input>
                      </div>
                    </section>

                    {/* <h4>Informazioni di Contatto*</h4> */}

                    <section className="contactinfos">

                        <label htmlFor="telefono1">Recapito telefonico</label>
                        <input type="text" placeholder={telefono1 ? telefono1 : 'Inserisci un recapito telefonico'} name="telefono1" 
                            value={telefono1 || ''} onChange={e => setTelefono1(e.target.value)}></input>
                    </section>
                    <section className="inforichiesta">
                    <div className="richiesta">
                            <label htmlFor="richiesta">Richiesta</label>
                            <textarea type="text" name="richiesta" placeholder={richiesta ? richiesta : 'Richiesta'} value={richiesta || ''} 
                                onChange={e => setRichiesta(e.target.value)} maxLength="250"></textarea>
                        </div>
                    </section>

                    <h4>Informazioni Aggiuntive</h4>
                    <section className="infoaggiuntive">

                        {/* <label htmlFor="info">Info utenza</label>
                        <input type="text" name="info" placeholder={info === 'undefined' ? 'info' : info} value={info || ''}
                            onChange={e => setInfo(e.target.value)}></input> */}

                        <label htmlFor="note">Note ritiro</label>
                        <input type="text" name="note" placeholder={note ? note : 'Note'} value={note || ''}
                            onChange={e => setNote(e.target.value)}></input>

                    </section>
                        <div className="buttons">
                          <ButtonAggiungi add={Modifica} title1="Modifica" />
                          <ButtonEsci abort={Exit} title3="Annulla ed Esci" />
                        </div>
                    </form>

                    <Modal
                        isOpen={modalModifyisOpen}
                        ariaHideApp={false}
                        style={customStyles}>
                        <button onClick={closeModalModify} className="closingbuttonmodal">x</button>
                        <p style={{marginTop: '5em'}}>Richiesta modificata correttamente</p>
                    </Modal>

                    <Modal
                        isOpen={modalErrorisOpen}
                        ariaHideApp={false}
                        style={customStyles}>
                        <button onClick={closeModalError} className="closingbuttonmodal">x</button>
                        <p style={{marginTop: '5em'}}>{modaltext}</p>
                    </Modal>

                </div>

                
            </div>
        </div>
    );
}

export default ModificaPotature;